import React from "react";
import CofinanceLinkListSkeletonItem from "./CofinanceLinkListSkeletonItem";

function CofinanceLinkListSkeleton() {
    return (<div className="cofinance-link-list">
        <CofinanceLinkListSkeletonItem key={1}/>
        <CofinanceLinkListSkeletonItem key={2}/>
        <CofinanceLinkListSkeletonItem key={3}/>
    </div>)
}

export default CofinanceLinkListSkeleton;
