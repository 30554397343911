import React, {useEffect, useState} from 'react';
import fetch from "isomorphic-unfetch";
import cn from "classnames";
import QuizInfo from "./quiz-info";
import QuestionList from "./question-list";
import {Button} from "../button";
import QuizTitle from "./quiz-title";
import QuizThanxMessage from "./quiz-thanx-message";
import {format, parseISO} from "date-fns";
import ru from "date-fns/locale/ru";

const defaultData = {
    info: null,
    questions: []
}

const formatDate = date => {
    try {
        return date ? format(parseISO(date), "dd.MM.yyyy", {locale: ru}) : null
    } catch {
        return null;
    }
};

export const QuizContent = props => {
    let quizId = props.quizId;
    let token = props.token;
    const [startQuiz, setStartQuiz] = useState(false);
    const [quiz, setQuiz] = useState(defaultData);
    const [answers, setAnswers] = useState([]);
    const [showResults, setShowResults] = useState(false);

    const quizClasses = cn({
        quiz: true,
        quiz__form: startQuiz,
    });

    useEffect(() => {
        fetch(`/api/quiz/${quizId}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw response;
                }
            })
            .then(json => {
                setQuiz(json);
            });
    }, []);

    const onAnswersChanged = (answerData) => {
        let questionId = answerData.question;
        let quizQuestions = quiz.questions || [];
        let qIndex = quizQuestions.findIndex(item => item.id === questionId);
        let quizAnswers = answers || [];
        let aIndex = quizAnswers.findIndex(item => item.question === questionId);

        if (aIndex !== -1) {
            quizAnswers[aIndex] = answerData;
            if (qIndex !== -1) {
                if (quizQuestions[qIndex].type.id === 2) {
                    quizAnswers[aIndex] = {
                        question: answerData.question,
                        answer: formatDate(answerData.answer)
                    };
                }
            }
        } else {
            quizAnswers.push(answerData);
        }
        setAnswers(quizAnswers);
    }

    const handleSendAnswers = () => {
        const data = {
            main: {
                answers: answers
            }
        };

        let headers = {
                'Content-Type': 'application/json'
            };
        if (token) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            };
        }

        return fetch('/api/quiz', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                setShowResults(true);
            } else {
                throw response;
            }
        }).catch(err => setShowResults(false))
            .finally(() => {
                setShowResults(true);
            });
    };

    return quiz && quiz.info && <div className={quizClasses}>
        <div className="quiz__initilal">
            <QuizTitle title={quiz.info.title} logo={quiz.info.logo}/>
            {!startQuiz &&
            <QuizInfo quizInfo={quiz.info}
                        questionCount={quiz.questions.length}
                        setStartQuiz={() => setStartQuiz(true)}/>}
        </div>

        {startQuiz && <>
            {showResults ? <QuizThanxMessage message={quiz.info.thanxMessage}/> : <>
            <QuestionList questions={quiz.questions}
                          onFormChange={onAnswersChanged}/>
            <div className="form-page__fill-required">
                * Пожалуйста, заполните все обязательные поля
            </div>
            <div className="quiz__form__question--control">
                <Button className="btn--secondary" onClick={handleSendAnswers}>
                    {"Отправить"}
                </Button>
            </div></>}
        </>}
    </div>
}

export default QuizContent;