import React from 'react';
import {Header} from '../../components/header';
import {GotQuestion} from '../../components/got-question';
import {Footer} from '../../components/footer';
import {ProgramFilter} from '../../components/program-filter';
import {GoTopButton} from '../../components/goTopButton'


const Programs = props => (
  <div className="wrapper">
    <Header/>

    <GoTopButton/>
	
    <ProgramFilter {...props} />
  
    <div className="block-margin"/>
    
    <GotQuestion/>
    
    <Footer/>
  </div>
);

export {Programs};