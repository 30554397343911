import React, { useState } from "react";

import { ProgramList } from "../program-list";

import { quizResult } from "../../api/quizData";

import { Chart } from "./chart";
import cn from "classnames";

const QuizResult = ({ answers, programs }) => {
  const [isOpen, setOpen] = useState(false);

  const renderContentList = (text, arr) => (
    <div className="quiz__result__content__desc__list">
      <p>{text}</p>
      <ul>{arr && arr.map((item, index) => <li key={`list-${index}`}>{item}</li>)}</ul>
    </div>
  );

  const result = quizResult.map(item => ({
    id: item.id,
    result: item.answers.filter(answer => answers.indexOf(answer) !== -1).length,
    desc: item,
    programs: programs[item.name],
  }));

  const chartData = result.map(item => item.result);

  const professionsClasses = cn({
    "quiz__result__content__desc__professions--list": true,
    "quiz__result__content__desc__professions--list--show": isOpen,
  });

  const professionsIconClasses = cn({
    "quiz__result__content__desc__professions--title--icon": true,
    "quiz__result__content__desc__professions--title--icon-reverse": isOpen,
  });

  return (
    <div className="quiz__result">
      <div className="quiz__result--title">Результат</div>
      <div className="quiz__result--chart">
        <Chart chartData={chartData} />
      </div>
      {result
        .sort((a, b) => b.result - a.result)
        .map(item => (
          <div key={item.id}>
            <div className="quiz__result__content">
              <div className="quiz__result__content__desc">
                <div className="quiz__result__content__desc__title">
                  <h4>{item.desc.type}</h4>
                  {item.desc.typeDesc && <span>{item.desc.typeDesc}</span>}
                </div>
                <div className="quiz__result__content__desc__content">
                  <div className="quiz__result__content__desc__content--score-wrapper">
                    <span>Ваше значение:</span>
                    <div className="quiz__result__content__desc__content--score">{item.result}</div>
                    <span>Максимум 8 баллов</span>
                  </div>
                  <div>
                    {renderContentList(
                      item.desc.labor &&
                        "Предметом труда для представителей большинства профессий в этой области являются:",
                      item.desc.labor
                    )}

                    {renderContentList(
                      item.desc.activities &&
                        "Специалистам в этой области приходится выполнять следующие виды деятельности:",
                      item.desc.activities
                    )}

                    {renderContentList(
                      item.desc.psychological && "Психологические требования профессий этого типа:",
                      item.desc.psychological
                    )}

                    <div className="quiz__result__content__desc__professions">
                      <div
                        className="quiz__result__content__desc__professions--title"
                        onClick={() => {
                          setOpen(!isOpen);
                        }}
                      >
                        <h4>Рекомендуемые профессии</h4>
                        <div className={professionsIconClasses}>
                          <svg width="20" height="10" viewBox="0 0 20 10" fill="none">
                            <path
                              d="M10 10L9.42087e-08 1.22807L1.4 0L10 7.54386L18.6 0L20 1.22807L10 10Z"
                              fill="#778899"
                            />
                          </svg>
                        </div>
                      </div>

                      <div className={professionsClasses} onClick={() => setOpen(false)}>
                        {item.desc.professions.join(", ")}
                      </div>
                      <ProgramList programs={item.programs} newTab />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="quiz__result--line" />
          </div>
        ))}
    </div>
  );
};

export { QuizResult };
