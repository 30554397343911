import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

const HeaderSearch = ({ mobile }) => {
  const history = useHistory();
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState();

  const openSearch = () => {
    setIsOpen(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };

  const closeSearch = () => {
    setIsOpen(false);
  };

  const handleSubmit = event => {
    event.preventDefault();
    history.push(`/search?query=${searchValue}`);
  };

  const handleInputChange = event => {
    setSearchValue(event.target.value);
  };

  return (
    <div
      className={isOpen || mobile ? "header-search header-search--open" : "header-search header-search--close"}
      onBlur={closeSearch}
    >
      <div className="header-search__icon" title="Поиск по сайту" onClick={openSearch}>
        {/*<img src="/images/search-icon.png" alt=""/>*/}
        <svg xmlns="http://www.w3.org/2000/svg"
             width="16" height="16"
             viewBox="0 0 446.25 446.25">
          <path d="M318.75,280.5h-20.4l-7.649-7.65c25.5-28.05,40.8-66.3,40.8-107.1C331.5,73.95,257.55,0,165.75,0S0,73.95,0,165.75    S73.95,331.5,165.75,331.5c40.8,0,79.05-15.3,107.1-40.8l7.65,7.649v20.4L408,446.25L446.25,408L318.75,280.5z M165.75,280.5    C102,280.5,51,229.5,51,165.75S102,51,165.75,51S280.5,102,280.5,165.75S229.5,280.5,165.75,280.5z"
                fill="#d0101b"
                fillRule="evenodd"
                clipRule="evenodd"/>
        </svg>
      </div>

      <form action="/search" className="header-search__form" onSubmit={handleSubmit}>
        {!mobile && <p>|</p>}
        <input
          type="text"
          ref={inputRef}
          placeholder="Поиск по сайту"
          value={searchValue || ""}
          onChange={event => handleInputChange(event)}
        />
        <div className="header-search__close" onClick={closeSearch}>
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="18.6569" y="5.92896" width="2" height="18" transform="rotate(45 18.6569 5.92896)" fill="#C4C4C4" />
            <rect x="7.34314" y="5.92896" width="18" height="2" transform="rotate(45 7.34314 5.92896)" fill="#C4C4C4" />
          </svg>
        </div>
      </form>
    </div>
  );
};

export { HeaderSearch };
