import React from "react";
import {Button} from "../../button";

const QuizInfo = ({quizInfo, questionCount, setStartQuiz}) => {
    return quizInfo.status && quizInfo.status.id === 1 ? <div>
            {quizInfo.description && <p>{quizInfo.description}</p>}
            {quizInfo.instruction && <>
            <h5>Инструкция к тесту:</h5>
            <p>{quizInfo.instruction}</p></>}

            <p>
                Количество вопросов в тесте: <b>{questionCount}</b>
            </p>
            <div className="quiz__initilal--control">
                <Button className="btn--secondary" onClick={setStartQuiz}>
                    Начать тестирование
                </Button>
            </div>
        </div> : <div className="quiz__initilal--noactive">неактивный опрос</div>
};

export default QuizInfo;