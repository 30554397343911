import React from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";

import { Quiz } from "../../components/quiz";
import QuizContent from "../../components/quiz-content";
import SecurityContext from "../../context/SecurityContext";

const QuizPage = (props) => {
    const context = React.useContext(SecurityContext);
    const { keycloak } = context;
    const [token, setToken] = React.useState(null);

    React.useEffect (() => {
        setToken(keycloak.token);
    }, [context]);

    let quizId = props.match.params['id'];
  return (
    <div className="quiz__wrapper">
      <Header />

      <div className="container">
        <QuizContent quizId={quizId} token={token}/>
      </div>

      <Footer />
    </div>
  );
};

export { QuizPage };
