import * as React from "react";
import {PageErrorCatch} from "../hoc/PageErrorCatch";
import {EmployerIndexData} from "../services/Employer/EmployerIndex";
import EmployerRoot from "../components/employer/EmployerRoot";

function EmployerPage() {
    return (<PageErrorCatch>
        <EmployerIndexData>
            <EmployerRoot/>
        </EmployerIndexData>
    </PageErrorCatch>)
}

export default EmployerPage
