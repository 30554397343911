import * as React from "react";
import {useJobHHDataFormSearch} from "../../services/Job";
import {Button} from "../button";

const SkeletonItemStyle = {
    width: "148px",
    height: "40px"
}

export function JobHHSearchPanelSearch() {
    const [isReady, doSearch] = useJobHHDataFormSearch();
    if (isReady) {
        return (<Button className="job-hh-search-panel-search btn btn--secondary" onClick={doSearch}>Найти</Button>)
    }
    return (<div className="skeleton">
        <div className="skeleton-item" style={SkeletonItemStyle}/>
    </div>);
}
