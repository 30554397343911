import React from "react";

import {useEmployerIndexData} from "../../services/Employer/EmployerIndex";
import EmployerContactListSkeleton from "./EmployerContactListSkeleton";
import EmployerContactListReady from "./EmployerContactListReady";


function EmployerContactBlock() {
    const [isLoading, data] = useEmployerIndexData()
    let list = null;
    if (isLoading) {
        list = (<EmployerContactListSkeleton/>);
    } else if (data && data.contacts && data.contacts.length > 0) {
        list = (<EmployerContactListReady contacts={data.contacts}/>)
    } else {
        return null;
    }

    return (<div className="employer-contact">
        <h2 className="employer-contact__head">Контактная информация</h2>
        {list}
    </div>)
}

export default EmployerContactBlock;
