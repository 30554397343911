import React from "react";

import CofinanceLinkListReadyItem from "./CofinanceLinkListReadyItem";

function CofinanceLinkListReady({links}) {
    return (<div className="cofinance-link-list">
        {links.map((i, idx) => (<CofinanceLinkListReadyItem item={i} key={idx} />))}
    </div>)
}

export default CofinanceLinkListReady;
