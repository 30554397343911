import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {PageTitle} from '../../components/page-title';
import {GotQuestion} from '../../components/got-question';
import {Link} from 'react-router-dom';
import {TabMenu} from '../../components/tab-menu';
import {aboutTabMenuModel} from '../../api/api';

class Teacher extends React.Component {

  render() {
    const {model} = this.props;
    return (
      <div className="wrapper wrapper--default">
        <Header/>
        <div className="container container--about">
          <PageTitle>О центре</PageTitle>

          <TabMenu menuModel={aboutTabMenuModel} />

          <div className="block-margin"/>

          <div className="teacher">
            {model && <>
              <div className="teacher__name">{model.name}</div>

              <div className="teacher__container">
                <div className="teacher__photo">
                  <img src={model.photo || '/images/teacher-no-photo.png'} alt=""/>
                </div>

                <div className="teacher__info">
                  {model.programs && model.programs.length &&
                    <div className="teacher-programs">
                      <div className="teacher-programs__title">Программы преподавателя</div>
                      {model.programs.map((item, index) => (
                        <Link to={'/program/' + item.link} className="teacher-programs__item" key={`teacher-program-${index}`}>
                          {item.title}
                        </Link>
                      ))}
                    </div>
                  }

                  {model.text && <>
                    <div className="styled-redactor teacher__text" dangerouslySetInnerHTML={{__html: model.text}}/>
                  </>}

                  <Link to="/about/teachers" className="teacher__back-link">
                    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M0.713018 7.01309L5.11302 12.0001L6.36202 10.8531L2.97402 7.01309H16.994V4.98209H2.96902L6.43402 1.14309L5.15602 -0.00390625L0.657019 4.98209H-0.000980377V7.01309H0.713018Z" fill="#778899"/>
                    </svg>

                    <span>Все преподаватели</span>
                  </Link>
                </div>

              </div>
            </>}
          </div>

          <div className="block-margin"/>
        </div>
        <GotQuestion/>
        <Footer/>
      </div>
    )
  }
}

export {Teacher};
