import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {PageTitle} from '../../components/page-title';
import {Link} from 'react-router-dom';
import {GotQuestion} from '../../components/got-question';
import {InlineMap} from '../../components/inline-map';
import {GoTopButton} from '../../components/goTopButton'
import {getPhones} from "../../api/api";


const Contacts = props => {
    let model = props.model;
    let hasSocial = model && model.social && model.social.length > 0;
    let hasFile = model && model.contactsFile;
    let hasContacts = model && model.contacts && model.contacts.length > 0;

    return (
        <div className="wrapper wrapper--default">
            <Header/>
            <GoTopButton/>
            <div className="container container--about">
                <PageTitle>Контакты</PageTitle>

                <div className="contacts-page">
                    <div className="contacts-page__text">
                        {hasContacts && model.contacts.map((item, index) => (
                            <div className="styled-redactor contacts-page-item" key={`item_${index}`}>
                                <b>{item.title}</b>
                                {!!item.text && item.text.length > 0 &&
                                <div dangerouslySetInnerHTML={{__html: item.text}}/>}
                                {!!item.phone && item.phone.length > 0 && item.phone.map((item, index) => (
                                    <a href={'tel:' + item} className="underline-link" key={`phone_${index}`}>
                                        {item}
                                    </a>
                                ))}

                                {!!item.email && item.email.length > 0 && item.email.map((item, index) => (
                                    <a href={'mailto:' + item} className="underline-link" key={`email_${index}`}>
                                        {item}
                                    </a>
                                ))}
                            </div>))}


                        {hasFile && <a href={model.contactsFile} className="contacts-page__link">
                            <span>Скачать реквизиты</span>
                            <svg width="13" height="18" viewBox="0 0 13 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M7.51297 16.7871L12.5 12.3871L11.353 11.1381L7.51297 14.5261V0.506104L5.48197 0.506104V14.5311L1.64297 11.0661L0.495972 12.3441L5.48197 16.8431V17.5011H7.51297V16.7871Z"
                                      fill="#D0101B"/>
                            </svg>
                        </a>}
                    </div>

                    <div className="contacts-page-aside">
                        <div className="contacts-page-aside__top">
                            <div className="contacts-page__pure-contacts">
                                По общим вопросам:
                                {getPhones().map(phone => {
                                    const phoneHref = "tel:" + phone;
                                    return (<a href={phoneHref} className="black-link">{phone}</a>)
                                })}
                                <a href="mailto:info@copp72.ru" className="underline-link">info@copp72.ru</a>
                            </div>

                            <Link to="/contact-us" className="btn btn--primary btn--arrow contacts-page__btn">
                                <span>Написать нам</span>
                                <svg width="41" height="16" viewBox="0 0 41 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M41.005 8.38197L33.795 16.001L32.009 14.472L37.191 8.99597H-0.000976562V7.00497H37.176L31.985 1.52497L33.773 -0.00402832L40.991 7.61497L40.55 7.99197L41.005 8.38197Z"
                                              fill="#778899"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="41" height="16" fill="#778899"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Link>
                        </div>

                        {hasSocial && <div className="contacts-page-socials">
                            <div className="contacts-page-socials__title">
                                Мы в соцсетях
                            </div>

                            <div className="contacts-page-socials__row">
                                {model.social.map((item, index) => (
                                    <a href={item.link} key={index}>
                                        <img width="32" height="32" src={item.logo} alt=""/>
                                    </a>
                                ))}
                            </div>
                        </div>}
                    </div>
                </div>

            </div>

            <div className="block-margin"/>

            <InlineMap/>

            <GotQuestion/>

            <Footer/>
        </div>
    )
};

export {Contacts};
