import React from 'react';
import {Header} from "../../components/header";
import {Footer} from "../../components/footer";
import {GoTopButton} from '../../components/goTopButton';
import {GotQuestion} from "../../components/got-question";
import {AnnounceCarousel} from "../../components/announce-carousel";

const Announces = props => {
    return (
        <div className="wrapper">
            <Header/>

            <GoTopButton />

            {props.model && <>

                {props.model.announces && props.model.announces.length > 0 && <>
                    <div className="block-margin"/>
                    <div className="container">
                        <div className="program-list__title">Анонс мероприятий</div>
                    </div>

                    <div className="program-list__default-wrapper">
                        <AnnounceCarousel slides={props.model.announces} noBg/>
                    </div>
                </>}

                <div className="block-margin"/>
            </>
            }

            <GotQuestion/>

            <Footer/>
        </div>
    )
}

export {Announces};