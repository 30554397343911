import React from 'react';

export const DocItem = ({link, title}) => {
    return (
        <a href={link} className="doc" download>
            <img src="/images/about-doc.png" alt=""/>
            <div className="doc-desc">
                <span className="">{title}</span>
            </div>
        </a>
    );
};
