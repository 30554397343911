import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';
import {withRouter} from 'react-router';
import Keycloak from "keycloak-js";
import SecurityContext from "./context/SecurityContext";

import React, {useEffect, useState, useCallback} from 'react';
import {render} from 'react-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './index.css';
import './styles/pages/employer.css';
import './styles/pages/cofinance.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

import {PageWrapper} from './hoc/page-wrapper/index'

smoothscroll.polyfill();

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, -200);
        }
    }

    render() {
        return this.props.children;
    }
}

const Scroll = withRouter(ScrollToTop);

const keycloak = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_BASE_URI,
    realm: "copp-work",
    clientId: "copp-my-site"
});


const InitialSecurity = {
    keycloak: keycloak,
    broken: true,
    authenticated: false,
    profile: {},
    login: () => {
        return keycloak.login({redirectUri: "https://platform.copp72.ru/lk/profile"});
    },
    logout: () => {
        return keycloak.logout();
    }
};

const Root = () => {

    const [security, setSecurity] = useState(InitialSecurity);

    useEffect(() => {
        keycloak.init({
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            promiseType: 'native',
        }).then((authenticated) => {
            security.authenticated = authenticated;
            if (authenticated) {
                keycloak.loadUserProfile()
                    .then(profile => {
                        setSecurity({
                            ...InitialSecurity,
                            authenticated,
                            profile,
                            broken: false
                        })
                    })
            } else {
                setSecurity({
                    ...InitialSecurity,
                    authenticated,
                    broken: false
                })
            }
        }, err => {
            console.log(err);
            setSecurity(InitialSecurity);
        });

    }, [])

    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Scroll>
                    <SecurityContext.Provider value={security}>
                        <PageWrapper>
                            <App/>
                        </PageWrapper>
                    </SecurityContext.Provider>
                </Scroll>
            </BrowserRouter>
        </GoogleReCaptchaProvider>
    )
}

render(<Root/>, document.getElementById('root'));







