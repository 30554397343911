import * as React from "react";
import {PageErrorCatch} from "../hoc/PageErrorCatch";
import {JobHHData} from "../services/Job";
import {JobHH} from "../components/JobHH";

export function JobHHPage() {
    return (<PageErrorCatch>
        <JobHHData>
            <JobHH/>
        </JobHHData>
    </PageErrorCatch>)
}
