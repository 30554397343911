import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {GoTopButton} from '../../components/goTopButton';
import {StepControl} from '../../components/step-control';
import {DocItem} from "../../components/doc-item";
import {JobPageHead} from "../../components/job-page/head";

export const Interview = () => {
    return (
        <div className="wrapper wrapper--default">
            <Header/>
            <GoTopButton/>
            <div className="job__container">
                <JobPageHead/>
                <div className="job__step">Шаг 4</div>
                <div className="job__desc">
                    <h2>Собеседование</h2>
                    <p>
                        Если Вы условились о времени встречи с работодателем, то самое время заняться непосредственной
                        подготовкой
                        к собеседованию. Что же нужно сделать?
                    </p>
                    <ol>
                        <li>
                            <p>Сначала подготовьте документы, которые могут понадобиться на собеседовании:</p>
                            <ul>
                                <li>резюме в двух экземплярах;</li>
                                <li>паспорт;</li>
                                <li>диплом об образовании с вкладышем;</li>
                                <li>
                                    дипломы о дополнительном образовании, сертификаты об окончании курсов, удостоверения
                                    и т.п. (не следует
                                    брать с собой документы, не имеющие отношения к должности, на которую Вы
                                    претендуете).
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                Рекомендуем Вам заранее ознакомиться с информацией об организации, в которую Вы идёте на
                                собеседование.
                                Подключите все возможные каналы: зайдите на сайт организации в Интернете, используйте
                                справочники, прессу
                                или другие источники. Тем самым в ходе беседы с работодателем Вы сможете
                                продемонстрировать серьёзность
                                своих намерений.
                            </p>
                        </li>
                        <li>
                            <p>
                                Наметьте маршрут поездки на собеседование, рассчитайте время, которое нужно затратить на
                                дорогу, добавьте
                                ещё запас времени (минут 30) на случай возможных транспортных затруднений, с которыми Вы
                                можете
                                столкнуться в дороге.
                            </p>
                        </li>
                        <li>
                            <p>
                                Продумайте ответы на вопросы работодателя на собеседовании, которые в том или ином виде
                                обязательно
                                прозвучат в ходе Вашего разговора:
                            </p>
                            <ul>
                                <li>почему Вы хотите работать именно в нашей организации?</li>
                                <li>какую пользу Ваша деятельность как сотрудника может принести нашей организации?</li>
                                <li>назовите свои самые большие достоинства и слабости как сотрудника;</li>
                                <li>назовите Ваши самые большие достижения как специалиста.</li>
                            </ul>
                            <p>Подготовьте заранее вопросы, которые Вы хотели бы задать работодателю.</p>
                        </li>
                        <li>
                            <p>
                                Продумайте одежду, в которой пойдёте на встречу с работодателем. Всем знакома пословица:
                                «Встречают по
                                одёжке, провожают по уму». Пусть первое впечатление о Вас будет самым благоприятным.
                            </p>
                        </li>
                    </ol>
                    <h4>
                        Воспользуйтесь нашей консультацией. Удачи на собеседовании!
                    </h4>
                    <DocItem link="/doc/31" title="Консультация выпускнику - собеседование"/>
                </div>
                <StepControl
                    prev="/job/self-presentation"
                    next="/job/carier-start"
                    stepTitlePrev="Шаг 3"
                    titlePrev="Самопрезентация"
                    stepTitleNext="Шаг 5"
                    titleNext="Начало карьеры"
                />
            </div>
            <Footer/>
        </div>
    );
};
