import React from 'react';

const CheckBox = props => {
  return (
    <div className="custom-checkbox-container">
        <div className="custom-checkbox">
            <input type="checkbox" id={`checkbox${props.key}`} name={`chk${props.key}`} value={props.checked}
            onChange={props.onChange}/>
            <span>
                <svg width="15" height="13" viewBox="0 0 15 13" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.685 2.38101L6.621 12.228L6.443 12.082L6.345 12.186L5.489 11.301L4.3 10.328L4.414 10.19L0.983 6.64301L2.987 4.49901L6.283 7.90601L12.364 0.480011L14.685 2.38101Z"
                          fill="#D0101B"/>
                </svg>
            </span>
        </div>

        <label htmlFor={`checkbox${props.key}`}>
            {props.label}
        </label>
    </div>);
};

export {CheckBox};
