import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {PageTitle} from '../../components/page-title';
import {TabMenu} from '../../components/tab-menu';
import {GotQuestion} from '../../components/got-question';
import {Accordion} from '../../components/accordion';
import {GoTopButton} from '../../components/goTopButton'
import {aboutTabMenuModel} from '../../api/api';


const Partners = props => {
    let content = [];

    if (props.model && props.model.partners) {
        for (const partner of props.model.partners) {
            if (partner.title && partner.partners && partner.partners.length > 0) {
                content.push(<h2 className="block-title">{partner.title}</h2>);
                content.push(<Accordion items={partner.partners}/>)
                content.push(<div className="block-margin"/>)
            }
        }
    }

    return (
        <div className="wrapper wrapper--default">
            <Header/>
            <GoTopButton/>

            <div className="container container--about">
                <PageTitle>О центре</PageTitle>
                <TabMenu menuModel={aboutTabMenuModel}/>
                <div className="block-margin"/>
                {content}
            </div>
            <GotQuestion/>
            <Footer/>
        </div>
    )
};

export {Partners};