import React from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { PageTitle } from "../../components/page-title";
import { TabMenu  } from "../../components/tab-menu";
import { GotQuestion } from "../../components/got-question";
import { GoTopButton } from "../../components/goTopButton";
import { Accordion } from "../../components/accordion";
import {aboutTabMenuModel} from '../../api/api'

const Docs = (props) => {
  const items =
    props.model &&
    props.model.groups.map((group) => ({
      title: group.name,
      docs: props.model.docs.filter((doc) => doc.groupId === group.id),
	}));
	
  return (
    <div className="wrapper wrapper--default">
      <Header />

      <GoTopButton />

      <div className="container container--about">
        <PageTitle>О центре</PageTitle>

        <TabMenu  menuModel={aboutTabMenuModel} />

        <div className="block-margin" />

        {props.model && <Accordion items={items} />}

        <div className="block-margin" />
      </div>
      <GotQuestion />
      <Footer />
    </div>
  );
};

export { Docs };
