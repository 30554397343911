import React from 'react';
import {Button} from '../button';
import Select from 'react-select';
import fetch from "isomorphic-unfetch"
import {ProgramListItem} from '../program-list-item'
import qs from "query-string"


const initialShowQuantity = 9;
const addShowQuantity = 9;
const activityAllId = -1;
const activityAllName = 'Все';
const conductAllId = -1;
const conductAllName = 'Все';

class ProgramFilter extends React.Component {
    constructor(props) {
        super(props);
        const parsed = qs.parse(props.location.search || '')

        this.state = {
            initialRegion: Number(parsed.region),
            initialTargetGroup: Number(parsed.targetGroup),
            initialActivity: Number(parsed.activity),
            initialConduct: Number(parsed.conduct),
            programs: [],
            programsCount: 0,
            showQuantity: initialShowQuantity,
            regions: [],
            targetGroups: [],
            activities: [],
            conducts: [],
            current: {
                region: Number(undefined),
                targetGroup: Number(undefined),
                activity: activityAllId,
                conduct: conductAllId
            },
            sortBy: {
                value: 'date',
                label: 'По новизне'
            }
        };
    }

    componentDidMount() {
        fetch("/api/site/program/catalog")
            .then(value => value.json())
            .then(catalog => {
                let currentRegion = Number(undefined);
                let currentTargetGroup = Number(undefined);
                let currentActivity = activityAllId;
                let currentConduct = conductAllId;
                if (catalog.regions && catalog.regions.length > 0) {
                    currentRegion = catalog.regions[0].id;
                    for (let idx = 0; idx < catalog.regions.length; ++idx) {
                        if (this.state.initialRegion === catalog.regions[idx].id) {
                            currentRegion = this.state.initialRegion
                        }
                    }
                }
                if (catalog.targetGroups && catalog.targetGroups.length > 0) {
                    currentTargetGroup = catalog.targetGroups[0].id;
                    for (let idx = 0; idx < catalog.targetGroups.length; ++idx) {
                        if (this.state.initialTargetGroup === catalog.targetGroups[idx].id) {
                            currentTargetGroup = this.state.initialTargetGroup
                        }
                    }
                }
                if (catalog.activities && catalog.activities.length > 0) {
                    for (let idx = 0; idx < catalog.activities.length; ++idx) {
                        if (this.state.initialActivity === catalog.activities[idx].id) {
                            currentActivity = this.state.initialActivity
                        }
                    }
                }
                if (catalog.conducts && catalog.conducts.length > 0) {
                    for (let idx = 0; idx < catalog.conducts.length; ++idx) {
                        if (this.state.initialConduct === catalog.conducts[idx].id) {
                            currentConduct = this.state.initialConduct
                        }
                    }
                }
                this.setState({
                    programsCount: (catalog.items || []).length,
                    programs: catalog.items || [],
                    regions: catalog.regions || [],
                    targetGroups: catalog.targetGroups || [],
                    activities: catalog.activities || [],
                    conducts: catalog.conducts || [],
                    current: {
                        region: currentRegion,
                        targetGroup: currentTargetGroup,
                        activity: currentActivity,
                        conduct: currentConduct
                    },
                });
            })
    }

    updateUrl = () => {
        let current = this.state.current || {}
        let search = {}
        if (current.region) {
            search.region = current.region
        }
        if (current.targetGroup) {
            search.targetGroup = current.targetGroup
        }
        if (current.activity && current.activity !== activityAllId) {
            search.activity = current.activity
        }
        if (current.conduct && current.conduct !== conductAllId) {
            search.conduct = current.conduct
        }
        this.props.history.replace({
            pathname: '/programs',
            search: qs.stringify(search)
        })
    }

    handleFilter = e => {
        e.persist();
        this.setState(prevState => {
            prevState.current[e.target.name] = Number(e.target.value);
            return prevState;
        }, () => this.updateUrl());
    };

    handleShowMoreClick = () => {
        this.setState(prevState => ({
            showQuantity: prevState.showQuantity + addShowQuantity
        }));
    };

    filterItems = item => {
        const {current} = this.state;
        return (
            (item.regionId === current.region || item.eduFormId === 4)
            && item.targetGroupId === current.targetGroup
            && (item.activityId === current.activity || current.activity === activityAllId)
            && (item.conductId === current.conduct || current.conduct === conductAllId)
        );
    };

    sortItems = (a, b) => {
        const {sortBy, current} = this.state;
        let aStop = !!a.stop;
        let bStop = !!b.stop;
        let currentRegion = current.region || 0;
        let aRegion = a.regionId || 0;
        let bRegion = b.regionId || 0;

        if (aRegion !== bRegion) {
            if (bRegion !== currentRegion) {
                return -1;
            }
            return 1;
        }

        if (aStop !== bStop) {
            if (bStop) {
                return -1;
            }
            return 1;
        }
        if (sortBy.value === 'date') {
            return Date.parse(b.date) - Date.parse(a.date);
        }

        if (sortBy.value === 'name') {
            return a.name.localeCompare(b.name);
        }
    };

    handleActivitySelect = activity => {
        this.setState(prevState => {
            prevState.current.activity = Number(activity.value);
            return prevState;
        }, () => this.updateUrl());
    };

    handleConductSelect = conduct => {
        this.setState(prevState => {
            prevState.current.conduct = Number(conduct.value);
            return prevState;
        }, () => this.updateUrl());
    };

    handleSortSelect = sortBy => {
        this.setState({sortBy});
    };

    getProgramFilterTitle = (region, targetGroup) => {
        let regionDesc = 'выбранном месте'
        this.state.regions.forEach(item => {
            if (item.id === region) {
                regionDesc = item.desc || 'выбранном месте'
            }
        })
        let targetGroupDesc = 'выбранного типа'
        this.state.targetGroups.forEach(item => {
            if (item.id === targetGroup) {
                targetGroupDesc = item.desc || 'выбранного типа'
            }
        })
        return `Программы обучения для ${targetGroupDesc} в ${regionDesc}`;
    };

    render() {
        const {
            regions,
            targetGroups,
            activities,
            conducts,
            current,
            programs,
            showQuantity,
            programsCount
        } = this.state;


        const selectActivities = [{value: activityAllId, label: activityAllName}];
        let selectActivity = null
        if (current.activity === activityAllId) {
            selectActivity = {value: activityAllId, label: activityAllName}
        }
        activities.forEach(item => {
            if (current.activity === item.id) {
                selectActivity = {value: item.id, label: item.name}
            }
            selectActivities.push({value: item.id, label: item.name});
        });

        const selectConducts = [{value: conductAllId, label: conductAllName}];
        let selectConduct = null
        if (current.conduct === conductAllId) {
            selectConduct = {value: conductAllId, label: conductAllName}
        }
        conducts.forEach(item => {
            if (current.conduct === item.id) {
                selectConduct = {value: item.id, label: item.name}
            }
            selectConducts.push({value: item.id, label: item.name});
        });

        const selectSortBy = [];

        selectSortBy.push({
            value: 'name',
            label: 'По названию'
        });
        selectSortBy.push({
            value: 'date',
            label: 'По новизне'
        });

        const items = programs
            .filter(this.filterItems)
            .sort(this.sortItems)
            .slice(0, showQuantity);
        return (

            <div className="container">
                <div className="program-filter">
                    <h1 className="program-filter__title">Программы</h1>
                    <div className="program-filter__subtitle">
                        В ЦОПП вы можете пройти обучение по {programsCount} программам по разным направлениям. <br/>
                        Для более удобного поиска воспользуйтесь фильтром.
                    </div>
                    <form className="program-filter-form" onChange={this.handleFilter}>
                        <div className="program-filter-form__block-title">Город</div>
                        <div className="radio-row">
                            {regions.map((item) => (
                                <div className="button-radio" key={item.id}>
                                    <input type="radio" name="region" value={item.id}
                                           defaultChecked={item.id === current.region}/>
                                    <span>{item.name}</span>
                                </div>
                            ))}
                        </div>
                        <div className="program-filter-form__block-title">Для кого</div>
                        <div className="radio-row">
                            {targetGroups.map((item) => (
                                <div className="button-radio" key={item.id}>
                                    <input type="radio" name="targetGroup" value={item.id}
                                           defaultChecked={item.id === current.targetGroup}/>
                                    <span>{item.name}</span>
                                </div>
                            ))}
                        </div>
                        <div className="program-filter-form__block-title">Направление</div>
                        <div className="program-filter-form__select">
                            <Select value={selectActivity}
                                    onChange={this.handleActivitySelect}
                                    placeholder="Выберите направление..."
                                    isSearchable={true}
                                    className="crs"
                                    classNamePrefix="crs"
                                    noOptionsMessage={() => 'Направлений не найдено'}
                                    options={selectActivities}/>
                        </div>
                        <div className="program-filter-form__block-title">Образовательное учреждение</div>
                        <div className="program-filter-form__select">
                            <Select value={selectConduct}
                                    onChange={this.handleConductSelect}
                                    placeholder="Выберите образовательное учреждение..."
                                    isSearchable={true}
                                    className="crs"
                                    classNamePrefix="crs"
                                    noOptionsMessage={() => 'Образовательных учреждений не найдено'}
                                    options={selectConducts}/>
                        </div>
                    </form>

                    <div className="program-filter-list">
                        <div className="program-filter-list__title">
                            {this.getProgramFilterTitle(current.region, current.targetGroup)}
                        </div>

                        <div className="program-filter-list__sort">
                            Сортировка:

                            <Select value={this.state.sortBy}
                                    options={selectSortBy}
                                    isSearchable={false}
                                    className="crs-sort"
                                    classNamePrefix="crs-sort"
                                    onChange={this.handleSortSelect}/>
                        </div>

                        <div className="program-filter-list__grid">
                            {items.length ? items.map((item, index) => (
                                <ProgramListItem item={item} key={index}/>
                            )) : <>
                                <span className="program-filter-list__no-results">По заданным параметрам ничего не найдено</span>
                                <span className="program-filter-list__no-results_fill">В настоящее время сайт находится на стадии наполнения. <br/> Если вы не смогли найти интересующую программу - напишите нам, либо позвоните по контактным телефонам.</span>
                            </>}
                        </div>

                        <div className="program-filter-btn__container">
                            {items.length >= showQuantity &&
                            <Button className="btn--primary btn--arrow program-filter__btn"
                                    onClick={this.handleShowMoreClick}>
                                Показать еще

                                <svg width="12" height="17" viewBox="0 0 12 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M7.01702 16.336V16.994H4.98502V16.28L-0.000976562 11.881L1.14602 10.631L4.98502 14.019V0H7.01702V14.024L10.856 10.56L12.003 11.837L7.01702 16.336Z"
                                          fill="#778899"/>
                                </svg>
                            </Button>}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export {ProgramFilter};
