import React from 'react';
import {Header} from "../../components/header";
import {GotQuestion} from "../../components/got-question";
import {Footer} from "../../components/footer";
import {NewsList} from "../../components/news-list";
import {GoTopButton} from '../../components/goTopButton'

const NewsAll = props => {
    return (
        <div className="wrapper">
            <Header/>

			<GoTopButton />

            {props.model && props.model.news && <NewsList news={props.model.news}/>}

            <div className="block-margin"/>

            <GotQuestion/>

            <Footer/>
        </div>
    );
};

export {NewsAll};
