import React, {useState} from 'react';

const ReadMore = props => {
    const [isVisible, toggleVisibility] = useState(false);
    const {text, maxTextLength} = props;

    const isTooLong = text ? text.length > maxTextLength : false;
    let trimmedText = text ? (text.substring(0, maxTextLength) + '...') : "..."


    const onClick = () => {
        toggleVisibility(!isVisible);

        props.onChange();
    };

    return (
        <div className="read-more">
            <div className="read-more__text" dangerouslySetInnerHTML={{
                __html: isTooLong
                    ? isVisible
                        ? text
                        : trimmedText
                    : text
            }}/>
            {isTooLong && <div className="read-more__toggle" onClick={onClick}>{
                isVisible
                    ? <>Скрыть</>
                    : <>Показать целиком</>
            }</div>}
        </div>
    )
};

export {ReadMore};