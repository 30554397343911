import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import classNames from 'classnames';

const TabMenu = withRouter(props => {
  return (
    <div className="tab-menu">
      {props.menuModel.map((item, index) => {
        const menuItemClasses = classNames({
          "tab-menu__item": true,
          "tab-menu__item--active":
            props.location.pathname === item.link ||
            (item.link === `${props.route}` && props.location.pathname.includes(item.link)),
        });
        return (
          <Link to={item.link} className={menuItemClasses} key={`tab_link_${index}`}>
            {item.icon}
            {item.anchor}
          </Link>
        );
      })}
    </div>
  );
});

export { TabMenu };
