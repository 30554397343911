import React from "react";
import { Link } from "react-router-dom";
import { trimTextFromSpace } from "../../api/functions";
import { NewsList } from "../news-list";

const News = (props) => {
  return (
    <div className="news__wrapper">
      <div className="container">
        <div className="news__container">
          <div className="news__container-title">
            <div className="news__title">Новости и события ЦОПП</div>
            <Link to="/news" className="news__all-link">
              Все новости
            </Link>
          </div>
          <NewsList news={props.news} noTitle noWraper main />
          <div className="random-links">
            {Array.isArray(props.extraLink) ? (
              props.extraLink.map((item, index) => (
                <Link to={item.link} className="random-links-item" key={index}>
                  <div className="random-links-item__img">
                    <img src={item.img} alt="" />
                  </div>
                  <div className="random-links-item__text">
                    <div className="random-links-item__title">
                      {trimTextFromSpace(item.title, 110, " ...")}
                    </div>
                  </div>
                </Link>
              ))
            ) : props.extraLink !== null ? (
              <Link to={props.extraLink.link} className="random-links-item">
                <div className="random-links-item__img">
                  <img src={props.extraLink.img} alt="" />
                </div>

                <div className="random-links-item__text">
                  <div className="random-links-item__title">
                    {trimTextFromSpace(props.extraLink.title, 110, " ...")}
                  </div>
                </div>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export { News };
