import React from "react";

const ItemStyle = {
    width: "320px",
    height: "255px",
}

function EmployerContactListSkeletonItem() {
    return (<div className="skeleton employer-contact-list-item">
        <div className="skeleton-item" style={ItemStyle} />
    </div>)
}

export default EmployerContactListSkeletonItem;
