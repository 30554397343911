import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import Select from 'react-select';
import fetch from 'isomorphic-unfetch';
import InputMask from 'react-input-mask';
import {DatePicker} from '../../components/date-picker';
import {Radio} from '../../components/radio';
import {Link} from 'react-router-dom';
import {getPhones} from "../../api/api";

const zeroMargin = {margin: 0};

const GotSchedulePhones = () => {
    let phones = getPhones() || [];
    let cmp = [];
    cmp.push(" ");
    for (let idx = 0; idx < phones.length; idx++) {
        if (idx > 0) {
            cmp.push(', ');
        }
        let phone = phones[idx];
        let phoneHref = "tel:" + phone;
        cmp.push(<a style={zeroMargin} href={phoneHref}>{phone}</a>);
    }
    cmp.push(" ");
    return cmp;
}


class Schedule extends React.Component {
    constructor(props) {
        super(props);

        let initialProgramId = null;
        let initialPlaceId = null;
        let initialTargetAudienceId = null;
        let initialTargetAudience = null;
        let initialProgramStop = null;
        let initialProgramDisable = null;
        let initialProgramMessage = null;

        let initialProgramIdFromPath = Number(props.match.params['id']);

        if (initialProgramIdFromPath != null && props.model.programs) {
            // Select program
            for (const program of props.model.programs) {
                if (program.programId === initialProgramIdFromPath) {
                    initialPlaceId = program.placeId;
                    initialProgramId = program.programId;
                    initialTargetAudienceId = program.targetAudienceId;
                    initialProgramStop = !!program.stop;
                    initialProgramDisable = !!program.disable;
                    initialProgramMessage = program.message;
                }
            }
            // Select form
            if (initialTargetAudienceId && props.model.targetGroups) {
                for (const targetGroup of props.model.targetGroups) {
                    if (targetGroup.id === initialTargetAudienceId) {
                        initialTargetAudience = targetGroup.form;
                    }
                }
            }
        }
        if (initialProgramId == null && props.model.programs && props.model.programs.length > 0) {
            initialPlaceId = props.model.programs[0].placeId;
        }

        let targetAudienceSelect = [];
        if (props.model.targetGroups) {
            for (const targetGroup of props.model.targetGroups) {
                if (targetGroup.id && targetGroup.name) {
                    targetAudienceSelect.push({
                        value: targetGroup.id,
                        label: targetGroup.name,
                        form: targetGroup.form
                    });
                }
            }
        }

        let placeSelect = [];
        if (props.model.places) {
            for (const place of props.model.places) {
                if (place.id && place.name) {
                    placeSelect.push({
                        value: place.id,
                        label: place.name
                    });
                }
            }
        }

        this.state = {
            send: false,
            sending: false,
            sendError: false,
            sendErrors: [],
            targetAudienceSelect: targetAudienceSelect,
            placeSelect: placeSelect,
            programStop: initialProgramStop,
            programDisable: initialProgramDisable,
            programMessage: initialProgramMessage,
            data: {
                targetAudienceId: initialTargetAudienceId,
                targetAudience: initialTargetAudience,
                program: initialProgramId,
                place: initialPlaceId
            }
        };
    }

    getProgramSelect = () => {
        let programSelect = [];
        if (this.props.model.programs) {
            for (const program of this.props.model.programs) {
                const include = program.placeId === this.state.data.place
                    && program.targetAudienceId === this.state.data.targetAudienceId
                if (include) {
                    programSelect.push({
                        label: program.programName,
                        value: program.programId,
                        eduFormId: program.eduFormId,
                        stop: !!program.stop,
                        message: program.message,
                        disable: !!program.disable,
                    })
                }
            }
        }
        return programSelect;
    }

    programSelectStyles = {
        option: (styles, {data}) => {
            return {
                ...styles,
                "&::after": data.eduFormId === 4 && {
                    position: "relative",
                    left: "8px",
                    padding: "0 8px",
                    content: `"Online"`,
                    color: "#fff",
                    borderRadius: "3px",
                    backgroundColor: "#d0101b",
                }
            };
        },
    };

    setData = (label, value) => {
        if (label && label !== "") {
            let prepVal = value;
            if (label === "orgPersonCount") {
                prepVal = Number(value);
            }
            this.setState(prevState => {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        [label]: prepVal
                    }
                };
            });
        }
    };

    setDataBirthdate = date => {
        this.setData("birthdate", date);
    }

    setDataSchoolchildBirthdate = date => {
        this.setData("schoolchildBirthdate", date);
    }

    setDataOrgStudyEnd = date => {
        this.setData("orgStudyEnd", date);
    }

    handlePlaceSelect = place => {
        this.setState(prev => {
            return {
                ...prev,
                programStop: null,
                programDisable: null,
                programMessage: null,
                data: {
                    ...prev.data,
                    place: place?.value,
                    program: null
                }
            }
        })
    };

    handleTargetAudienceSelect = tg => {
        this.setState(prev => {
            return {
                ...prev,
                programStop: null,
                programDisable: null,
                programMessage: null,
                data: {
                    ...prev.data,
                    targetAudience: tg?.form,
                    targetAudienceId: tg?.value,
                    program: null
                }
            }
        })
    };

    handleProgramSelect = p => {
        this.setState(prev => {
            return {
                ...prev,
                programStop: !!p?.stop,
                programDisable: !!p?.disable,
                programMessage: p?.message,
                data: {
                    ...prev.data,
                    program: p?.value,
                    orgRequest: null
                }
            }
        })
    };


    handleFormChange = e => {
        const shouldHandle = 'birthdate' !== e.target.name
            && 'schoolchildBirthdate' !== e.target.name
            && 'orgStudyEnd' !== e.target.name
        if (shouldHandle) {
            this.setData(e.target.name, e.target.value);
        }
    };

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            sending: true
        }, async () => {
            let token
            try {
                token = await this.props.googleReCaptchaProps.executeRecaptcha('schedule')
                try {
                    let body = JSON.stringify({
                        token,
                        ...this.state.data
                    });
                    let response = await fetch('/api/schedule', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: body
                    });
                    if (response.ok) {
                        this.setState({
                            send: true,
                            sending: false,
                            sendError: false,
                        });
                    } else {
                        try {
                            let payload = await response.json()
                            if (payload.messages && payload.messages.length > 0) {
                                this.setState({
                                    send: false,
                                    sending: false,
                                    sendError: true,
                                    sendErrors: payload.messages
                                })
                            }
                        } catch (err) {
                            this.setState({
                                send: false,
                                sending: false,
                                sendError: true,
                                sendErrors: ['Не удалось разобрать ответ сервера']
                            })
                        }
                    }
                } catch (err) {
                    this.setState({
                        send: false,
                        sending: false,
                        sendError: true,
                        sendErrors: ['Неизвестная ошибка, возможно сервер недоступен']
                    });
                }
            } catch (err) {
                this.setState({
                    send: false,
                    sending: false,
                    sendError: true,
                    sendErrors: ['Не удалось получить токен ReCaptcha']
                })
            }
        });
    };

    getSchoolchildLayout = () => {
        return (
            <>
                <input type="text"
                       name="schoolchildLastName"
                       placeholder="Фамилия школьника*"
                       required
                       tabIndex={1}
                       key={"schoolchildLastName"}
                       className="form-page__input"/>

                <input type="text"
                       name="schoolchildFirstName"
                       placeholder="Имя школьника*"
                       required
                       tabIndex={2}
                       key={"schoolchildFirstName"}
                       className="form-page__input"/>

                <input type="text"
                       name="schoolchildMiddleName"
                       placeholder="Отчество школьника"
                       tabIndex={3}
                       key={"schoolchildMiddleName"}
                       className="form-page__input"/>

                <DatePicker placeholder='Дата рождения школьника*'
                            name='schoolchildBirthdate'
                            required
                            tabIndex={4}
                            key={"schoolchildBirthdate"}
                            onChange={this.setDataSchoolchildBirthdate}/>

                <InputMask type="text"
                           name="schoolchildSnils"
                           mask='999-999-999 99'
                           placeholder="СНИЛС школьника*"
                           required
                           tabIndex={5}
                           key={"schoolchildSnils"}
                           className="form-page__input"/>

                <input type="text"
                       name="schoolchildClass"
                       placeholder="Класс*"
                       required
                       tabIndex={6}
                       key={"schoolchildClass"}
                       className="form-page__input"/>

                <input type="text"
                       name="schoolchildSchool"
                       placeholder="Школа*"
                       required
                       tabIndex={7}
                       key={"schoolchildSchool"}
                       className="form-page__input"/>

                <div className="form-page-radio">
                    <div className="form-page-radio__title">Смена в школе*</div>
                    <div className="form-page-radio__row">
                        <div className="form-page-radio__item">
                            <Radio name='schoolchildShift'
                                   label='1 смена'
                                   value='1 смена'
                                   tabIndex={8}
                                   required/>
                        </div>

                        <div className="form-page-radio__item">
                            <Radio name='schoolchildShift'
                                   label='2 смена'
                                   value='2 смена'
                                   tabIndex={9}
                                   required/>
                        </div>

                        <div className="form-page-radio__item">
                            <Radio name='schoolchildShift'
                                   label='домашнее обучение'
                                   value='домашнее обучение'
                                   tabIndex={10}
                                   required/>
                        </div>
                    </div>
                </div>

                <input type="text"
                       name="schoolchildParentLastName"
                       placeholder="Фамилия родителя (законного представителя)*"
                       required
                       tabIndex={11}
                       key={"schoolchildParentLastName"}
                       className="form-page__input"/>

                <input type="text"
                       name="schoolchildParentFirstName"
                       placeholder="Имя родителя (законного представителя)*"
                       required
                       tabIndex={12}
                       key={"schoolchildParentFirstName"}
                       className="form-page__input"/>

                <input type="text"
                       name="schoolchildParentMiddleName"
                       placeholder="Отчество родителя (законного представителя)"
                       tabIndex={13}
                       key={"schoolchildParentMiddleName"}
                       className="form-page__input"/>

                <InputMask type="text"
                           name="schoolchildParentPhone"
                           mask='+7(999)999-99-99'
                           placeholder="Телефон родителя (законного представителя)*"
                           required
                           tabIndex={14}
                           key={"schoolchildParentPhone"}
                           className="form-page__input"/>

                <input type="text"
                       name="schoolchildParentEmail"
                       placeholder="Эл.почта родителя (законного представителя) *"
                       required
                       tabIndex={15}
                       key={"schoolchildParentEmail"}
                       className="form-page__input"/>

                <div className="form-page-radio">
                    <div className="form-page-radio__title">
                        Школьник уже участвовал в программе "Первая профессия"? *
                    </div>
                    <div className="form-page-radio__row">
                        <div className="form-page-radio__item">
                            <Radio name='schoolchildGotFreeProfession'
                                   label='да'
                                   value={true}
                                   tabIndex={16}
                                   required/>
                        </div>

                        <div className="form-page-radio__item">
                            <Radio name='schoolchildGotFreeProfession'
                                   label='нет'
                                   value={false}
                                   tabIndex={17}
                                   required/>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    getDefaultLayout = () => {
        return (
            <>
                <input type="text"
                       name="lastName"
                       placeholder="Фамилия*"
                       required
                       tabIndex={1}
                       key={"lastName"}
                       className="form-page__input"/>

                <input type="text"
                       name="firstName"
                       placeholder="Имя*"
                       required
                       tabIndex={2}
                       key={"firstName"}
                       className="form-page__input"/>

                <input type="text"
                       name="middleName"
                       placeholder="Отчество"
                       tabIndex={3}
                       key={"middleName"}
                       className="form-page__input"/>

                <InputMask type="text"
                           name="phone"
                           mask='+7(999)999-99-99'
                           placeholder="Номер телефона*"
                           required
                           tabIndex={4}
                           key={"phone"}
                           className="form-page__input"/>

                <input type="text"
                       name="email"
                       placeholder="Email*"
                       required
                       tabIndex={5}
                       key={"email"}
                       className="form-page__input"/>

                <DatePicker placeholder="Дата рождения"
                            name='birthdate'
                            key={"birthdate"}
                            onChange={this.setDataBirthdate}/>
            </>
        );
    };

    getOrgLayout = (selectedProgram, programSelect) => {
        let showRequest = !selectedProgram;
        return (
            <>
                <input type="text"
                       name="orgCustomerName"
                       placeholder="Организация*"
                       required
                       tabIndex={1}
                       key={"orgCustomerName"}
                       className="form-page__input"/>

                <input type="text"
                       name="orgContactName"
                       placeholder="ФИО ответственного специалиста*"
                       required
                       tabIndex={5}
                       key={"orgContactName"}
                       className="form-page__input"/>

                <Select value={selectedProgram}
                        onChange={this.handleProgramSelect}
                        isClearable={true}
                        isSearchable={true}
                        className="form-page-crs form-page__crs"
                        classNamePrefix="form-page-crs"
                        placeholder={'Выберите программу'}
                        noOptionsMessage={() => 'Не найдено программ'}
                        options={programSelect}
                        styles={this.programSelectStyles}/>

                {showRequest && <textarea name="orgRequest"
                                          placeholder="Или напишите профессию необходимых вам специалистов"
                                          tabIndex={2}
                                          key={"orgRequest"}
                                          className="form-page__input"/>}

                <input type="number"
                       name="orgPersonCount"
                       placeholder="Кол-во специалистов*"
                       required
                       tabIndex={3}
                       key={"orgPersonCount"}
                       className="form-page__input"/>

                <DatePicker placeholder="Необходимо обучить до"
                            name='orgStudyEnd'
                            tabIndex={4}
                            key={"orgStudyEnd"}
                            onChange={this.setDataOrgStudyEnd}/>

                <InputMask type="text"
                           name="orgPhone"
                           mask='+7(999)999-99-99'
                           placeholder="Номер телефона*"
                           required
                           tabIndex={8}
                           key={"orgPhone"}
                           className="form-page__input"/>

                <input type="text"
                       name="orgEmail"
                       placeholder="Email*"
                       required
                       tabIndex={9}
                       key={"orgEmail"}
                       className="form-page__input"/>
            </>
        );
    }
    ;

    getFormBottom = () => {
        return (

            <>
                <div className="form-page__fill-required">
                    * Пожалуйста, заполните все обязательные поля
                </div>

                <div className="form-page__agreement">
                    <div className="custom-checkbox">
                        <input type="checkbox" id="form-page-agreement"
                               name="agreed" required tabIndex={7}
                               defaultChecked/>
                        <span>
                <svg width="15" height="13" viewBox="0 0 15 13" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.685 2.38101L6.621 12.228L6.443 12.082L6.345 12.186L5.489 11.301L4.3 10.328L4.414 10.19L0.983 6.64301L2.987 4.49901L6.283 7.90601L12.364 0.480011L14.685 2.38101Z"
                          fill="#D0101B"/>
                </svg>
            </span>
                    </div>

                    <label htmlFor="form-page-agreement">
                        Я согласен с условиями обработки <a href="/policy" target="_blank">персональных данных</a>
                    </label>
                </div>

                {
                    this.state.send && <>
                        <div className="block-margin"/>
                        <div>Заявка отправлена</div>
                    </>
                }
                {
                    this.state.sendError && <>
                        <div className="block-margin"/>
                        <p>Не удалось отправить заявку.</p>
                        {this.state.sendErrors && this.state.sendErrors.length > 0 && <>
                            {this.state.sendErrors.map((e, idx) => (
                                <React.Fragment key={idx}>
                                    <br/>
                                    <p>{e}</p>
                                </React.Fragment>
                            ))}
                        </>}
                        <br/>
                        <p> Попробуйте обновить страницу и заполнить заявку повторно</p>
                    </>
                }
                {
                    !this.state.send && <button type="submit" disabled={this.state.sending}
                                                className="btn btn--secondary form-page__btn">Отправить</button>
                }
            </>
        )
            ;
    };

    render() {
        const placeSelect = this.state.placeSelect;
        let selectedPlace = placeSelect.find(x => x.value === this.state.data.place);
        if (!selectedPlace) {
            selectedPlace = null;
        }
        const targetAudienceSelect = this.state.targetAudienceSelect;
        let selectedTargetAudience = targetAudienceSelect.find(x => x.value === this.state.data.targetAudienceId);
        if (!selectedTargetAudience) {
            selectedTargetAudience = null;
        }
        const programSelect = this.getProgramSelect();
        let selectedProgram = programSelect.find(x => x.value === this.state.data.program);
        if (!selectedProgram) {
            selectedProgram = null;
        }
        const programStop = this.state.programStop;
        const programMessage = this.state.programMessage;
        const programDisable = this.state.programDisable;
        const showProgramSelect = selectedTargetAudience && selectedPlace;

        let requestProgramForm = null;
        let isProgramRequired = true;
        if (showProgramSelect) {
            let targetAudience = this.state.data.targetAudience;
            if (targetAudience === "schoolchild" && selectedProgram) {
                requestProgramForm = this.getSchoolchildLayout();
            } else if (targetAudience === "org") {
                isProgramRequired = false;
                requestProgramForm = this.getOrgLayout(selectedProgram, programSelect);
            } else if (selectedProgram) {
                requestProgramForm = this.getDefaultLayout();
            }
        }

        return (
            <div className="wrapper wrapper--form-page">
                <Header/>
                <div className="form-page__wrapper">
                    <div className="container">
                        <div className="form-page">
                            <div className="form-page__title">Заявка на обучение</div>

                            <div className="form-page__subtitle">Заполните заявку, чтобы получить информацию по
                                расписанию занятий и условиям обучения.
                            </div>

                            <form className="form-page__form" onChange={this.handleFormChange}
                                  onSubmit={this.handleFormSubmit}>

                                <div className="form-page__input-title">Ваш город</div>

                                <Select value={selectedPlace}
                                        onChange={this.handlePlaceSelect}
                                        isSearchable={false}
                                        className="form-page-crs form-page__crs"
                                        classNamePrefix="form-page-crs"
                                        placeholder={'Выберите город'}
                                        noOptionsMessage={() => 'Не найдено городов'}
                                        options={placeSelect}/>

                                <div className="form-page__input-title">Для кого</div>

                                <Select value={selectedTargetAudience}
                                        onChange={this.handleTargetAudienceSelect}
                                        isSearchable={false}
                                        className="form-page-crs form-page__crs"
                                        classNamePrefix="form-page-crs"
                                        placeholder={'Не выбрано'}
                                        noOptionsMessage={() => 'Не найдено категорий'}
                                        options={targetAudienceSelect}/>

                                {showProgramSelect && <>

                                    {isProgramRequired && <>
                                        <div className="form-page__input-title">Выберите программу обучения</div>

                                        <Select value={selectedProgram}
                                                onChange={this.handleProgramSelect}
                                                isClearable={true}
                                                isSearchable={true}
                                                className="form-page-crs form-page__crs"
                                                classNamePrefix="form-page-crs"
                                                placeholder={'Не выбрано'}
                                                noOptionsMessage={() => 'Не найдено программ'}
                                                options={programSelect}
                                                styles={this.programSelectStyles}/>

                                    </>}


                                    {programStop && <>
                                        <div className="stop-message"><img src="/images/important-small.png"/> Запись
                                            приостановлена
                                        </div>
                                        <div className="form-page__message">
                                            Если вы хотите пройти обучение по данной программе,
                                            оставьте ваши контактные данные,
                                            и мы уведомим вас об открытии записи.
                                        </div>
                                    </>}

                                    {programMessage && <div className="form-page__message"
                                                            dangerouslySetInnerHTML={{__html: programMessage}}/>}

                                    {!programDisable && requestProgramForm}

                                    {!programDisable && this.getFormBottom()}
                                </>}


                            </form>

                            {selectedProgram
                                ? <div className="form-page__sign">
                                    <b>Возникли вопросы?</b> Позвоните нам
                                    <GotSchedulePhones/>
                                    или напишите на почту
                                    <a href="mailto:info@copp72.ru">info@copp72.ru</a>
                                </div>
                                : <div className="form-page__start-sign">
                                    Найти подходящую программу вы можете в <Link to="/programs"
                                                                                 className="underline-link">каталоге
                                    программ</Link>
                                </div>}

                        </div>
                    </div>

                    <div className="form-page__bg">
                        <img src="/images/form-page.jpg"
                             srcSet="/images/form-page.jpg 1400w, /images/form-page@2x.jpg 2800w"
                             alt=""/>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export
{
    Schedule
}
    ;
