import React from "react";

import {useCofinanceIndexData} from "../../services/Cofinance/CofinanceIndex";
import CofinanceLinkListSkeleton from "./CofinanceLinkListSkeleton";
import CofinanceLinkListReady from "./CofinanceLinkListReady";


function CofinanceLinkList() {
    const [isLoading, data] = useCofinanceIndexData()
    let list = null;
    if (isLoading) {
        list = (<CofinanceLinkListSkeleton/>);
    } else if (data && data.links && data.links.length > 0) {
        list = (<CofinanceLinkListReady links={data.links}/>)
    } else {
        return null;
    }

    return (<div className="cofinance-link">
        {list}
    </div>)
}

export default CofinanceLinkList;
