import React from 'react';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { GoTopButton } from '../../components/goTopButton';
import { StepControl } from '../../components/step-control';
import { DocItem } from '../../components/doc-item';
import {JobPageHead} from "../../components/job-page/head";

export const JobSearch = () => {
  return (
    <div className="wrapper wrapper--default">
      <Header />

      <GoTopButton />
      <div className="job__container">
        <JobPageHead/>
        <div className="job__step">Шаг 1</div>
        <div className="job__desc">
          <h2>Поиск работы</h2>

            <div className="video">
                <iframe  src="https://www.youtube.com/embed/k_q7Cc6lygg" frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />
            </div>

            <p>
              Изменения на рынке труда происходят ежедневно. Работники выходят на пенсию, теряют трудоспособность
              вследствие болезни или несчастного случая, меняют место жительства, уходят в отпуск по уходу за ребенком,
              увольняются и т.д. Кроме того, организуются новые предприятия. Всё это говорит о том, что свободные
              рабочие места есть, или, по крайней мере, появляются время от времени. Поиск работы как раз и заключается
              в первую очередь в том, чтобы получить информацию об этих свободных или высвобождающихся местах.
            </p>
            <p>
              Где же искать информацию о рабочих местах? Есть несколько источников таких сообщений и лучше, если Вы
              будете пользоваться ими одновременно:
            </p>
            <ul>
              <li>городские, районные центры занятости населения;</li>
              <li>объявления в газетах и журналах, специальных бюллетенях;</li>
              <li>в рекламных сообщениях на радио и ТВ, на улице;</li>
              <li>сообщения знакомых, друзей, коллег, соседей;</li>
              <li>информация по каналам профессиональных и общественных организаций; </li>
              <li>отделы кадров предприятий и учреждений, организаций и служб;</li>
              <li>интернет;</li>
              <li>ответы из различных источников на твои запросы или объявления в газете;</li>
              <li>газетные статьи о расширении производства или создании новыхпредприятий.</li>
            </ul>
            <h4>
              Мы оптимизировали для Вас информацию о возможных источниках поиска работы. Воспользуйтесь ими, сделайте первый шаг!
            </h4>
            <DocItem link="/doc/25" title="Консультационные центры профориентации в районах юга Тюменской области" />
        </div>
        <StepControl
            prev="/job/steps"
            stepTitlePrev="Шаги"
            titlePrev="Вступление"
            next="/job/resume"
            stepTitleNext="Шаг 2"
            titleNext="Составление резюме"
        />
      </div>
      <Footer />
    </div>
  );
};
