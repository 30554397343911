import * as React from 'react';
import {Link} from 'react-router-dom';
import {Header} from '../header';
import {Footer} from '../footer';
import {GoTopButton} from '../goTopButton';
import {useJobHHItemData} from "../../services/Job/JobHHItem";
import {JobHHItemSkeleton} from "./JobHHItemSkeleton";
import {JobHHItemReady} from "./JobHHItemReady";
import {JobHHItemNotFound} from "./JobHHItemNotFound";


export function JobHHItem() {

    const [isLoading, data] = useJobHHItemData();
    let content = null;
    if (isLoading) {
        content = (<JobHHItemSkeleton/>);
    } else if (data && data.vacancy) {
        content = (<JobHHItemReady vacancy={data.vacancy}/>);
    } else {
        content = (<JobHHItemNotFound/>);
    }

    return (
        <div className="wrapper wrapper--default">
            <Header/>
            <GoTopButton/>
            <div className="job-hh-item">
                {content}
            </div>
            <Footer/>
        </div>
    );
}

