import React from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";

import { Quiz } from "../../components/quiz";

const QuizKlimovPage = () => {
    return (
        <div className="quiz__wrapper">
            <Header />

            <div className="container">
                <Quiz />
            </div>

            <Footer />
        </div>
    );
};

export { QuizKlimovPage };
