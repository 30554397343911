import React from "react";
import EmployerContactListSkeletonItem from "./EmployerContactListSkeletonItem";

function EmployerContactListSkeleton() {
    return (<div className="employer-contact-list">
        <EmployerContactListSkeletonItem key={1}/>
        <EmployerContactListSkeletonItem key={2}/>
    </div>)
}

export default EmployerContactListSkeleton;
