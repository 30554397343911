import React, { useState, useEffect } from "react";
import fetch from "isomorphic-unfetch";

import { QuizInitial } from "./quiz-initial";
import { QuizForm } from "./quiz-form";
import { QuizResult } from "./quiz-result";
import cn from "classnames";

import { quizQuestions } from "../../api/quizData";

const Quiz = () => {
  const [startQuiz, setStartQuiz] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [programs, setPrograms] = useState();
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    fetch("/api/site/quiz/klimov")
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then(json => {
        setPrograms(json);
      });
  }, []);

  const totalQuestions = quizQuestions.length;
  const activeQuestion = quizQuestions[questionNumber];
  const currentQuestion = questionNumber + 1;

  const nextQuestion = answer => {
    setAnswers([...answers, questionNumber + 1 + answer]);

    if (questionNumber < quizQuestions.length) {
      setQuestionNumber(questionNumber + 1);
      return;
    }
  };

  const quizClasses = cn({
    quiz: true,
    quiz__form: startQuiz,
  });

  return (
    <div className={quizClasses}>
      {!startQuiz && <QuizInitial setStartQuiz={() => setStartQuiz(true)} />}
      {startQuiz && !showResults && (
        <QuizForm
          totalQuestions={totalQuestions}
          currentQuestion={currentQuestion}
          activeQuestion={activeQuestion}
          nextQuestion={nextQuestion}
          setShowResults={() => setShowResults(true)}
        />
      )}
      {showResults && <QuizResult answers={answers} programs={programs} />}
    </div>
  );
};

export { Quiz };
