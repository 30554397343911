import * as React from "react";
import {useJobHHDataFormOnlyWithSalary} from "../../services/Job";

const SkeletonItemStyle = {
    width: "100%",
    height: "40px"
}


export function JobHHDataFormOnlyWithSalary() {
    const [isReady, value, setValue] = useJobHHDataFormOnlyWithSalary();
    const handleChange = React.useCallback((e) => {
        setValue(!value)
    }, [value, setValue]);


    if (isReady) {
        return (<div className="job-hh-search-panel-only-with-salary">
            <div className="job-hh-search-panel-only-with-salary__checkbox">
                <input type="checkbox"
                       id="JobHHDataFormOnlyWithSalary"
                       name="JobHHDataFormOnlyWithSalary"
                       checked={value} onChange={handleChange}/>
                <span>
                    <svg width="12" height="10" viewBox="0 0 15 13" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.685 2.38101L6.621 12.228L6.443 12.082L6.345 12.186L5.489 11.301L4.3 10.328L4.414 10.19L0.983 6.64301L2.987 4.49901L6.283 7.90601L12.364 0.480011L14.685 2.38101Z"
                              fill="#D0101B"/>
                    </svg>
                </span>
            </div>
            <label
                className="job-hh-search-panel-only-with-salary__label"
                htmlFor="JobHHDataFormOnlyWithSalary">
                Показывать только вакансии с указанной зарплатой
            </label>
        </div>)
    }


    return (
        <div className="skeleton">
            <div className="skeleton-item" style={SkeletonItemStyle}/>
        </div>
    );
}
