import * as React from "react";
import {Header} from "../header";
import {GoTopButton} from "../goTopButton";
import {Footer} from "../footer";
import {useJobHHDataIsReady} from "../../services/Job";
import {JobHHSearchPanel} from "./JobHHSearchPanel";
import {JobHHSearchList} from "./JobHHSearchList";


export function JobHH() {
    const isReady = useJobHHDataIsReady();
    return (<div className="wrapper wrapper--default">
        <Header/>
        <GoTopButton/>
        <div className="job-hh">
            <div className="job-hh__head">
                <div className="job-hh__head-text">Вакансии партнера hh.ru</div>
                <img className="job-hh__head-logo"
                     alt="HeadHunter"
                     width="50px"
                     height="50px"
                     src="/images/hh.png"/>
            </div>
            <JobHHSearchPanel/>
            {isReady ? (<JobHHSearchList/>) : null}
        </div>
        <Footer/>
    </div>);
}
