import React from "react";
import {ErrorPage} from "../../components/error-page";

function withRemoteModel(ComponentWithModel, promise) {


    return class extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                model: null,
                error: null
            }
        }

        componentDidMount() {
            promise.then(json => {
                this.setState({
                    model: json
                })
            }, error => {
                this.setState({
                    error: error
                })
            })
        }

        render() {
            return <>
                {this.state.model && <ComponentWithModel model={this.state.model} {...this.props} />}
                {this.state.error && <ErrorPage {...this.props}
                                                code="500"
                                                title="Ошибка сервера"
                                                text="На сервере произошла непредвиденная ошибка. <br /> Пожалуйста, подождите, она вскоре будет исправлена"
                />}
            </>;
        }

    }
}

function withRemoteModelFn(ComponentWithModel, promiseFn, canPartial = true) {


    return class extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                model: null,
                error: null
            }
        }

        componentDidMount() {
            promiseFn(this.props).then(json => {
                this.setState({
                    model: json
                })
            }, error => {
                this.setState({
                    error: error
                })
            })
        }

        render() {
            return <>
                {!this.state.error && (this.state.model || canPartial) &&
                <ComponentWithModel model={this.state.model} {...this.props} />}
                {!!this.state.error && <ErrorPage {...this.props}
                                                  code="500"
                                                  title="Ошибка сервера"
                                                  text="На сервере произошла непредвиденная ошибка. <br /> Пожалуйста, подождите, она вскоре будет исправлена"
                />}
            </>;
        }

    }
}

function withSharedRemoteModelFn(ComponentWithModel, promiseFn) {

    return class extends React.PureComponent {

        constructor(props) {
            super(props);
            this.state = {
                model: null
            }
        }

        componentDidMount() {
            promiseFn(this.props).then(json => {
                this.setState({
                    model: json
                })
            }, () => { /* ignore */ })
        }

        render() {
            return <ComponentWithModel model={this.state.model} {...this.props} />
        }
    }
}

export {withRemoteModel, withRemoteModelFn, withSharedRemoteModelFn};
