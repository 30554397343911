import React from "react";

import EmployerContactListReadyItem from "./EmployerContactListReadyItem";

function EmployerContactListReady({contacts}) {
    return (<div className="employer-contact-list">
        {contacts.map((i, idx) => (<EmployerContactListReadyItem item={i} key={idx} />))}
    </div>)
}

export default EmployerContactListReady;
