import React from "react";

const ItemStyle = {
    width: "255px",
    height: "255px",
}

function EmployerLinkListSkeletonItem() {
    return (<div className="skeleton employer-link-list-item">
        <div className="skeleton-item" style={ItemStyle}/>
    </div>)
}

export default EmployerLinkListSkeletonItem;
