import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {GoTopButton} from '../../components/goTopButton';
import {StepControl} from '../../components/step-control';
import {JobPageHead} from "../../components/job-page/head";

export const SelfPresentation = () => {
    return (
        <div className="wrapper wrapper--default">
            <Header/>
            <GoTopButton/>
            <div className="job__container">
                <JobPageHead/>
                <div className="job__step">Шаг 3</div>
                <div className="job__desc">
                    <h2>Самопрезентация</h2>
                    <p>
                        При подготовке к собеседованию и на самом собеседовании многие соискатели работы делают упор на
                        содержание
                        вопросов интервьюера и на содержание своих ответов. Но даже правильные ответы не дадут должного
                        эффекта,
                        если поведение соискателя по форме не является правильным.
                    </p>
                    <h3>Имидж</h3>
                    <img src="/images/pages/job-page/self-representation/image.jpg" alt="Имидж" />
                    <p>
                        У претендента на вакансию не будет возможности получить второй шанс на первое впечатление. Нужно
                        подать
                        себя правильно сразу, поскольку очень тяжело исправить неудачное первое впечатление. У 90%
                        людей,
                        встретившихся впервые, мнение о собеседнике формируется в течение первых минут или даже секунд.
                        И
                        основывается оно на внешности, физических характеристиках, Вашем облике, языке тела, одежде,
                        прическе, что
                        может привести к ошибке при оценке Ваших деловых и личных качеств. Одним из важных моментов,
                        повышающих
                        конкурентоспособность человека на рынке труда, является работа над собственным имиджем.
                    </p>
                    <p>
                        Если Вы желаете получить работу, закрепиться на новом месте, а тем более достичь успехов в
                        профессиональной деятельности, имидж является одним из важнейших условий успешной
                        профессиональной
                        деятельности и показателем возможностей личности.
                    </p>
                    <p>Впечатление о Вас зависит от правильной или не правильной подачи информации.</p>
                    <p>Впечатление о Вас зависит от того, каким вы себя представляете сами.</p>
                    <p>
                        В самопрезентации первое – это правильно одеться. Специалисты по кадрам отмечают следующие
                        ключевые
                        факторы, создающие благоприятное впечатление во время собеседования:
                    </p>
                    <p>
                        <b>Для мужчин:</b> хороший костюм и сумка; аккуратность; здоровый вид.
                        <br />
                        <b>Для женщин:</b> элегантная одежда; здоровый вид; привлекательная прическа; тонкий макияж.
                    </p>
                    <h3>Правило 30 секунд</h3>
                    <img src="/images/pages/job-page/self-representation/30sec.jpg" alt="Правило 30 секунд" />
                    <p>
                        Существует мнение, что у человека есть лишь 30 секунд, для того чтобы понравиться. Действует это
                        правило и
                        при трудоустройстве. Ваша задача – сделать так, чтобы после Вашего появления с Вами захотелось
                        общаться
                        ещё и ещё. Произвести позитивное первое впечатление поможет тщательно продуманный внешний облик
                        для визита
                        к потенциальному работодателю. Ваш голос – также немаловажный штрих к портрету, поэтому
                        постарайтесь,
                        чтобы он звучал уверенно, но вместе с тем вежливо. И, конечно, будьте пунктуальны – серьёзные
                        специалисты
                        на деловые встречи не опаздывают!
                    </p>
                    <p>
                        В самом начале собеседования, Ваши первые десять слов должны быть очень важными. Наиболее
                        эффективным
                        средством быстрого взаимопонимания будет выражение благодарности: «Спасибо, что вы нашли время
                        для встречи
                        со мной, уважаемый Иван Иванович». «Я очень рад встретиться лично с вами». Устанавливайте
                        зрительный
                        контакт с работодателем. Смотря прямо в глаза, Вы достигнете трёх целей: произведёте впечатление
                        уверенного в себе человека, человека со здоровым самомнением; Вас воспримут как хорошего
                        слушателя; Вам,
                        вероятно, окажут такое же вежливое внимание, когда Вы будете говорить.
                    </p>
                    <h4>Воспользуйтесь этими советами, сделайте третий шаг!</h4>
                </div>
                <StepControl
                    prev="/job/resume"
                    next="/job/interview"
                    stepTitlePrev="Шаг 2"
                    titlePrev="Составление резюме"
                    stepTitleNext="Шаг 4"
                    titleNext="Собеседование"
                />
            </div>
            <Footer/>
        </div>
    );
};
