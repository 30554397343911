import * as React from "react";
import fetch from "isomorphic-unfetch";
import {ErrorPage} from "../../components/error-page";


const JobHHItemDataContextInitial = {
    data: null,
    isLoading: true,
    isError: false
}

const JobHHItemDataContext = React.createContext(JobHHItemDataContextInitial);


export function JobHHItemData({id, children}) {
    let [ctx, setCtx] = React.useState(JobHHItemDataContextInitial);

    React.useEffect(() => {
        let canceled = false;

        function setCtxWithCheck(ctx) {
            if (!canceled) {
                setCtx(ctx);
            }
        }

        setCtxWithCheck(JobHHItemDataContextInitial);

        (async function () {
            try {
                const resp = await fetch("/api/job/hh/" + id)
                if (!resp.ok) {
                    setCtxWithCheck({
                        data: null,
                        isLoading: false,
                        isError: true
                    });
                    return;
                }
                setCtxWithCheck({
                    data: await resp.json(),
                    isLoading: false,
                    isError: false
                });
            } catch (e) {
                setCtxWithCheck({
                    data: null,
                    isLoading: false,
                    isError: true
                })
            }
        })();
        return () => {
            canceled = true;
        }
    }, [id]);

    let content = children;
    if (ctx.isError) {
        content = (<ErrorPage
            code="500"
            title="Ошибка сервера"
            text="На сервере произошла непредвиденная ошибка. <br /> Пожалуйста, подождите, она вскоре будет исправлена"
        />)
    } else if ((!ctx.isLoading && !ctx.data)) {
        content = (<ErrorPage
            code="500"
            title="Ошибка сервера"
            text="Не удалось получить данные от сервера"
        />)
    }

    return (<JobHHItemDataContext.Provider value={ctx}>
        {content}
    </JobHHItemDataContext.Provider>)
}


export function useJobHHItemData() {
    const ctx = React.useContext(JobHHItemDataContext);
    let isLoading = true;
    let data = null;
    if (ctx) {
        isLoading = !!ctx.isLoading;
        data = ctx.data;
    }
    return [isLoading, data];
}
