import React from "react";
import {Switch, Route} from "react-router-dom";
import {FrontPage} from "./pages/front-page";
import {Programs} from "./pages/programs";
import {ProgramDetail} from "./pages/program-detail";
import {NewsDetail} from "./pages/news-detail";
import {NewsAll} from "./pages/news-all";
import {Schedule} from "./pages/schedule";
import {ContactUs} from "./pages/contact-us";
import {About} from "./pages/about/about";
import {Policy} from "./pages/policy";
import "./styles/app.scss";
import {withRemoteModelFn} from "./hoc/remote-model";
import fetch from "isomorphic-unfetch";
import {ErrorPage} from "./components/error-page";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Partners} from "./pages/about/partners";
import {Docs} from "./pages/about/docs";
import {Staffs} from "./pages/about/staffs";
import {Teachers} from "./pages/about/teachers";
import {Teacher} from "./pages/about/teacher";
import {VideoMaterials} from "./pages/about/videoMaterials";
import {Faq} from "./pages/faq";
import {Contacts} from "./pages/contacts";
import {SearchPage} from "./pages/search-page";
import {QuizPage} from "./pages/quiz-page";
import {QuizKlimovPage} from "./pages/quiz-klimov";

import {JobIndexPage} from "./pages/JobIndexPage";
import {JobSteps} from './pages/job-page/steps';
import {CarierStart} from './pages/job-page/carier-start';
import {Interview} from './pages/job-page/interview';
import {JobSearch} from './pages/job-page/job-search';
import {Resume} from './pages/job-page/resume';
import {SelfPresentation} from './pages/job-page/self-presentation';
import {Announces} from "./pages/announces";
import {FeedbackPage} from "./pages/feedback-page";
import Keycloak from "keycloak-js";
import {JobResourcePage} from "./pages/job-page/resources";
import {JobHHItemPage} from "./pages/JobHHItemPage";
import {JobHHPage} from "./pages/JobHHPage";
import EmployerPage from "./pages/employer";
import CofinancePage from "./pages/cofinance";

//==== Главная страница


const FrontPageRest = withRemoteModelFn(FrontPage, props =>
    fetch("/api/front-page").then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    })
);

//==== Отзывы все
const FeedbackPageRest = withRemoteModelFn(FeedbackPage, props =>
    fetch("/api/feedback-page").then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    })
);



//==== Заявка на обучение


const ScheduleRest = withRemoteModelFn(
    withGoogleReCaptcha(Schedule),
    props =>
        fetch("/api/schedule").then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        }),
    false
);

//==== Сотрудники

const StaffsRest = withRemoteModelFn(Staffs, props =>
    fetch("/api/copp/staff").then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    })
);

//==== Партнеры

const PartnersRest = withRemoteModelFn(Partners, props =>
    fetch("/api/copp/partner").then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    })
);

//==== Частые вопросы

const FaqRest = withRemoteModelFn(Faq, props => {
    return fetch("/api/faq/").then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    });
});

//==== Документы

const DocsRest = withRemoteModelFn(Docs, props => {
    return fetch("/api/copp/doc").then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    });
});

//==== Преподаватели

const TeachersRest = withRemoteModelFn(Teachers, props => {
    return fetch("/api/copp/person").then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    });
});

//==== Преподаватель

const TeacherRest = withRemoteModelFn(Teacher, props => {
    return fetch("/api/copp/person/" + props.match.params["id"]).then(response => {
        if (response.ok) {
            return response.json();
        } else if (response.status === 404) {
            props.history.push("/about/teachers");
        } else {
            throw response;
        }
    });
});

const VideoMaterialsRest = withRemoteModelFn(VideoMaterials, props => {
    return fetch("/api/copp/video").then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    });
});

const AnnounceAllStub = withRemoteModelFn(Announces, props => {
    return new Promise((resolve, reject) => {
        resolve({
            announces: [
                {
                    dateEvent: "28.09",
                    title: "Мастер-класс “Питание в 21 веке”",
                    text: "Здоровое питание-основа долголетия. Хорошее состояние кожи, волос, ногтей, отличная работа огранизма-все это зависит от правильного питания.",
                    img: "/images/nail-designer.jpg",
                    link: "/news/17"
                },
                {
                    dateEvent: "28.09",
                    title: "Кондитерское и кондитерское дело",
                    text: "<p>Открывается набор на новую специальность для людей предпенсионного возраста.</p>",
                    img: "/images/nail-designer.jpg",
                    link: "/news/17"
                },
                {
                    dateEvent: "28.09",
                    title: "Сервировка и декор стола. Онлайн курс. Сервировка и декор стола. Онлайн курс",
                    text: "<p>Здесь научат сервировке стола к завтраку, обеду и ужину или праздику.</p><p> Владеть разными способами переноса блюд. Как убрать скатерть при гостях. Здесь научат сервировке стола к завтраку, обеду и ужину или праздику. Владеть разными способами переноса блюд. Как убрать скатерть при гостях.</p>",
                    img: "/images/nail-designer.jpg",
                    link: "/news/17"
                },
                {
                    title: "Серебряные волонтеры Вперед!",
                    text: "Приглашает серебряных волонтеров к нам на курс психологии “Счастливы вместе”",
                    img: "/images/nail-designer.jpg",
                    link: "/news/17"
                }
            ]
        });
    });
});

//==== Каталог новостей

const NewsAllRest = withRemoteModelFn(NewsAll, props =>
    fetch("/api/news").then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    })
);

const NewsAllStub = withRemoteModelFn(NewsAll, props => {
    return new Promise((resolve, reject) => {
        resolve({
            news: [
                {
                    date: "23 января 2020",
                    title: "Презентация программ Центра опережающей профессиональной подготовки",
                    text:
                        "<p>Мероприятие посетили заместитель Губернатора Тюменской Области Пантелеев Андрей Вадимович, директор Департамента образования и науки Тюменской области Райдер Алексей Владимирович, представители основных работодателей и профессиональных образовательных организаций региона.&nbsp;</p>",
                    img: "/file/6498bc08-eb5d-4947-beaa-334a17b95767",
                    link: "/news/17",
                    category: null,
                    externalLink: null
                },
                {
                    date: "23 января 2020",
                    title: "Презентация программ Центра опережающей профессиональной подготовки",
                    text:
                        "<p>Мероприятие посетили заместитель Губернатора Тюменской Области Пантелеев Андрей Вадимович, директор Департамента образования и науки Тюменской области Райдер Алексей Владимирович, представители основных работодателей и профессиональных образовательных организаций региона.&nbsp;</p><p>Мероприятие посетили заместитель Губернатора Тюменской Области Пантелеев Андрей Вадимович, директор Департамента образования и науки Тюменской области Райдер Алексей Владимирович, представители основных работодателей и профессиональных образовательных организаций региона.&nbsp;</p>",
                    img: "/file/6498bc08-eb5d-4947-beaa-334a17b95767",
                    link: "/news/17",
                    category: 1,
                    externalLink: null
                },
                {
                    date: "25 января 2020",
                    title: "Презентация программ Центра опережающей профессиональной подготовки",
                    text:
                        "<p>Мероприятие посетили заместитель Губернатора Тюменской Области Пантелеев Андрей Вадимович, директор Департамента образования и науки Тюменской области Райдер Алексей Владимирович, представители основных работодателей и профессиональных образовательных организаций региона.&nbsp;</p>",
                    img: "/file/6498bc08-eb5d-4947-beaa-334a17b95767",
                    link: "/news/17",
                    category: 2,
                    externalLink: null
                },
                {
                    date: "25 января 2020",
                    title: "Презентация программ Центра опережающей профессиональной подготовки",
                    text:
                        "<p>Мероприятие посетили заместитель Губернатора Тюменской Области Пантелеев Андрей Вадимович, директор Департамента образования и науки Тюменской области Райдер Алексей Владимирович, представители основных работодателей и профессиональных образовательных организаций региона.&nbsp;</p>",
                    img: "/file/6498bc08-eb5d-4947-beaa-334a17b95767",
                    link: "/news/17",
                    category: 3,
                    externalLink: null
                }
            ]
        });
    });
});

//==== Новость

const NewsDetailRest = withRemoteModelFn(NewsDetail, props => {
    return fetch("/api/news/" + props.match.params["id"]).then(response => {
        if (response.ok) {
            return response.json();
        } else if (response.status === 404) {
            props.history.push("/news");
        } else {
            throw response;
        }
    });
});

const NewsDetailStub = withRemoteModelFn(NewsDetail, props => {
    return new Promise((resolve, reject) => {
        resolve({
            date: "3 октября 2019",
            title: "Лицей и Вышка: что общего и чем отличаются",
            text:
                'На XIV Международный фестиваль школу современного искусства "Территория" Государственный молодежный театр Литвы привез lorem ipsum dolor sit amet',
            img: "/images/news-item.png",
            photos: [
                {
                    title: "Первая",
                    img: "/images/news-item.png"
                },
                {
                    title: "Вторая",
                    img: "/images/news-item.png"
                }
            ]
        });
    });
});

//==== Задать вопрос

const EnhancedContactUs = withGoogleReCaptcha(ContactUs);

//==== Контакты

const ContactsRest = withRemoteModelFn(Contacts, () => {
    return fetch("/api/contacts").then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    });
});

//==== О ЦОПП

const AboutStub = withRemoteModelFn(About, () => {
    return new Promise(resolve => {
        resolve({
            photos: [
                {
                    title: "Вторая",
                    img: "/images/about-building.png"
                },
                {
                    title: "Вторая",
                    img: "/images/news-item.png"
                }
            ],
            video: "https://vk.com/video_ext.php?oid=-134699547&id=456244621&hash=c0d16af61b7e7bb4"
        });
    });
});

const AboutRest = withRemoteModelFn(About, () => {
    return fetch("/api/copp/about").then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    });
});


function redirectToRegister(redirectUri) {
    let kc = new Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_BASE_URI,
        realm: "copp-work",
        clientId: "copp-my-site"
    });
    kc.init({
        promiseType: 'native'
    }).then(() => {
        window.location.href = kc.createRegisterUrl({
            redirectUri: redirectUri
        });
    })
}

function App() {
    return (
        <Switch>
            <Route path="/news" exact component={NewsAllRest}/>
            <Route path="/news/:id" exact component={NewsDetailRest}/>
            <Route path="/feedbacks" exact component={FeedbackPageRest}/>
            <Route path="/programs" exact component={Programs}/>
            <Route path="/program/:id" exact component={ProgramDetail}/>
            <Route path="/schedule" exact component={ScheduleRest}/>
            <Route path="/schedule/:id" exact component={ScheduleRest}/>
            <Route path="/contact-us" exact component={EnhancedContactUs}/>
            <Route path="/policy" exact component={Policy}/>
            <Route path="/about" exact component={AboutRest}/>
            <Route path="/about/partners" exact component={PartnersRest}/>
            <Route path="/about/docs" exact component={DocsRest}/>
            <Route path="/about/teachers" exact component={TeachersRest}/>
            <Route path="/about/staffs" exact component={StaffsRest}/>
            <Route path="/about/teachers/:id" exact component={TeacherRest}/>
            <Route path="/about/video" exact component={VideoMaterialsRest}/>
            <Route path="/contacts" exact component={ContactsRest}/>
            <Route path="/faq" exact component={FaqRest}/>
            <Route path="/" exact component={FrontPageRest}/>
            <Route path="/search" exact component={SearchPage}/>
            <Route path="/quiz_klimov" exact component={QuizKlimovPage}/>
            <Route path="/quiz/:id" exact component={QuizPage}/>
            <Route path="/job" exact component={JobIndexPage}/>
            <Route path="/job/hh" exact component={JobHHPage} />
            <Route path="/job/hh/:id" exact component={JobHHItemPage}/>
            <Route path="/job/steps" exact component={JobSteps}/>
            <Route path="/job/job-search" exact component={JobSearch}/>
            <Route path="/job/resources" exact component={JobResourcePage} />
            <Route path="/job/resume" exact component={Resume}/>
            <Route path="/job/self-presentation" exact component={SelfPresentation}/>
            <Route path="/job/interview" exact component={Interview}/>
            <Route path="/job/carier-start" exact component={CarierStart}/>
            <Route path="/employer" exact component={EmployerPage} />
            <Route path="/cofinance" exact component={CofinancePage} />
            <Route path="/reg/staff" exact component={() => {
                redirectToRegister("https://platform.copp72.ru/app");
                return (<div className="redirect-message">Переход на страницу регистрации...</div>);
            }}/>
            <Route path="/reg/person" exact component={() => {
                redirectToRegister("https://platform.copp72.ru/lk");
                return (<div className="redirect-message">Переход на страницу регистрации...</div>);
            }}/>
            <Route
                render={props => (
                    <ErrorPage
                        {...props}
                        code="404"
                        title="Страница не найдена"
                        text="Ссылка, по которой вы перешли, никуда не ведет. <br/>Возможно в ней была опечатка или эта страница была удалена."
                    />
                )}
            />
        </Switch>
    );
}

export default App;
