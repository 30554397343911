import React, { useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

const Radio = props => {
  const [id] = useState(_uniqueId('radio-'));
  return (
    <div className='custom-radio'>
      <div className="custom-radio__input">
        <input id={id}
               type="radio"
               tabIndex={props.tabindex}
               name={props.name}
               value={props.value}
			   checked={props.checked}
               required={props.required}
               onChange={props.onRadioChanged}/>
        <span></span>
      </div>

      <label htmlFor={id}>{props.label}</label>
    </div>
  )
};

export {Radio};
