import React, {useRef, useState} from "react";
import {Header} from "../../components/header";
import {Footer} from "../../components/footer";
import {Link} from "react-router-dom";
import {PageTitle} from "../../components/page-title";
import {TabMenu} from "../../components/tab-menu";
import {GotQuestion} from "../../components/got-question";
import {GoTopButton} from '../../components/goTopButton'
import {aboutTabMenuModel} from '../../api/api'
import Slider from "react-slick";
import {Accordion} from '../../components/accordion';

const About = props => {
    const slider = useRef(null);
    const [openVideo, setOpenVideo] = useState(false);
    const isYoutube = props.model && props.model.video && props.model.video.indexOf("youtube") !== -1;

    const sliderSettings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <PrevArrow/>
                <ul> {dots} </ul>
                <NextArrow/>
            </div>
        )
    };

    const PrevArrow = () => {
        return (
            <div onClick={() => slider.current.slickPrev()} className="carousel__arrow">
                <svg width="41" height="16" viewBox="0 0 41 16" fill="none">
                    <path
                        d="M-0.000976562 8.38197L7.20903 16.001L8.99503 14.472L3.81302 8.99597H41.005V7.00497H3.82803L9.01902 1.52497L7.23103 -0.00402832L0.0130234 7.61497L0.454025 7.99197L-0.000976562 8.38197Z"
                        fill="#d0101b"
                    />
                </svg>
            </div>
        );
    };

    const NextArrow = () => {
        return (
            <div onClick={() => slider.current.slickNext()} className="carousel__arrow">
                <svg width="41" height="16" viewBox="0 0 41 16" fill="none">
                    <path
                        d="M41.005 8.38197L33.795 16.001L32.009 14.472L37.191 8.99597H-0.000976562V7.00497H37.176L31.985 1.52497L33.773 -0.00402832L40.991 7.61497L40.55 7.99197L41.005 8.38197Z"
                        fill="#d0101b"
                    />
                </svg>
            </div>
        );
    };

    let hasPhotos = props.model && props.model.photos && props.model.photos.length > 0;
    return (
        <div className="wrapper wrapper--default">
            <Header/>

            <GoTopButton/>

            <div className="container container--about">
                <PageTitle>О центре</PageTitle>

                <TabMenu menuModel={aboutTabMenuModel}/>


                <div className="block-margin"/>

                <div className="about-center">
                    <div className="about-center-top">
                        {hasPhotos && (
                            <>
                                <Slider {...sliderSettings} ref={slider}>
                                    {props.model.photos.map((item, index) => (
                                        <div className="about-center-top__img" key={index}>
                                            <img src={item.img} alt={item.title}/>
                                        </div>
                                    ))}
                                </Slider>
                            </>
                        )}
                        <div className="about-center-top__text">Учим профессиям будущего</div>
                    </div>

                    <div className="block-margin"/>

                    <h2 className="block-title">Центр опережающей профессиональной подготовки</h2>

                    <div className="styled-redactor">
                        <p>
                            ЦОПП осуществляет разработку и реализацию программ подготовки,
                            переподготовки и
                            повышения квалификации граждан по перечню компетенций опережающей профессиональной
                            подготовки:
                        </p>

                        <ul>
                            <li>
                                программы профессиональных модулей для интегрирования в образовательные программы
                                среднего
                                профессионального образования;
                            </li>
                            <li>программы для обучающихся общеобразовательных организаций;</li>
                            <li>программы под заказ работодателей;</li>
                            <li>отраслевые программы;</li>
                            <li>программы для граждан предпенсионного возраста;</li>
                            <li>программы по компетенциям будущего, включая компетенции цифровой экономики.</li>
                        </ul>

                        <p>
                            <Link to="/about/docs" className="underline-link">
                                Документы
                                <svg width="17" height="12" viewBox="0 0 17 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M16.28 7.01309L11.88 12.0001L10.631 10.8531L14.019 7.01309H-0.000976562V4.98209H14.024L10.559 1.14309L11.837 -0.00390625L16.336 4.98209H16.994V7.01309H16.28Z"
                                        fill="#D0101B"
                                    />
                                </svg>
                            </Link>
                        </p>
                    </div>

                    <div className="block-margin"/>

                    {props.model && props.model.partners && <Accordion items={props.model.partners}/>}

                    {props.model && props.model.video && (
                        <div className="about-center-video">
                            <h2 className="about-center-video-title">Обучение в ЦОПП</h2>
                            <div
                                className={
                                    openVideo ? "about-center-video-content-wrapper-hidden" : "about-center-video-content-wrapper"
                                }
                                onClick={() => setOpenVideo(true)}
                            >
                                <div
                                    className={openVideo ? "about-center-video-content-hidden" : "about-center-video-content"}>
                                    <img src={props.model.video.img ? props.model.video.img : "/images/video_grid.png"}
                                         alt=""/>
                                    <img
                                        src="/images/play.png"
                                        alt=""

                                    />
                                </div>
                                {openVideo && (
                                    <iframe
                                        title="videoSingle"
                                        src={`${props.model.video}${isYoutube ? "?autoplay=1" : "&autoplay=1,"}`}
                                        frameBorder="0"
                                        allowFullScreen
                                    ></iframe>
                                )}
                            </div>
                            <p>
                                Как проходит обучение в ЦОПП и многое другое можно узнать из наших видео. У нас еще
                                много интересного.
                            </p>
                            <Link to="/about/video" className="underline-link">
                                Видеоматериалы
                                <svg width="17" height="12" viewBox="0 0 17 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M16.28 7.01309L11.88 12.0001L10.631 10.8531L14.019 7.01309H-0.000976562V4.98209H14.024L10.559 1.14309L11.837 -0.00390625L16.336 4.98209H16.994V7.01309H16.28Z"
                                        fill="#D0101B"
                                    />
                                </svg>
                            </Link>
                        </div>
                    )}

                    <div className="block-margin"/>

                    <div className="about-center-block">
                        <div className="about-center-block__img">
                            <img src="/images/about-photo-1.png" alt=""/>
                        </div>

                        <div className="about-center-block__info">
                            <h2 className="block-title">Партнеры</h2>
                            <div className="about-center-block__text">
                                ЦОПП сотрудничает с образовательными организациями Тюменской области. Это позволяет нам
                                формировать
                                образовательные программы, исходя из потребностей рынка и предоставляя учащимся все
                                необходимое для
                                максимально эффективного обучения и практики.
                            </div>

                            <Link to="/about/partners" className="about-center-block__link underline-link">
                                Наши партнеры
                                <svg width="17" height="12" viewBox="0 0 17 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M16.28 7.01309L11.88 12.0001L10.631 10.8531L14.019 7.01309H-0.000976562V4.98209H14.024L10.559 1.14309L11.837 -0.00390625L16.336 4.98209H16.994V7.01309H16.28Z"
                                        fill="#D0101B"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div>

                    <div className="block-margin"/>

                    <div className="about-center-block about-center-block">
                        <div className="about-center-block__img">
                            <img src="/images/about-photo-2.png" alt=""/>
                        </div>

                        <div className="about-center-block__info">
                            <h2 className="block-title">Преподаватели</h2>

                            <div className="about-center-block__text">
                                Преподаватели ЦОПП — это талантливые представители разных индустрий.
                                Все наши преподаватели - профессионалы своего дела,
                                каждый из них уже успешно реализовался в своей сфере деятельности и достиг высоких
                                результатов.
                            </div>

                            {false && <Link to="/about/teachers" className="about-center-block__link underline-link">
                                Наши преподаватели
                                <svg width="17" height="12" viewBox="0 0 17 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M16.28 7.01309L11.88 12.0001L10.631 10.8531L14.019 7.01309H-0.000976562V4.98209H14.024L10.559 1.14309L11.837 -0.00390625L16.336 4.98209H16.994V7.01309H16.28Z"
                                        fill="#D0101B"
                                    />
                                </svg>
                            </Link>}
                        </div>
                    </div>

                    <div className="block-margin"/>
                </div>
            </div>
            <GotQuestion/>
            <Footer/>
        </div>
    );
};

export {About};
