import React from "react";
import {DoubleLink, trimTextFromSpace} from "../../api/functions";
import {Link} from "react-router-dom";
import Select from "react-select";
import {VideoModal} from "../videoModal";
import {ReadMore} from "../read-more";

const activityAllId = -1;
const activityAllName = 'Все';

const FeedbackList = (props) => {

    const [feedbackList, setFeedbackList] = React.useState(props.feedbacks || null);
    const [selectYear, setSelectYear] = React.useState(props.maxYear || null);
    const [currentActivity, setCurrentActivity] = React.useState({value: activityAllId, label: activityAllName});
    const [hasChanges, setHasChanges] = React.useState(false);
    const [videoLink, setVideoLink] = React.useState(null);
    const [openVideoModal, setOpenVideoModal] = React.useState(null);

    const openModal = React.useCallback((link) => {
        setVideoLink(link);
        setOpenVideoModal(true);
    }, [setVideoLink, setOpenVideoModal]);

    const closeModal = React.useCallback(() => {
        setVideoLink(null);
        setOpenVideoModal(false);
    }, [setVideoLink, setOpenVideoModal]);

    const selectActivities = [{value: activityAllId, label: activityAllName}];
    props.activities && props.activities.forEach(item => {
        selectActivities.push({value: item.id, label: item.name});
    });

    React.useEffect(() => {
        if (props.maxYear != null && !hasChanges) {
            onSelectYear(props.maxYear);
        }
    }, []);

    const onSelectYear = year => {
        setHasChanges(true);
        onFilterFeedbacks(year, currentActivity);
        setSelectYear(year);
    };

    const onSelectActivity = a => {
        setHasChanges(true);
        onFilterFeedbacks(selectYear, a);
        setCurrentActivity(a);
    };

    const onFilterFeedbacks = (year, activity) => {
        const fullList = props.feedbacks;
        let flist;
        if (activity === null || activity.value === activityAllId) {
            flist = fullList.filter(f => f.year === year);
        } else {
            flist = fullList.filter(f => f.activityName === activity.label && f.year === year);
        }
        setFeedbackList(flist);
    }

    return (
        <>
            <div className={
                props.noWraper ? "feedback_list__wrapper--no-wraper" : "feedback_list__wrapper"
            }>
                <div className="container feedback_list__container">
                    {!props.noTitle && (
                        <>
                            <div className="feedback_list__title">Отзывы выпускников</div>
                            <div className="feedback_list__subtitle">Мы гордимся нашими выпускниками, они рады, что
                                учились у нас.
                            </div>
                        </>
                    )}

                    <div className="feedback_list-filter">
                        <div className="feedback_list-filter__years">
                            <ul>
                                {props.years.map(year => {
                                    return (<li key={year} onClick={() => onSelectYear(year)}
                                                className={year === selectYear ? "feedback_list-filter__years-active" : ""}>{year}</li>)
                                })
                                }
                            </ul>
                        </div>
                        <div className="feedback_list-filter__select">
                            <Select value={currentActivity}
                                    onChange={(e) => onSelectActivity(e)}
                                    placeholder="Выберите направление..."
                                    isSearchable={true}
                                    className="crs"
                                    classNamePrefix="crs"
                                    noOptionsMessage={() => 'Направлений не найдено'}
                                    options={selectActivities}/>
                        </div>
                    </div>

                    <div className="feedback_list">
                        {feedbackList && feedbackList.map((item, index) => {
                            if (item.videoLink) {
                                return (
                                    <div className="feedback_list-item"
                                         key={`feedback-${index}`}>
                                        <div className="feedback_list-item__block">
                                            <p className="feedback_list-item__block_link">
                                                Ссылка на видео ресурс: <a href={item.videoLink}>{item.videoLink}</a>
                                            </p>
                                            <div className="feedback-main-slider-item__video"
                                                 onClick={() => openModal(item.videoLink)}>
                                                <div className="feedback-main-slider-item__video-img">
                                                    <img
                                                        src={
                                                            item.imgBig ? item.imgBig : "/images/photo-pattern-large.jpg"
                                                        }
                                                        alt=""
                                                    />
                                                    <img className="feedback-main-slider-item__video-img-play"
                                                         src="/images/play.png" alt=""/>
                                                </div>
                                                <div className="feedback-main-slider-item__video-source">
                                                    <img
                                                        src={
                                                            item.videoLink.indexOf("youtube") !== -1
                                                                ? "/images/youtube-small.png"
                                                                : "/images/vk-small.png"
                                                        }
                                                        alt=""
                                                    />
                                                    <span>{`${
                                                        item.videoLink.indexOf("youtube") !== -1
                                                            ? "Youtube"
                                                            : "Вконтакте"
                                                    }`}</span>
                                                </div>
                                            </div>
                                            <div className="feedback_list-item__info">
                                                <div className="feedback_list-item__title">
                                                    {item.studentName}
                                                </div>
                                                <div
                                                    className="feedback_list-item__text"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.text,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="feedback_list-item__date">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12 0C12.5523 0 13 0.447715 13 1V2C14.6569 2 16 3.34315 16 5V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V5C0 3.34315 1.34315 2 3 2V1C3 0.447715 3.44772 0 4 0C4.55228 0 5 0.447715 5 1V2H11V1C11 0.447715 11.4477 0 12 0ZM14 8H2V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V8ZM13 4H3C2.44772 4 2 4.44772 2 5V6H14V5C14 4.44772 13.5523 4 13 4Z"
                                                    fill="#D0101B"/>
                                            </svg>
                                            {item.date}
                                        </div>
                                        <div className="feedback_list-item__program">
                                            Программа:&nbsp;
                                            <Link to={item.program.link} key={index}>
                                                {item.program.name}
                                            </Link>
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="feedback_list-item"
                                         key={`feedback-${index}`}>
                                        <div className="feedback_list-item__block">
                                            <div className="feedback_list-item__img">
                                                <img src={item.imgBig} alt=""/>
                                            </div>
                                            <div className="feedback_list-item__info">
                                                <div className="feedback_list-item__title">
                                                    {item.studentName}
                                                </div>
                                                <div
                                                    className="feedback_list-item__text"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.text,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="feedback_list-item__date">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12 0C12.5523 0 13 0.447715 13 1V2C14.6569 2 16 3.34315 16 5V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V5C0 3.34315 1.34315 2 3 2V1C3 0.447715 3.44772 0 4 0C4.55228 0 5 0.447715 5 1V2H11V1C11 0.447715 11.4477 0 12 0ZM14 8H2V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V8ZM13 4H3C2.44772 4 2 4.44772 2 5V6H14V5C14 4.44772 13.5523 4 13 4Z"
                                                    fill="#D0101B"/>
                                            </svg>
                                            {item.date}
                                        </div>
                                        <div className="feedback_list-item__program">
                                            Программа:&nbsp;
                                            <Link to={item.program.link} key={index}>
                                                {item.program.name}
                                            </Link>
                                        </div>
                                    </div>
                                );
                            }
                        })
                        }
                    </div>
                </div>
            </div>

            <VideoModal open={openVideoModal} link={videoLink} close={closeModal}/>
        </>);
};

export {FeedbackList};
