import * as React from "react";
import {format, parseISO} from "date-fns";
import {ru} from "date-fns/locale";
import {Link} from "react-router-dom";
import {Button} from "../button";

export function JobHHItemReady({vacancy}) {
    if (!vacancy) {
        return null;
    }
    let name = null;
    if (vacancy.name) {
        name = (<div className="job-hh-item-ready__name">{vacancy.name}</div>)
    }

    let salary = null;
    let salaryFrom = null;
    let salaryTo = null;
    if (vacancy.salaryFrom) {
        salaryFrom = Number(vacancy.salaryFrom).toLocaleString("ru-RU");
    }
    if (vacancy.salaryTo) {
        salaryTo = Number(vacancy.salaryTo).toLocaleString("ru-RU");
    }
    if (salaryFrom || salaryTo) {
        let salaryStr = '';
        if (salaryFrom) {
            salaryStr = salaryStr + ' от ' + salaryFrom;
        }
        if (salaryTo) {
            salaryStr = salaryStr + ' до ' + salaryTo;
        }
        if (vacancy.currency) {
            salaryStr = salaryStr + ' ' + vacancy.currency;
        }
        if (vacancy.gross === false) {
            salaryStr = salaryStr + ' на руки ';
        }

        salary = (<div className="job-hh-item-ready__salary">{salaryStr}</div>)

    }

    let org = null;
    if (vacancy.orgName) {
        let orgLogo = null;
        let orgInfo = null;

        if (vacancy.orgLogo) {
            orgLogo = (<div className="job-hh-item-ready__org-logo">
                <img className="job-hh-item-ready__org-logo-img"
                     src={vacancy.orgLogo}
                     alt={vacancy.orgName}/>
            </div>)
        }

        let address = null;
        let addressStr = '';
        if (vacancy.city) {
            addressStr = addressStr + vacancy.city;
        }
        if (vacancy.street) {
            if (addressStr !== '') {
                addressStr = addressStr + ', ';
            }
            addressStr = addressStr + vacancy.street;
        }
        if (vacancy.building) {
            if (addressStr !== '') {
                addressStr = addressStr + ', ';
            }
            addressStr = addressStr + vacancy.building;
        }

        if (addressStr !== '') {
            address = (<div className="job-hh-item-ready__org-address">{addressStr}</div>)
        }
        orgInfo = (<div className="job-hh-item-ready__org-info">
            <div className="job-hh-item-ready__org-name">{vacancy.orgName}</div>
            {address}
        </div>)

        org = (<div className="job-hh-item-ready__org">
            {orgLogo}
            {orgInfo}
        </div>)
    }

    let experienceStr = (vacancy.experienceName ? vacancy.experienceName : 'не требуется').toLowerCase();
    let experience = (<div className="job-hh-item-ready__props-experience">
        Требуемый опыт работы: {experienceStr}
    </div>)

    let schedule = null;
    if (vacancy.scheduleName || vacancy.employmentName) {
        let scheduleStr = '';
        if (vacancy.employmentName) {
            scheduleStr = scheduleStr + vacancy.employmentName;
        }
        if (vacancy.scheduleName) {
            if (scheduleStr !== '') {
                scheduleStr = scheduleStr + ', ';
            }
            scheduleStr = scheduleStr + vacancy.scheduleName.toLowerCase();
        }

        schedule = (<div className="job-hh-item-ready__props-schedule">{scheduleStr}</div>)
    }

    let props = (<div className="job-hh-item-ready__props">
        {experience}
        {schedule}
    </div>)


    let description = null;
    if (vacancy.brandedDescription) {
        description = (<div
            className="job-hh-item-ready__content-rich"
            dangerouslySetInnerHTML={{__html: vacancy.brandedDescription}}/>);
    } else if (vacancy.description) {
        description = (<div
            className="job-hh-item-ready__content"
            dangerouslySetInnerHTML={{__html: vacancy.description}}/>);
    }

    let publish = null;
    if (vacancy.publishedAt) {
        let parsed = parseISO(vacancy.publishedAt);
        let formatted = format(parsed, 'd MMMM yyyy', {locale: ru});
        publish = (<div key="date" className="job-hh-item-ready__date">
            Вакансия опубликована {formatted}
        </div>);
    }

    let toHH = null;
    if (vacancy.hhUrl) {
        toHH = (<a href={vacancy.hhUrl} target="_blank" className="job-hh-item-ready__to-hh">
            <Button className="btn--secondary">Посмотреть вакансию на hh.ru</Button>
        </a>);
    }

    return (<div className="job-hh-item-ready">
        {name}
        {salary}
        {org}
        {props}
        {description}
        {publish}
        <div className="job-hh-item-ready__source">
            <img className="job-hh-item-ready__source-img"
                 alt="HeadHunter"
                 width="20px"
                 height="20px"
                 src="/images/hh.png"/>
            <span className="job-hh-item-ready__source-text">Вакансия найдена на hh.ru</span>
        </div>
        {toHH}
        <Link to="/job/hh" className="job-hh-item-ready__return">
            <svg width="17" height="12" viewBox="0 0 17 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M17.001 7.01401H2.98098L6.36898 10.853L5.11998 12.001L0.719981 7.01401H0.00598145V4.98201H0.663981L5.16298 -0.00299072L6.43998 1.14401L2.97598 4.98201H17.001V7.01401Z"
                      fill="#D0101B"/>
            </svg>
            <span>Все вакансии</span>
        </Link>
    </div>);
}
