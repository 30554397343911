import React, { useState } from "react";
import classNames from "classnames";

const AccordionItem = (props) => {
  const [isOpen, setOpen] = useState(false);
  const accordionItemClasses = classNames({
    "accordion-item": true,
    "accordion-item--open": isOpen,
    "accordion-item--no-img": !props.img,
  });

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <div className={accordionItemClasses}>
      <div className="accordion-item__top" onClick={toggleOpen}>
        {!!props.img && (
          <>
            <div className="accordion-item__img">
              <img src={props.img} alt="" />
            </div>
          </>
        )}

        <div className="accordion-item__title">{props.title || props.name}</div>

        <svg
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.996 10.0021L-0.00500488 -0.00390625H11.997L5.996 10.0021Z"
            fill="#778899"
          />
        </svg>
      </div>

      <div className="accordion-item__body">
	  {!!props.docs && props.docs.map((item, index) => (
            <a
              href={item.link}
              className="about-docs-item"
              key={`docs_item_${index}`}
              download={`${item.title}.${item.extension}`}
            >
              <div className="about-docs-item__img">
                <img src="/images/about-doc.png" alt="" />
              </div>

              <div className="about-docs-item__body">
                <span className="about-docs-item__title">{item.title}</span>
                <div className="about-docs-item__info">{`${item.extension}, ${item.size}`}</div>
              </div>
            </a>
          ))}
		  
        {!!props.region && (
          <div className="accordion-item__region">{props.region}</div>
        )}

        {!!props.text && (
          <>
            <div
              className="styled-redactor accordion-item__text"
              dangerouslySetInnerHTML={{ __html: props.text }}
            />
          </>
        )}

        {!!props.phone &&
          props.phone.length > 0 &&
          props.phone.map((item, index) => (
            <a
              href={"tel:" + item}
              className="accordion-item__phone"
              key={`accordion_phone_${index}`}
            >
              {item}
            </a>
          ))}

        {!!props.email &&
          props.email.length > 0 &&
          props.email.map((item, index) => (
            <a
              href={"mailto:" + item}
              className="accordion-item__email"
              key={`accordion_email_${index}`}
            >
              {item}
            </a>
          ))}

        {!!props.site && (
          <>
            <a href={props.site} className="accordion-item__site">
              {props.site}
              <svg
                width="15"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="accordion-item__site-svg"
              >
                <path
                  d="M1.33333 0C0.98052 0.00261677 0.6429 0.143933 0.393416 0.393416C0.143933 0.6429 0.00261677 0.98052 0 1.33333V10.6667C0.00261677 11.0195 0.143933 11.3571 0.393416 11.6066C0.6429 11.8561 0.98052 11.9974 1.33333 12H10.6667C11.0195 11.9974 11.3571 11.8561 11.6066 11.6066C11.8561 11.3571 11.9974 11.0195 12 10.6667V6H10.6667V10.6667H1.33333V1.33333H6V0H1.33333Z"
                  fill="#C6CFD8"
                />
                <path
                  d="M7.33331 0V1.33333H9.72442L3.52887 7.52889L4.47109 8.47111L10.6666 2.27556V4.66667H12V0H7.33331Z"
                  fill="#C6CFD8"
                />

                <defs>
                  <clipPath id="clip0">
                    <rect width="12" height="12" fill="#C6CFD8" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </>
        )}
      </div>
    </div>
  );
};

const Accordion = (props) => {
  return (

	<div className="accordion">
      {props.items.map((item, index) => (
        <AccordionItem {...item}  key={`accordion_item_${index}`} />
      ))}
    </div>
  );
};

export { Accordion };
