import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Scrollbars} from 'react-custom-scrollbars';
import {Header} from '../header';
import {Footer} from '../footer';
import {GoTopButton} from '../goTopButton';

import {DocItem} from "../doc-item";
import {JobHead} from "../JobCommon";
import {Button} from "../button";
import {JobIndexVacancyList} from "./JobIndexVacancyList";

const SourceItemListData = [
    {
        title: "АО Федеральная корпорация по развитию малого и среднего предпринимательства",
        link: "https://corpmsp.ru/",
        img: "/images/pages/job-page/corpmsp_ru.png"
    }, {
        title: "Универсариум (Проект ООО \"Курсариум\")",
        link: "https://universarium.org/",
        img: "/images/pages/job-page/universarium_org.png"
    }, {
        title: "20.35 Университет НТИ",
        link: "https://2035.university",
        img: "/images/pages/job-page/2035_university.png"
    },
    {
        title: "Национальный проект \"Малое и среднее предпринимательство и поддержка предпринимательской инициативы\" Министерства экономического развития Российской Федерации",
        link: "https://мойбизнес.рф",
        img: "/images/pages/job-page/my_business.png"
    },
    {
        title: "Общероссийская база вакансий Федеральной службы по труду и занятости",
        link: "https://trudvsem.ru/",
        img: "/images/pages/job-page/trudvsem_ru.png"
    },
    {
        title: "Группа компаний HeadHunter",
        link: "https://hh.ru/",
        img: "/images/pages/job-page/hh_ru.png"
    },
    {
        title: "Neuvoo. Онлайн ресурс для поиска работы",
        link: "https://neuvoo.ru/",
        img: "/images/pages/job-page/neuvoo_ru.png"
    },
    {
        title: "Агрегатор вакансий Jooble в России",
        link: "https://ru.jooble.org/",
        img: "/images/pages/job-page/ru_jooble_org.png"
    },
    {
        title: "IT-сервис по поиску работы и подбору сотрудников",
        link: "https://www.superjob.ru/",
        img: "/images/pages/job-page/superjob_ru.png"
    },
    {
        title: "Агрегатор вакансий",
        link: "https://www.trud.com/",
        img: "/images/pages/job-page/trud_com.png"
    },
    {
        title: "Агрегатор вакансий",
        link: "https://www.rabota.ru/",
        img: "/images/pages/job-page/rabota_ru.png"
    },
    {
        title: "Агрегатор вакансий",
        link: "https://russia.zarplata.ru/",
        img: "/images/pages/job-page/zarplata_ru.png"
    },
    {
        title: "Вакансии государственной службы",
        link: "https://gossluzhba.gov.ru/",
        img: "/images/pages/job-page/gossluzhba_gov_ru.png"
    },
    {
        title: "Кадровое агентство",
        link: "https://ancor.ru/",
        img: "/images/pages/job-page/ancor_ru.png"
    },
    {
        title: "Компания \"FutureToday\" - работа для студентов и выпускников",
        link: "https://fut.ru/",
        img: "/images/pages/job-page/fut_ru.png"
    },
    {
        title: "Академия Яндекса",
        link: "https://academy.yandex.ru/",
        img: "/images/pages/job-page/academy_yandex_ru.png"
    },
    {
        title: "Совместный проект платформы «Россия – страна возможностей» и Общероссийского народного фронта Профстажировки 2.0",
        link: "https://Профстажировки.рф/",
        img: "/images/pages/job-page/prof_prework.png"
    },
];


const UsefulItemListData = [
    {
        title: "Атлас новых профессий",
        link: "http://atlas100.ru/",
        img: "/images/pages/job-page/atla100.png"
    },
    {
        title: "Навигатум",
        link: "https://navigatum.ru/",
        img: "/images/pages/job-page/navigatum.png"
    },
    {
        title: "ПроеКТОриЯ",
        link: "https://proektoria.online/",
        img: "/images/pages/job-page/proektoria.png"
    },
    {
        title: "Профориентатор",
        link: "https://proforientator.ru/tests/",
        img: "/images/pages/job-page/prof_orient.png"
    },
    {
        title: "ПрофВыбор.ру",
        link: "http://profvibor.ru/",
        img: "/images/pages/job-page/prof_select.png"
    },
    {
        title: "Online Test Pad (Профессиональные предпочтения)",
        link: "https://onlinetestpad.com/ru",
        img: "/images/pages/job-page/onlinetestpad.png"
    },
    {
        title: "Калейдоскоп профессий",
        link: "https://paramult.ru/",
        img: "/images/pages/job-page/paramult.png"
    },
    {
        title: "Профтест",
        link: "https://careertest.ru/",
        img: "/images/pages/job-page/careertest.png"
    }
]

const WorkItemListData = [
    {
        name: [
            "Служба содействия трудоустройства выпускников",
            "ГАПОУ ТО «Голышмановский агропедагогический колледж»"
        ],
        img: "/images/pages/job-page/agropedcollege_ru.png",
        link: "http://agropedcollege.ru/служба-содействия-трудоустройству-в/"
    },
    {
        name: [
            "Служба содействия трудоустройства выпускников",
            "ГАПОУ ТО «Тюменский колледж цифровых и педагогических технологий»"
        ],
        img: "/images/pages/job-page/tpk-1_ru.svg",
        link: "http://tpk-1.ru/содействие-трудоустройству/"
    },
    {
        name: [
            "Служба содействия трудоустройства выпускников",
            "ГАПОУ ТО «Тобольский многопрофильный центр»"
        ],
        img: "/images/pages/job-page/tmt72_ru.jpg",
        link: "https://tmt72.ru/student-2/employ-2/"
    },
    {
        name: [
            "Центр содействия трудоустройства выпускников",
            "ГАПОУ ТО «Тюменский техникум индустрии питания, коммерции и сервиса»"
        ],
        img: "/images/pages/job-page/mck72_ru.png",
        link: "https://mck72.ru/learners/placement/"
    },
    {
        name: [
            "ГАПОУ ТО «Тюменский техникум строительной индустрии и городского хозяйства»"
        ],
        img: "/images/pages/job-page/tci72_ru.png",
        link: "http://tci72.ru/technical-college/trudoustroystvo/"
    },
    {
        name: [
            "Служба профориентации и содействия трудоустройства выпускников",
            "ГАПОУ ТО «Агротехнологический колледж» г. Ялуторовск"
        ],
        img: "/images/pages/job-page/yalagrokoll_ru.png",
        link: "http://yalagrokoll.ru/sstv/"
    },
    {
        name: [
            "Служба содействия трудоустройства выпускников",
            "ГАПОУ ТО «Ишимский медицинский колледж»"
        ],
        img: "/images/pages/job-page/med-ishim_ru.png",
        link: "http://med-ishim.ru/pages/deyatelnost-sstv"
    },
    {
        name: [
            "Центр содействия трудоустройства выпускников",
            "ГАПОУ ТО «Ишимский многопрофильный техникум»"
        ],
        img: "/images/pages/job-page/imt-ishim_ru.jpg",
        link: "http://www.imt-ishim.ru/vypusknikam/tsentr-sodejstviya-trudoustrojstvu"
    },
    {
        name: [
            "Центр содействия трудоустройства выпускников",
            "ГАПОУ ТО «Тобольский медицинский колледж имени Володи Солдатова»"
        ],
        img: "/images/pages/job-page/tobmk_ru.png",
        link: "https://tobmk.ru/цств/"
    },
    {
        name: [
            "Центр трудоустройства выпускников ГАПОУ ТО «Тюменский колледж производственных и социальных технологий»"
        ],
        img: "/images/pages/job-page/tkpst_ru.svg",
        link: "http://tkpst.ru/graduates/search-vacancies/"
    },
    {
        name: [
            "Служба содействия трудоустройства выпускников",
            "ГАПОУ ТО «Тюменский колледж транспортных технологий и сервиса»"
        ],
        img: "/images/pages/job-page/tktts_ru.png",
        link: "https://tktts.ru/menu/9/"
    },
    {
        name: [
            "Центр профориентации ГАПОУ ТО «Тюменский медицинский колледж»"
        ],
        img: "/images/pages/job-page/goutmk_ru.png",
        link: "http://goutmk.ru/depts/practice/proforient"
    }
]

const TipListData = [
    "Помните, что новые возможности открываются тому, кто умеет искать и видеть их.",
    "Со всей настойчивостью стремитесь к той работе, которую Вы больше всего хотите.",
    "Работа должна доставлять удовольствие, а её поиск не должен затягиваться надолго."
]

export const JobIndex = () => {

    const [showAllWork, setShowAllWork] = useState(false);

    const workList = [];


    for (let dataIdx = 0; dataIdx < WorkItemListData.length; dataIdx++) {
        if (showAllWork || dataIdx < 4) {
            const item = WorkItemListData[dataIdx];
            workList.push(<a href={item.link} className="job-page__work-list-item" key={item.link} target="_blank">
                <img src={item.img} alt={item.link}/>
                <div className='job-page__work-list-text'>
                    {item.name.map((name, index) => (
                        <div key={index} className="job-page__work-list-item-name">
                            {name}
                            {index === item.name.length - 1 ? (<svg
                                width="15"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.33333 0C0.98052 0.00261677 0.6429 0.143933 0.393416 0.393416C0.143933 0.6429 0.00261677 0.98052 0 1.33333V10.6667C0.00261677 11.0195 0.143933 11.3571 0.393416 11.6066C0.6429 11.8561 0.98052 11.9974 1.33333 12H10.6667C11.0195 11.9974 11.3571 11.8561 11.6066 11.6066C11.8561 11.3571 11.9974 11.0195 12 10.6667V6H10.6667V10.6667H1.33333V1.33333H6V0H1.33333Z"
                                    fill="#C6CFD8"
                                />
                                <path
                                    d="M7.33331 0V1.33333H9.72442L3.52887 7.52889L4.47109 8.47111L10.6666 2.27556V4.66667H12V0H7.33331Z"
                                    fill="#C6CFD8"
                                />

                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="12" height="12" fill="#C6CFD8"/>
                                    </clipPath>
                                </defs>
                            </svg>) : null}
                        </div>
                    ))}
                </div>
            </a>)
        }
    }

    let imgStartRef = useRef(null);
    const onStartLinkMouseOver = (e) => {
        if (imgStartRef.current) {
            imgStartRef.current.src = "/images/pages/job-page/to_steps_next-active.png";
        }
    }
    const onStartLinkMouseLeave = (e) => {
        if (imgStartRef.current) {
            imgStartRef.current.src = "/images/pages/job-page/to_steps_next.png";
        }
    }

    return (
        <div className="wrapper wrapper--default">
            <Header/>
            <GoTopButton/>
            <div className="job__container">
                <JobHead/>
                <Link to="/employer" className="btn btn--primary btn--arrow">
                    <span>Информация для работодателей</span>
                    <svg width="41" height="16" viewBox="0 0 41 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M41.005 8.38197L33.795 16.001L32.009 14.472L37.191 8.99597H-0.000976562V7.00497H37.176L31.985 1.52497L33.773 -0.00402832L40.991 7.61497L40.55 7.99197L41.005 8.38197Z"
                                  fill="#778899"/>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="41" height="16" fill="#778899"/>
                            </clipPath>
                        </defs>
                    </svg>
                </Link>
                <div className="job-page__desc">
                    <div className="job-page__abstract">
                        <img className="job-page__desc-img"
                             src="/images/pages/job-page/vector.jpg"
                             alt="Выберите вектор развития"/>
                        <div className="job-page__desc-text">
                            <div className="job-page__desc-title">Найти работу по душе проще чем кажется</div>
                            <div className="job-page__desc-content">
                                Центр опережающей профессиональной подготовки Тюменской области
                                считает содействие трудоустройству выпускников
                                одним из приоритетных направлений деятельности.
                                Мы поможем вам с поиском работы, которая соответствует вашему запросу.
                            </div>
                            <Link to="/job/hh" className="job-page__desc-to-hh">
                                <Button className="btn--secondary">Найти работу</Button>
                            </Link>
                        </div>
                    </div>
                    <JobIndexVacancyList/>
                </div>


                <div className="job-page__section-title">
                    Мы знаем о лучших вакансиях в отрасли и рекомендуем наших ученикам
                </div>
                <DocItem
                    link="/doc/23"
                    title="Итоговый отчет по мониторингу и прогнозированию кадровых потребностей Тюменской области (без автономных округов) за 2017 год и на перспективу 2018 — 2023 годов"
                />
                <DocItem
                    link="/doc/48"
                    title="Прогнозная потребность в работниках по наиболее приоритетным и востребованным на рынке  труда Тюменской области на 2021-2024 года"
                />
                <div className="job-page__section-title">
                    Источники получения информации о рынке труда
                </div>


            </div>

            <div className="job-page__source-list-wrapper">
                <Scrollbars
                    autoHeight
                    autoHeightMin={300}
                    autoHeightMax={450}
                    renderTrackHorizontal={props => <div {...props}
                                                         className="job-page__source-list-track-horizontal"/>}
                    renderThumbHorizontal={props => <div {...props}
                                                         className="job-page__source-list-thumb-horizontal"/>}>
                    <div className="job-page__source-list">
                        {SourceItemListData.map((item) => (
                            <a href={item.link} className="job-page__source-list-item" key={item.link}
                               target="_blank">
                                <div className="job-page__source-list-item-img-wrapper ">
                                    <img className="job-page__source-list-item-img" src={item.img}
                                         alt={item.title}/>
                                </div>

                                <div className="job-page__source-list-item-link">{item.link}</div>
                            </a>
                        ))}
                    </div>
                </Scrollbars>
            </div>


            <div className="job__container">
                <div className="job-page__section-title">
                    Службы или Центры содействия трудоустройства выпускников государственных автономных образовательных
                    учреждений Тюменской области
                </div>

                <div className="job-page__work-list">
                    <div className="job-page__work-list-wrapper">
                        {workList}
                        {(!showAllWork) && (<div className="job-page__work-list-shadow"/>)}
                    </div>
                    <Button className="btn--primary" onClick={() => setShowAllWork(!showAllWork)}>
                        {showAllWork ? "Свернуть" : "Показать полностью"}
                    </Button>
                </div>

                <div className="job-page__section-title">
                    Полезные странички для выпускника по профориентации
                </div>
                <div className="job-page__useful-list">
                    {UsefulItemListData.map((item) => (
                        <a href={item.link} className="job-page__useful-list-item" key={item.link} target="_blank">
                            <img className="job-page__useful-list-item-img" src={item.img} alt={item.title}/>
                        </a>
                    ))}
                </div>
                <div className="job-page__tip-list">
                    {TipListData.map((item, idx) => (
                        <div className="job-page__tip-list-item" key={idx}>
                            <img src="/images/pages/job-page/tip-check.png" alt="Да!"
                                 className="job-page__tip-list-item-check"/>
                            <div className="job-page__tip-list-item-content">{item}</div>
                        </div>
                    ))}
                </div>


                <div className="job-page__to-steps-pre">
                    <div className="job-page__to-steps-pre-text">
                        Мы дадим вам советы по составлению резюме, прохождению собеседования и поможем получить работу
                        мечты.
                    </div>
                    <img src="/images/pages/job-page/to_steps_arrow.png" alt="Перейти"
                         className="job-page__to-steps-pre-arrow"/>
                </div>

                <Link to="/job/steps" className="job-page__to-steps"
                      onMouseOver={onStartLinkMouseOver}
                      onMouseLeave={onStartLinkMouseLeave}>
                    <div className="job-page__to-steps-icon">
                        <img src="/images/pages/job-page/to_steps.png" alt="Перейти"/>
                    </div>
                    <div className="job-page__to-steps-text">
                        <div className="job-page__to-steps-title">
                            5 шагов к достижению цели
                        </div>
                        <div className="job-page__to-steps-desc">
                            Предлагаем вам вместе пройти 5 шагов к трудоустройству
                        </div>
                    </div>
                    <img ref={imgStartRef} src="/images/pages/job-page/to_steps_next.png" alt="Перейти"
                         className="job-page__to-steps-next"/>
                </Link>

            </div>
            <Footer/>
        </div>
    );
};

