import React from "react";

import {useEmployerIndexData} from "../../services/Employer/EmployerIndex";
import EmployerLinkListSkeleton from "./EmployerLinkListSkeleton";
import EmployerLinkListReady from "./EmployerLinkListReady";


function EmployerLinkList() {
    const [isLoading, data] = useEmployerIndexData()
    let list = null;
    if (isLoading) {
        list = (<EmployerLinkListSkeleton/>);
    } else if (data && data.links && data.links.length > 0) {
        list = (<EmployerLinkListReady links={data.links}/>)
    } else {
        return null;
    }

    return (<div className="employer-link">
        {list}
    </div>)
}

export default EmployerLinkList;
