import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import fetch from "isomorphic-unfetch";
import { Button } from "../button";
import { cookie } from "../../api/functions";

const SpecialAnnouncement = () => {
  const history = useHistory();
  const [firstShow, setFirstShow] = useState(false);
  const [hideShow, setHideShow] = useState(false);
  const [secondShow, setSecondShow] = useState(false);
  const [special, setSpecial] = useState(null);
  const [link, setLink] = useState(null);

  useEffect(() => {
    fetch("/api/shared/special")
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then((json) => {
        setSpecial(json.special);
      });
  }, []);

  useEffect(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    if (!link && special) {
      setLink(special.link);
    }

    setTimeout(() => {
      if (!cookie.showAnnouncement && cookie.showAnnouncement !== link) {
        document.cookie = `showAnnouncement=${link}; expires=${date}`;
        setFirstShow(true);
      } else {
        setFirstShow(false);
        setHideShow(true);
      }
    }, 2000);
  }, [link, special]);

  const bannerClasses = cn({
    banner: true,
    "banner--first-show": firstShow,
    "banner--hide": hideShow,
    "banner--second-show": secondShow,
  });

  return (
    special && (
      <div className="banner__wrapper">
        <div className={bannerClasses}>
          <div className="banner__img">
            <img src={special && special.img} alt="" />
          </div>
          <div className="banner__content--wrapper">
            <h3>{special && special.title}</h3>
            <div className="banner__content">
              <div
                dangerouslySetInnerHTML={{
                  __html: special && special.text,
                }}
              />
              <div className="banner__btn">
                <Button
                  className="btn--secondary"
                  onClick={() => {
                    history.push(special.link);
                    setHideShow(true);
                    setFirstShow(false);
                    setSecondShow(false);
                  }}
                >
                  Подробнее
                </Button>
              </div>
            </div>
          </div>
          <span
            className="banner__close"
            onClick={() => {
              setHideShow(true);
              setFirstShow(false);
              setSecondShow(false);
            }}
          ></span>
        </div>
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          width="40px"
          viewBox="0 0 247.065 247.065"
          className={`${
            hideShow && !secondShow ? "speaker__show" : "speaker__hidden"
          }`}
          onClick={() => {
            setSecondShow(true);
            setHideShow(false);
          }}
        >
          <path
            d="M160.551,36.935c-0.614-1.892-1.956-3.462-3.727-4.365c-1.774-0.904-3.831-1.065-5.724-0.45l-31.376,10.196
		c-3.939,1.28-6.096,5.511-4.815,9.45l1.568,4.828l-79.811,58.625L5.183,125.448c-1.892,0.615-3.462,1.956-4.365,3.728
		c-0.903,1.772-1.065,3.831-0.45,5.723l19.129,58.862c1.03,3.169,3.97,5.184,7.131,5.184c0.769,0,1.55-0.119,2.32-0.369
		l31.478-10.229l16.173,0.085c3.248,15.336,16.888,26.88,33.176,26.88c16.164,0,29.714-11.371,33.095-26.531l16.587,0.087
		l1.568,4.829c0.614,1.892,1.955,3.462,3.728,4.365c1.064,0.542,2.232,0.817,3.405,0.817c0.78,0,1.563-0.122,2.317-0.367
		l31.377-10.195c3.939-1.28,6.096-5.511,4.816-9.45L160.551,36.935z M31.444,181.992l-14.492-44.597l18.364-5.967l14.49,44.597
		L31.444,181.992z M109.774,200.312c-7.912,0-14.694-4.887-17.513-11.797l34.958,0.184
		C124.356,195.514,117.617,200.312,109.774,200.312z M64.714,173.369l-7.888-24.277l-7.888-24.277l72.419-53.194l22.209,68.349
		l11.006,33.873L64.714,173.369z M172.972,181.929l-0.921-2.833c-0.001-0.005-0.002-0.011-0.004-0.017l-19.815-60.983l-20.74-63.833
		l17.111-5.561l41.48,127.665L172.972,181.929z"
          />
          <path
            d="M185.807,73.393c1.092,0.556,2.254,0.819,3.4,0.819c2.73,0,5.363-1.496,6.688-4.096l13.461-26.41
		c1.882-3.69,0.415-8.207-3.275-10.088c-3.69-1.88-8.207-0.415-10.088,3.276l-13.461,26.41
		C180.65,66.995,182.117,71.512,185.807,73.393z"
          />
          <path
            d="M242.176,144.712l-26.414-13.455c-3.691-1.879-8.207-0.412-10.087,3.279c-1.881,3.691-0.412,8.207,3.278,10.087
		l26.414,13.455c1.091,0.555,2.253,0.818,3.398,0.818c2.73,0,5.364-1.497,6.689-4.097
		C247.335,151.109,245.867,146.593,242.176,144.712z"
          />
          <path
            d="M204.242,101.204c1.03,3.169,3.97,5.184,7.131,5.184c0.769,0,1.55-0.119,2.32-0.369l28.188-9.16
		c3.938-1.28,6.095-5.511,4.814-9.451c-1.28-3.94-5.511-6.092-9.451-4.815l-28.188,9.16
		C205.118,93.034,202.962,97.265,204.242,101.204z"
          />
        </svg>
      </div>
    )
  );
};

export { SpecialAnnouncement };
