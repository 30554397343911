import React from "react";
import {ErrorPage} from "../../components/error-page";

export class PageErrorCatch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false
        }
    }

    static getDerivedStateFromError() {
        return {error: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log(errorInfo);
    }

    render() {
        if (this.state.error) {
            return (<ErrorPage
                title="Неизвестная ошибка"
                text="В ходе работы произошла неизвестная ошибка. <br /> Пожалуйста, подождите, она вскоре будет исправлена"
            />);
        }
        return this.props.children;
    }
}
