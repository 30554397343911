import React, {useState} from "react";
import QuestionItem from "../question-item";

export const QuestionList = ({questions, onFormChange}) => {
    const handleChangeAnswer = (questionId, answer) => {
        let newAnswer = {
            question: questionId,
            answer: answer
        };
        onFormChange(newAnswer);
    }
    return <div>
        {questions && questions.map((item, idx) =>
            <QuestionItem key={`quetion-item-${idx}`} question={item}
                          setAnswer={handleChangeAnswer}
            />)
        }
    </div>
}

export default QuestionList;