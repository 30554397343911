import React from 'react';
import {Header} from '../header';
import {Footer} from '../footer';
import {Link} from 'react-router-dom';

const ErrorPage = props => (
  <div className="wrapper wrapper--policy">
    <Header/>
    <div className="container">
      <div className="error-page">
        {props.code && <div className="error-page__code">{props.code}</div>}
        {props.title && <div className="error-page__title">{props.title}</div>}
        {props.text && <div className="error-page__text" dangerouslySetInnerHTML={{__html: props.text}}/>}

        <Link to="/" className="error-page__go-back">
          <span>Перейти на главную</span>
          <svg width="25" height="13" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 11.625L23 5.625L19 0.625M0 5.625H24" stroke="#D0101B" strokeWidth="2" strokeMiterlimit="10"/>
          </svg>
        </Link>
      </div>
    </div>
    <Footer/>
  </div>
);

export {ErrorPage};
