import * as React from "react";
import Select from 'react-select';
import {useJobHHDataFormActivity} from "../../services/Job";

const SkeletonItemStyle = {
    width: "100%",
    height: "40px"
}

function formatOptionLabel(x) {
    return x.name;
}

export function JobHHSearchPanelActivity() {
    const [isReady, list, id, setId] = useJobHHDataFormActivity();
    const handleChange = React.useCallback((x) => {
        if (x) {
            setId(x.id || null);
        } else {
            setId(null);
        }
    }, [setId]);


    if (isReady) {
        let selected = list.find(x => x.id === id) || null;

        return (<Select
            className="job-hh-search-panel-activity"
            classNamePrefix="job-hh-search-panel-activity"
            value={selected}
            onChange={handleChange}
            isClearable={true}
            formatOptionLabel={formatOptionLabel}
            placeholder="Направление"
            noOptionsMessage={() => 'Нет значений для выбора'}
            options={list}/>)
    }


    return (<div className="skeleton">
        <div className="skeleton-item" style={SkeletonItemStyle}/>
    </div>);
}
