import * as React from "react";
import {useJobHHDataFormText} from "../../services/Job";

const SkeletonItemStyle = {
    width: "100%",
    height: "40px"
}

export function JobHHSearchPanelText() {
    const [isReady, text, setText] = useJobHHDataFormText();
    const handleChange = React.useCallback((e) => {
        setText(e.target.value || '');
    }, [setText]);


    if (isReady) {
        return (<input
            className="job-hh-search-panel-text"
            type="text"
            placeholder="Профессия, должность или компания"
            value={text || ''}
            onChange={handleChange}
            onBlur={handleChange}/>)
    }


    return (<div className="skeleton">
        <div className="skeleton-item" style={SkeletonItemStyle}/>
    </div>);
}
