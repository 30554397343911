import React from 'react';
import {getFooterMenuItems, getPhones} from '../../api/api';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import {withSharedRemoteModelFn} from "../../hoc/remote-model";
import fetch from 'isomorphic-unfetch';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -150;
    window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
};

class Footer_ extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            menuItems: getFooterMenuItems()
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowSize);
    }

    updateWindowSize = () => {
        this.setState({
            width: window.innerWidth
        });
    };

    getLayout = () => {
        if (this.state.width >= 1024) {
            return this.getDesktopLayout();
        } else if (this.state.width >= 768) {
            return this.getTabletLayout();
        } else {
            return this.getMobileLayout();
        }
    };


    getDesktopLayout = () => (
        <footer className="footer">
            <div className="container container__footer">
                <div className="footer__row">
                    <div className="footer__left">
                        <div className="footer__logo">
                            <img src="./images/large-logo.png" alt=""/>
                        </div>

                        {this.socials()}

                        <div className="footer__copy">© 2019 - 2021 ЦОПП ТО</div>
                    </div>

                    <div className="footer__middle">
                        {this.menu()}
                        {this.faq()}
                    </div>

                    <div className="footer__right">
                        {this.addresses()}
                        {this.branch()}
                    </div>
                </div>
            </div>
        </footer>
    );

    getTabletLayout = () => (
        <footer className="footer">
            <div className="container">
                <div className="footer__row footer__row--tablet">
                    <div className="footer__left">
                        <div className="footer__logo">
                            <img src="./images/large-logo.png" alt=""/>
                        </div>

                        {this.socials()}

                        <div className="footer__copy">© 2019 - 2021 ЦОПП ТО</div>
                    </div>

                    <div className="footer__middle footer__middle--tablet">
                        {this.menu()}
                        {this.faq()}
                    </div>

                    <div className="footer__right">
                        {this.addresses()}
                        {this.branch()}
                    </div>
                </div>
            </div>
        </footer>
    );

    getMobileLayout = () => (
        <footer className="footer">
            <div className="container">
                <div className="footer__mobile-row">
                    <div className="footer__logo">
                        <img src="./images/large-logo.png" alt=""/>
                    </div>

                    {this.faq()}

                    {this.addresses()}

                    {this.branch()}

                    {this.socials()}

                    <div className="footer__copy">© 2019 - 2021 ЦОПП ТО</div>
                </div>
            </div>
        </footer>
    );

    socials = () => {
        let hasSocial = this.props.model && this.props.model.social && this.props.model.social.length > 0;
        if (hasSocial) {
            return (
                <div className="footer-socials">
                    {this.props.model.social.map((item, index) => (
                        <a href={item.link} key={index} className="footer-socials__item">
                            <img width="40" height="40" src={item.logo} alt=""/>
                        </a>
                    ))}
                </div>
            );
        }
    };

    menu = () => (
        <div className="footer-menu">
            {this.state.menuItems.map((item, index) => {
                if (item.link.startsWith("/#")) {
                    return (
                        <HashLink to={item.link}
                                  scroll={scrollWithOffset}
                                  className="footer-menu__item"
                                  key={index}>{item.anchor}</HashLink>
                    )
                } else {
                    return (
                        <HashLink to={item.link}
                                  className="footer-menu__item"
                                  key={index}>{item.anchor}</HashLink>
                    )
                }
            })}
        </div>
    );

    faq = () => (
        <div className="footer-faq">
            <Link to="/faq" className="footer-faq__link">Частые вопросы</Link>
            <Link to="/contact-us">
                <div className="btn btn--primary btn--arrow footer-faq__btn">
                    <span>Написать нам</span>

                    <svg width="41" height="16" viewBox="0 0 41 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M33.932 15.7442L40.7558 8.61746C40.9134 8.45278 41 8.2332 41 7.99398C41 7.75958 40.9134 7.53987 40.7558 7.37545L33.9384 0.255349C33.7806 0.0906663 33.5702 0 33.3458 0C33.1214 0 32.9109 0.0905362 32.7532 0.255349L32.2511 0.779704C32.0934 0.944386 32.0064 1.16422 32.0064 1.39863C32.0064 1.63303 32.0934 1.85274 32.2511 2.01755L37.976 7.99645L32.2445 13.982C32.0869 14.1467 32 14.3664 32 14.6009C32 14.8353 32.0869 15.0549 32.2445 15.2197L32.7468 15.7442C33.0735 16.0853 33.6052 16.0853 33.932 15.7442Z"
                            fill="#778899"/>
                        <line x1="40" y1="8" x2="-8.96083e-08" y2="8" stroke="#778899" strokeWidth="2"/>
                    </svg>
                </div>
            </Link>
            <a href="https://platform.copp72.ru/work/request-org/">
                <div className="btn btn--primary btn--arrow footer-faq__btn">
                    <span style={{"width": "135px"}}>Регистрация организаций</span>

                    <svg width="41" height="16" viewBox="0 0 41 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M33.932 15.7442L40.7558 8.61746C40.9134 8.45278 41 8.2332 41 7.99398C41 7.75958 40.9134 7.53987 40.7558 7.37545L33.9384 0.255349C33.7806 0.0906663 33.5702 0 33.3458 0C33.1214 0 32.9109 0.0905362 32.7532 0.255349L32.2511 0.779704C32.0934 0.944386 32.0064 1.16422 32.0064 1.39863C32.0064 1.63303 32.0934 1.85274 32.2511 2.01755L37.976 7.99645L32.2445 13.982C32.0869 14.1467 32 14.3664 32 14.6009C32 14.8353 32.0869 15.0549 32.2445 15.2197L32.7468 15.7442C33.0735 16.0853 33.6052 16.0853 33.932 15.7442Z"
                            fill="#778899"/>
                        <line x1="40" y1="8" x2="-8.96083e-08" y2="8" stroke="#778899" strokeWidth="2"/>
                    </svg>
                </div>
            </a>
            <div className="btn btn--primary footer-faq__btn footer-faq__btn-lw"
                 onClick={() => {
                     document.getElementById('root').className = 'low_vision';
                 }}>
                <span>Для слабовидящих</span>
            </div>
            <div className="btn btn--primary footer-faq__btn footer-faq__btn-default-ver"
                 onClick={() => {
                     document.getElementById('root').className = '';
                 }}>
                <span>Обычная версия сайта</span>
            </div>
        </div>
    );

    addresses = () => (
        <div className="footer-addresses">
            <div className="footer-addresses__title">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.95622 1.22628C8.1679 0.437956 7.1168 0 6.00001 0C4.88323 0 3.83213 0.437956 3.02191 1.22628C1.55476 2.69343 1.37958 5.45255 2.62775 7.13869L6.00001 12L9.35038 7.13869C10.6205 5.45255 10.4234 2.69343 8.95622 1.22628ZM6.04381 5.69343C5.18979 5.69343 4.51096 5.0146 4.51096 4.16058C4.51096 3.30657 5.18979 2.62774 6.04381 2.62774C6.89782 2.62774 7.57666 3.30657 7.57666 4.16058C7.57666 5.0146 6.87593 5.69343 6.04381 5.69343Z"
                        fill="#D0101B"/>
                </svg>

                <span>г. Тюмень</span>
            </div>
            <div className="footer-addresses__item">ул. Пермякова 3/1</div>
            {getPhones().map(phone => {
                const phoneHref = "tel:" + phone;
                return (<a href={phoneHref} className="footer-addresses__item">{phone}</a>)
            })}
            <a href="mailto:info@copp72.ru"
               className="footer-addresses__item footer-addresses__item--email">info@copp72.ru</a>
        </div>
    );

    branch = () => (<></>);

    branchR = () => (
        <HashLink to="/#contacts" className="footer__branch" scroll={scrollWithOffset}>
            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.95622 1.22628C7.1679 0.437956 6.1168 0 5.00001 0C3.88323 0 2.83213 0.437956 2.02191 1.22628C0.554758 2.69343 0.379576 5.45255 1.62775 7.13869L5.00001 12L8.35038 7.13869C9.62045 5.45255 9.42337 2.69343 7.95622 1.22628ZM5.04381 5.69343C4.18979 5.69343 3.51096 5.0146 3.51096 4.16058C3.51096 3.30657 4.18979 2.62774 5.04381 2.62774C5.89782 2.62774 6.57666 3.30657 6.57666 4.16058C6.57666 5.0146 5.87593 5.69343 5.04381 5.69343Z"
                    fill="#778899"/>
            </svg>

            <span>Филиалы в других городах</span>
        </HashLink>
    );

    render() {
        return this.getLayout();
    }
}

const Footer = withSharedRemoteModelFn(Footer_, props => fetch('/api/shared/footer')
    .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        }
    ));

export {Footer};
