import React from "react";

function EmployerContactListReadyItem({item}) {
    let phones = [];
    let emails = [];

    if (item.emails) {
        for (const email of item.emails) {
            if (email) {
                const mailto = "mailto:" + email;
                emails.push((<a href={mailto} className="employer-contact-list-item__email">
                    {email}
                </a>))
            }
        }
    }

    if (item.phones) {
        for (const phone of item.phones) {
            if (phone) {
                const tel = "tel:" + phone;
                phones.push((<a href={tel} className="employer-contact-list-item__phone">
                    {phone}
                </a>))
            }
        }
    }

    return (<div className="employer-contact-list-item">
        {item.post && (<div className="employer-contact-list-item__post">{item.post}</div>)}
        {item.name && (<div className="employer-contact-list-item__name">{item.name}</div>)}
        {phones}
        {emails}
    </div>)

}

export default EmployerContactListReadyItem;
