import {Link} from "react-router-dom";
import React, {useRef} from "react";

export function IndexStepNavItem({stepTitle, title, link, icon}) {

    return (
        <Link to={link} className="job-page-steps-index-step-nav-item" >
            <div className="job-page-steps-index-step-nav-item__text">
                <div className="job-page-steps-index-step-nav-item__step-title">{stepTitle}</div>
                <div className="job-page-steps-index-step-nav-item__title" title={title}>{title}</div>
            </div>
            <div className="job-page-steps-index-step-nav-item__img">
                <img  src={icon} alt={stepTitle}/>
                <div className="job-page-steps-index-step-nav-item__img-arrow"/>
            </div>
        </Link>
    );
};
