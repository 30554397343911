import React, { useState } from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { PageTitle } from "../../components/page-title";
import { GotQuestion } from "../../components/got-question";
import { TabMenu } from "../../components/tab-menu";
import { VideoModal } from "../../components/videoModal";
import {GoTopButton} from '../../components/goTopButton';
import {aboutTabMenuModel} from '../../api/api';

const VideoMaterials = ({ model }) => {
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoLink, setVideoLink] = useState(null);

  return (
    <div className="wrapper wrapper--default">
      <Header />
	  <GoTopButton />
      <div className="container container--about">
        <PageTitle>О центре</PageTitle>
        <TabMenu menuModel={aboutTabMenuModel} />
        <div className="video_list">
          {model &&
            model.video.map((item, index) => {
              return (
                <div
                  className="video_list-item"
                  key={index}
                  onClick={() =>
                    setVideoLink(item.link) || setOpenVideoModal(true)
                  }
                >
                  <div className="video_list-item-img">
                    <img
                      src={
                        item.img ? item.img : "/images/photo-pattern-large.jpg"
                      }
                      alt=""
                    />
                    <img src="/images/play.png" alt="" />
                  </div>
                  <div className="video_list-item-title">{item.title}</div>
                  <div className="video_list-item-source">
                    <img
                      src={
                        item.link.indexOf("youtube") !== -1
                          ? "/images/youtube-small.png"
                          : "/images/vk-small.png"
                      }
                      alt=""
                    />
                    <span>{`${
                      item.link.indexOf("youtube") !== -1
                        ? "Youtube"
                        : "Вконтакте"
                    } - ${
                      item.source ? item.source : "ЦОПП в Тюменской области"
                    }`}</span>
                  </div>
                </div>
              );
            })}
        </div>
        <VideoModal
          open={openVideoModal}
          link={videoLink}
          close={() => setOpenVideoModal(false)}
        />
      </div>
      <GotQuestion />
      <Footer />
    </div>
  );
};

export { VideoMaterials };
