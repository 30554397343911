import React from "react";
import {JobIndex} from "../components/JobIndex";
import {PageErrorCatch} from "../hoc/PageErrorCatch";
import {JobIndexData} from "../services/Job";

export function JobIndexPage() {
    return (<PageErrorCatch>
        <JobIndexData>
            <JobIndex/>
        </JobIndexData>
    </PageErrorCatch>)
}
