import React, { useMemo } from "react";
import { Link } from "react-router-dom";

const trimText = (text, length, end = "") => {
  if (!text) return;
  return text.length <= length ? text : `${text.substr(0, length)}${end}`;
};

const trimTextFromSpace = (text, length, end = "") => {
  if (!text) return;
  let index = text.indexOf(" ", length);
  if (index === -1) index = length;
  return text.length <= length ? text : `${text.slice(0, index)}${end}`;
};

const getSelectOption = item => ({
  value: item,
  label: item
});

const scrollToElement = ref => window.scrollTo(0, ref.current.offsetTop - 100);

const DoubleLink = ({ to, children, ...props }) => {
  const external = useMemo(() => {
    if (to) {
      return /^https?:\/\//.test(to);
    }
    return false;
  }, [to]);
  if (!to) return <span {...props}>{children}</span>;
  if (external)
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    );
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

const cookie = Object.fromEntries(document.cookie.split('; ').map(x => x.split('=')))

export { trimText, trimTextFromSpace, getSelectOption, scrollToElement, DoubleLink, cookie };
