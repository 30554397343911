import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import {Link} from "react-router-dom";

const NavSlideLayout = ({item, index}) => (
    <Link to={item.link}
          className="announce-list-item"
          key={index}
          target="_blank">
        <div className="announce-list-item__img">
            <img src={item.img} alt=""/>
        </div>

        <div className="announce-list-item-info">
            <div className="announce-list-item__name">
                {item.title}
            </div>

            <div className="announce-list-item-info__bottom">
                <div
                    className="styled-redactor"
                    dangerouslySetInnerHTML={{ __html: item.text }}
                />
            </div>
        </div>

        <div className="announce-list-item__date">
            <div className="announce-list-item__date-badge">
                {item.dateEvent || 'скоро'}
            </div>
        </div>
    </Link>
);

class AnnounceCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slides: this.props.slides,
            currentSlideIndex: 0,
            isMobile: window.innerWidth < 580,
            isPad: window.innerWidth < 767,
            isSmallScreen: window.innerWidth < 1023
        };
    }

    updateIsMobile = () => {
        this.setState({
            isMobile: window.innerWidth < 580,
            isPad: window.innerWidth < 767,
            isSmallScreen: window.innerWidth < 1023
        });
    }

    handleClickNext = () => {
        const {slides, currentSlideIndex} = this.state;
        if (currentSlideIndex + 1 < slides.length) {
            this.slider.slickNext();
        }
    };

    handleClickPrev = () => {
        const {currentSlideIndex} = this.state;
        if (currentSlideIndex > 0) {
            this.slider.slickPrev();
        }
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);

        this.setState({
            navSlider: this.navSlider
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    render() {
        const {slides} = this.state;

        let maxSlides = this.state.isMobile ? 1 : (this.state.isSmallScreen ? 2 : 3);

        const navSliderSettings = {
            slidesToShow: this.state.slides.length > maxSlides ? maxSlides : this.state.slides.length,
            vertical: false,
            focusOnSelect: true,
            asNavFor: null,
            swipeToSlide: true,
            adaptiveHeight: false,
            nextArrow: '',
            prevArrow: '',
            verticalSwiping: false,
            responsive: [
                {
                    breakpoint: 700,
                    settings: {
                        vertical: false,
                        verticalSwiping: false
                    }
                }
            ]
        }

        return (
            <div className="carousel__wrapper container">
                {
                    (this.state.slides.length === 1) ?
                        <div className="carousel__wrapper__bckgr-line" style={{maxWidth: '430px'}}/>
                            : (
                    (!this.state.isMobile && this.state.slides.length === 2) ?
                    <div className="carousel__wrapper__bckgr-line" style={{maxWidth: '830px'}}/> :
                    <div className="carousel__wrapper__bckgr-line"/>)
                }

                {((this.state.isMobile && this.state.slides.length > 1)
                    || (this.state.isSmallScreen && this.state.slides.length > 2)
                    || this.state.slides.length > 3) ?
                    <Slider
                        ref={slider => {
                            this.navSlider = slider;
                        }}
                        className="announce-list-nav-slider"
                        {...navSliderSettings}>
                        {slides.map((item, index) => <NavSlideLayout item={item} index={index}/>)}
                    </Slider> :
                    <div className="announce-list-nav-list">
                        {slides.map((item, index) =>
                            <div className="announce-list-nav-list__slide">
                                <NavSlideLayout item={item} index={index}/>
                            </div>
                        )}
                    </div>
                }
                </div>
        );
    }

}

export {AnnounceCarousel, NavSlideLayout};
