import React from 'react';
import {Header} from '../header';
import {Footer} from '../footer';
import {GoTopButton} from '../goTopButton';

import CofinanceLinkList from "./CofinanceLinkList";


function CofinanceRoot() {
    return (<div className="wrapper wrapper--default">
        <Header/>
        <GoTopButton/>
        <div className="cofinance">
            <h1 className="cofinance__head">
                Софинансирование
            </h1>
            <CofinanceLinkList/>
        </div>
        <Footer/>
    </div>);
}

export default CofinanceRoot;

