import * as React from "react";
import {JobHHSearchListAdvanced} from "./JobHHSearchListAdvanced";
import {JobHHSearchResultListSkeleton} from "./JobHHSearchResultListSkeleton";
import {useJobHHDataSearch} from "../../services/Job";
import {JobHHSearchResultListReady} from "./JobHHSearchResultListReady";
import {JobHHSearchListPage} from "./JobHHSearchListPage";
import {JobHHSearchListOrderBy} from "./JobHHSearchListOrderBy";
import {JobHHSearchListFound} from "./JobHHSearchListFound";

export function JobHHSearchList() {

    const [isLoading, data] = useJobHHDataSearch()

    let list = null;
    let paginator = null;
    if (isLoading) {
        list = (<JobHHSearchResultListSkeleton/>);
    } else if (data && data.vacancies && data.vacancies.length > 0) {
        list = (<JobHHSearchResultListReady vacancies={data.vacancies}/>)
        paginator = (<JobHHSearchListPage/>)
    }


    return (<div className="job-hh-search-list">
        <div className="job-hh-search-list__advanced-mobile">
            <JobHHSearchListAdvanced/>
        </div>
        <div className="job-hh-search-list__found">
            <JobHHSearchListFound />
        </div>
        <div className="job-hh-search-list__head">
            <div className="job-hh-search-list__sort">
                <JobHHSearchListOrderBy />
            </div>
            <div className="job-hh-search-list__advanced">
                <JobHHSearchListAdvanced/>
            </div>
        </div>
        <div className="job-hh-search-list__result_list">
            {list}
        </div>
        {paginator}
    </div>)
}
