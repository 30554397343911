import * as React  from "react";

const GoTopButton = () => {
  const [showTopButton, setShowTopButton] = React.useState(false);

  React.useEffect(() => {

      function scrollFunction() {
          if (window.pageYOffset > 500) {
              setShowTopButton(true);
          } else {
              setShowTopButton(false);
          }
      }

      window.addEventListener('scroll', scrollFunction);

      return () => {
          window.removeEventListener('scroll', scrollFunction);
      }

  }, [])



  return (
    showTopButton && (
      <div className="top__button" onClick={() => window.scrollTo(0, 0)}>
        <svg
          width="42"
          height="42"
          viewBox="0 0 42 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="42" height="42" rx="3" fill="#778899" />
          <path
            d="M31.6483 23.6878L21.849 13.8887C21.6226 13.6623 21.3207 13.538 20.9917 13.538C20.6694 13.538 20.3673 13.6623 20.1412 13.8887L10.3511 23.6785C10.1247 23.9051 10 24.2072 10 24.5295C10 24.8518 10.1245 25.1541 10.3511 25.3805L11.0721 26.1015C11.2985 26.328 11.6008 26.453 11.9231 26.453C12.2454 26.453 12.5475 26.328 12.7741 26.1015L20.9951 17.8805L29.2252 26.111C29.4517 26.3373 29.7538 26.4621 30.0763 26.4621C30.3986 26.4621 30.7005 26.3373 30.9271 26.111L31.6483 25.3897C32.1172 24.9205 32.1172 24.1569 31.6483 23.6878Z"
            fill="white"
          />
        </svg>
      </div>
    )
  );
};

export { GoTopButton };
