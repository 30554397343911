import * as React from "react";
import {JobHHSearchResultListReadyItem} from "./JobHHSearchResultListReadyItem";


export function JobHHSearchResultListReady({vacancies}) {
    if (!vacancies || vacancies.length === 0) {
        return null;
    }
    return (<div className="job-hh-search-result-list">
        {vacancies.map(vacancy => (<JobHHSearchResultListReadyItem vacancy={vacancy} key={vacancy.id}/>))}
    </div>);
}
