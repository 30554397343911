import * as React from "react";
import {useJobHHDataFormSalary} from "../../services/Job";
import InputMask from "inputmask";

const SkeletonItemStyle = {
    width: "100%",
    height: "40px"
}

const NumericMask = {
    'alias': 'decimal',
    'rightAlign': false,
    'radixPoint': ',',
    'groupSeparator': ' ',
    'groupSize': 3,
    "digits": "0",
    'digitsOptional': true,
    'autoGroup': true,
    "jitMasking": true,
    "allowMinus": false,
    "clearMaskOnLostFocus": true,
    "showMaskOnFocus": false,
    "showMaskOnHover": false,
};

export function JobHHSearchPanelSalary() {
    let inputRef = React.useRef();
    const [isReady, salary, setSalary] = useJobHHDataFormSalary();
    const handleChange = React.useCallback((e) => {
        setSalary(InputMask.unmask(e.target.value, NumericMask) || null);
    }, [setSalary]);


    React.useEffect(() => {
        let el = inputRef.current;
        if (el) {
            InputMask(NumericMask).mask(el)
        }
    }, [inputRef.current])

    if (isReady) {
        return (<input
            ref={inputRef}
            type="text"
            className="job-hh-search-panel-salary"
            placeholder="Зарплата"
            value={salary}
            onChange={handleChange}
            onBlur={handleChange}/>)
    }


    return (<div className="skeleton">
        <div className="skeleton-item" style={SkeletonItemStyle}/>
    </div>);
}
