import * as React from "react";
import {useJobHHDataSearchPage} from "../../services/Job";


export function JobHHSearchListPage() {
    const [page, pages, setPage] = useJobHHDataSearchPage();

    if (pages < 0) {
        return null;
    }

    const prevButtonProps = {};
    if (page > 0) {
        prevButtonProps.className = "job-hh-search-list-page__nav"
        prevButtonProps.onClick = () => {
            window.scrollTo(0, -200);
            setPage(page - 1);
        }
    } else {
        prevButtonProps.className = "job-hh-search-list-page__nav job-hh-search-list-page__nav--disabled"
        prevButtonProps["aria-disabled"] = true;
    }

    const currentPage = page + 1;

    const nextButtonProps = {};
    if (page < (pages - 1)) {
        nextButtonProps.className = "job-hh-search-list-page__nav"
        nextButtonProps.onClick = () => {
            window.scrollTo(0, -200);
            setPage(page + 1);
        }
    } else {
        nextButtonProps.className = "job-hh-search-list-page__nav job-hh-search-list-page__nav--disabled"
        nextButtonProps["aria-disabled"] = true;
    }

    return (<div className="job-hh-search-list-page">
        <button {...prevButtonProps}>
            <i className="job-hh-search-list-page__nav-icon job-hh-search-list-page__nav-icon-prev"/>
        </button>
        <div className="job-hh-search-list-page__current">{currentPage}</div>
        <button {...nextButtonProps}>
            <i className="job-hh-search-list-page__nav-icon job-hh-search-list-page__nav-icon-next"/>
        </button>
        <div className="job-hh-search-list-page__total">из {pages}</div>
    </div>)

}
