import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {PageTitle} from '../../components/page-title';
import {TabMenu} from '../../components/tab-menu';
import {GotQuestion} from '../../components/got-question';
import {GoTopButton} from '../../components/goTopButton';
import {aboutTabMenuModel} from '../../api/api';

const Staffs = props => {
    return (
        <div className="wrapper wrapper--default">
            <Header/>
            <div className="container container--about">
                <PageTitle>О центре</PageTitle>

                <TabMenu menuModel={aboutTabMenuModel} />

				<GoTopButton />

                <div className="block-margin"/>

                <div className="staffs">
                    {props.model && props.model.staffs && props.model.staffs.map((item, index) => (
                        <div key={`staffs_item_${index}`}>
                            <div className="staffs-item" >
                                <div className="staffs-item__img">
                                    <img src={item.photo || '/images/teacher-no-photo.png'} alt=""/>
                                </div>
                                <div className="staffs-item__info">
                                    <b>{item.name}</b>
                                    <p>{item.post}</p>
                                    {!!item.phone && item.phone.length > 0 && item.phone.map((item, index) => (
                                        <a href={'tel:' + item} className="underline-link" key={`phone_${index}`}>
                                            {item}
                                        </a>
                                    ))}

                                    {!!item.email && item.email.length > 0 && item.email.map((item, index) => (
                                        <a href={'mailto:' + item} className="underline-link" key={`email_${index}`}>
                                            {item}
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <hr className="staffs-separator" />
                        </div>
                    ))}
                </div>

                <div className="block-margin"/>
            </div>
            <GotQuestion/>
            <Footer/>
        </div>
    );
};

export {Staffs};