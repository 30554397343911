import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from '../button';
import SEO from '../seo';
import {scrollToElement} from '../../api/functions';
import classNames from "classnames";

class ProgramDetailBlock extends React.Component {
    constructor(props) {
        super(props);

        this.descriptionBlock = React.createRef();
    }

    render() {
        const data = this.props.initialState;
        let partnerClasses = {};
        if (data.partner) {
            partnerClasses = classNames({
                'program-detail-partner': true,
                'program-detail-partner--no-img': !data.partner.img
            });
        }
        return (
            <div className="container">
                <div className="program-detail">
                    <div className="program-detail__top">

                        {data.img && <div className="program-detail__img">
                            <img src={data.img} alt=""/>

                            <img src="/images/detail-page-img-bg.png" alt=""/>
                        </div>}

                        <div className="program-detail__info">
                            {data.competence && <div className="program-detail__competence">
                                Компетенция: {data.competence}
                            </div>}

                            {data.duration && <div className="program-detail__duration">{data.duration}<i>ч</i></div>}

                            {data.title && <h1 className="program-detail__title">{data.title}</h1>}

                            {data.subtitle && <div className="program-detail__subtitle">{data.subtitle}</div>}

                            {data.shortDescription && <div className="program-detail__short-description">
                                <span dangerouslySetInnerHTML={{__html: data.shortDescription}}/>
                            </div>}

                            {data.description && <div className="program-detail__detail-link"
                                                      onClick={() => scrollToElement(this.descriptionBlock)}>подробнее
                            </div>}

                            {data.price && <div className="program-detail__price">{data.price}<i>*</i></div>}

                            <div className="program-detail__order">
                                <Link to={"/schedule/" + data.id}>
                                    <Button className="btn--secondary program-detail__btn">
                                        Записаться
                                    </Button>
                                </Link>

                                {data.price &&
                                <div className="program-detail__wanna-free" onClick={this.props.handlePopupAdd}>
                                    <i>*</i>Хочу бесплатно
                                </div>}
                            </div>

                            {data.stop &&
                            <div className="stop-message"><img src="/images/important-small.png"/> Запись приостановлена
                            </div>}
                            <Link to='/programs' className="program-detail__return">
                                <svg width="17" height="12" viewBox="0 0 17 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M17.001 7.01401H2.98098L6.36898 10.853L5.11998 12.001L0.719981 7.01401H0.00598145V4.98201H0.663981L5.16298 -0.00299072L6.43998 1.14401L2.97598 4.98201H17.001V7.01401Z"
                                          fill="#778899"/>
                                </svg>

                                Вернуться в программы
                            </Link>
                        </div>
                    </div>

                    {data.description && <div className="program-detail-description">
                        <div className="block-title" ref={this.descriptionBlock}>Описание программы</div>

                        <div className="program-detail-description__text"
                             dangerouslySetInnerHTML={{__html: data.description}}/>
                    </div>}

                    {data.programFile && <div>
                        <a href={data.programFile} className="program-detail__program-file">
                            Скачать учебный план

                            <svg width="12" height="17" viewBox="0 0 12 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M7.01702 16.336V16.994H4.98502V16.28L-0.000976562 11.881L1.14602 10.631L4.98502 14.019V0H7.01702V14.024L10.856 10.56L12.003 11.837L7.01702 16.336Z"
                                      fill="#d0101b"/>
                            </svg>
                        </a>
                    </div>}

                    {data.programWorkFile && <div>
                        <a href={data.programWorkFile} className="program-detail__program-file">
                            Скачать рабочую программу

                            <svg width="12" height="17" viewBox="0 0 12 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M7.01702 16.336V16.994H4.98502V16.28L-0.000976562 11.881L1.14602 10.631L4.98502 14.019V0H7.01702V14.024L10.856 10.56L12.003 11.837L7.01702 16.336Z"
                                      fill="#d0101b"/>
                            </svg>
                        </a>
                    </div>}

                    {data.docFile && <div className="program-detail-doc">
                        <div className="program-detail-doc__img">
                            <img src="/images/doc.png" alt=""/>
                        </div>

                        <div className="program-detail-doc__text">
                            <div className="program-detail-doc__title">Образец</div>
                            <a href={data.docFile} className="program-detail-doc__link">
                                Документ о завершении обучения
                            </a>
                        </div>
                    </div>}

                    {data.partner && <div className={partnerClasses}>
                        <div className="program-detail-partner__top">
                            {!!data.partner.img && <>
                                <div className="program-detail-partner__img">
                                    <img src={data.partner.img} alt=""/>
                                </div>
                            </>}

                            <div className="program-detail-partner__title">{data.partner.title}</div>
                        </div>

                        <div className="program-detail-partner__body">
                            {!!data.partner.region && <div className="program-detail-partner__region">
                                {data.partner.region}
                            </div>}

                            {!!data.partner.text && <>
                                <div className="styled-redactor program-detail-partner__text"
                                     dangerouslySetInnerHTML={{__html: data.partner.text}}/>
                            </>}

                            {!!data.partner.phone && data.partner.phone.length > 0 && data.partner.phone.map((item, index) => (
                                <a href={'tel:' + item} className="program-detail-partner__phone"
                                   key={`accordion_phone_${index}`}>
                                    {item}
                                </a>
                            ))}

                            {!!data.partner.email && data.partner.email.length > 0 && data.partner.email.map((item, index) => (
                                <a href={'mailto:' + item} className="program-detail-partner__email"
                                   key={`accordion_email_${index}`}>
                                    {item}
                                </a>
                            ))}

                            {!!data.partner.site && <>
                                <a href={data.partner.site} className="program-detail-partner__site">
                                    {data.partner.site}
                                    <svg
                                        width="15"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="accordion-item__site-svg"
                                    >
                                        <path
                                            d="M1.33333 0C0.98052 0.00261677 0.6429 0.143933 0.393416 0.393416C0.143933 0.6429 0.00261677 0.98052 0 1.33333V10.6667C0.00261677 11.0195 0.143933 11.3571 0.393416 11.6066C0.6429 11.8561 0.98052 11.9974 1.33333 12H10.6667C11.0195 11.9974 11.3571 11.8561 11.6066 11.6066C11.8561 11.3571 11.9974 11.0195 12 10.6667V6H10.6667V10.6667H1.33333V1.33333H6V0H1.33333Z"
                                            fill="#C6CFD8"
                                        />
                                        <path
                                            d="M7.33331 0V1.33333H9.72442L3.52887 7.52889L4.47109 8.47111L10.6666 2.27556V4.66667H12V0H7.33331Z"
                                            fill="#C6CFD8"
                                        />

                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="12" height="12" fill="#C6CFD8"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </>}
                        </div>
                    </div>}
                </div>

                <SEO pageProps={{
                    description: "ЦОПП Тюменской области",
                    title: data.title ? `Программа обучения: ${data.title}` : "Центр опережающей профессиональной подготовки",
                    thumbnail: "https://copp72.ru/images/welcome-screen-2.jpg",
                    url: `https://copp72.ru/program/${data.id}`
                }}
                />
            </div>
        )
    }
}

export {ProgramDetailBlock}
