import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {GoTopButton} from '../../components/goTopButton';
import {StepControl} from '../../components/step-control';
import {DocItem} from '../../components/doc-item';
import {JobPageHead} from "../../components/job-page/head";

export const CarierStart = () => {
    return (
        <div className="wrapper wrapper--default">
            <Header/>
            <GoTopButton/>
            <div className="job__container">
                <JobPageHead/>
                <div className="job__step">Шаг 5</div>
                <div className="job__desc">
                    <h2>Начало карьеры</h2>
                    <p>
                        Если Вы воспользовались нашими рекомендациями, успешно прошли четыре шага, нашли место работы и
                        готовы
                        приступить к трудовой деятельности, примите наши поздравления!
                    </p>
                    <p>Однако и здесь мы готовы поддержать Вас и предлагаем ещё несколько советов.</p>
                    <ol>
                        <li>
                            <p>Собираясь выходить на работу, обязательно согласуйте с работодателем то, как и когда вас
                                представят
                                коллегам по подразделению и другим сотрудникам компании, с которыми Вы должны будете
                                часто
                                иметь дело по
                                работе.
                            </p>
                            <p>
                                Постарайтесь запомнить (или запишите) имена и отчества всех, с кем Вас будут знакомить.
                                Хорошо, если в
                                первый день в Вашем распоряжении окажется много деловых бумаг, в которых следует
                                разобраться. Вы сразу
                                окажетесь при деле. Но важно поначалу не стараться понять все детали, а быстро провести
                                обзор,
                                экспресс-обучение, чтобы на этой основе перейти к конкретной работе.
                            </p>
                        </li>
                        <li>
                            <p>
                                То, чем вам можно и нужно будет заниматься по работе в первые дни, зависит, прежде
                                всего, от
                                порядков,
                                заведённых в компании, и от того, на какую должность Вы претендуете.
                            </p>
                            <p>
                                Выходя на новую работу, очень важно разобраться, кто, в какой мере и по каким критериям
                                будет оценивать
                                результаты Вашей деятельности. Это на самом деле не такой простой вопрос, как может
                                показаться на первый
                                взгляд. Разобравшись в том, кто будет оценивать Вашу работу, не забывайте об этих людях
                                и
                                помогайте им
                                увидеть результаты.
                            </p>
                        </li>
                        <li>
                            В каждом коллективе существуют сложившиеся групповые нормы поведения, своеобразные
                            поведенческие
                            стандарты. Это может касаться манеры одеваться и говорить, времени прихода и ухода,
                            перекуров, обеда,
                            «прописки», содержания рабочего места и т.д. Старайтесь понять эти групповые нормы поведения
                            и вписаться в
                            них.
                        </li>
                        <li>
                            Еще одним важным моментом является Ваша доброжелательность. Разговаривайте приветливо, чаще
                            улыбайтесь,
                            при случае не стесняйтесь предложить свою помощь. Избегайте резких слов и интонаций. Не
                            торопитесь
                            критиковать порядки в новой организации. И уж тем более не сравнивайте их с чем-то из Вашего
                            прежнего
                            опыта, если это сравнение не в пользу нового места.
                        </li>
                        <li>
                            Занимайтесь самообразованием в области практической психологии. Если Вы научитесь лучше
                            разбираться в
                            людях и правильнее себя вести, то Вам будет легче вписаться в любой коллектив. Читайте и
                            изучайте, когда
                            представится возможность (а ее нужно искать), поучаствуйте в тренинге по психологии общения.
                            И, наконец,
                            задумайтесь о том, что и как Вы делаете каждый день, как строятся Ваши отношения с
                            коллективом. И
                            находите способы совершенствовать себя и свои отношения с людьми.
                        </li>
                    </ol>
                    <h4>Воспользуйтесь также данной информацией</h4>
                    <DocItem link="/doc/32" title="Порядок оформления документов при приёме на работу"/>
                </div>
                <StepControl prev="/job/interview" stepTitlePrev="Шаг 4" titlePrev="Собеседование"/>
            </div>
            <Footer/>
        </div>
    );
};
