import React from "react";
import { NavSlideLayout } from "../announce-carousel";

class AnnounceList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {announces} = this.props;

        return(
            <div className="announce-list__wrapper">
                <div className="announce-list">
                    {announces.map((item, index) => <NavSlideLayout item={item} index={index}/>
                    )}
                </div>
            </div>
        );
    }
}

export {AnnounceList};