import React from "react";
import { SpecialAnnouncement } from "../../components/special-announcement";

const PageWrapper = (props) => (
  <>
    <SpecialAnnouncement />
    {props.children}
  </>
);

export { PageWrapper };
