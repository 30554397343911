import React from "react";
import {
    useParams
} from "react-router-dom";
import {PageErrorCatch} from "../hoc/PageErrorCatch";
import {JobHHItemData} from "../services/Job";
import {JobHHItem} from "../components/JobHHItem/JobHHItem";

export function JobHHItemPage() {
    let {id} = useParams();

    return (<PageErrorCatch>
        <JobHHItemData id={id}>
            <JobHHItem/>
        </JobHHItemData>
    </PageErrorCatch>)
}
