import React, {useRef, useState} from "react";
import {DoubleLink} from "../../api/functions";

function EmployerLinkListReadyItem({item}) {
    let [brokenHov, setBrokenHov] = useState(false)
    let imgRef = useRef();
    let content = [];
    if (item.image) {
        let alt = item.caption || 'Информация для работодателя';

        if (item.imageHover && !brokenHov) {
            let onError = e => {
                if (e.target.src === item.imageHover) {
                    setBrokenHov(true);
                    e.target.src = item.image;
                }
            };
            content.push((<img className="employer-link-list-item__image"
                               ref={imgRef}
                               onError={onError} src={item.image} alt={alt}/>));
        } else {
            content.push((<img className="employer-link-list-item__image" src={item.image} alt={alt}/>));
        }

    }
    if (item.caption) {
        content.push((<div className="employer-link-list-item__caption">{item.caption}</div>));
    }

    let onMouseEnter;
    let onMouseLeave;
    if (item.imageHover && !brokenHov) {
        onMouseEnter = e => {
            if (imgRef.current) {
                imgRef.current.src = item.imageHover;
            }
        };
        onMouseLeave = e => {
            if (imgRef.current) {
                imgRef.current.src = item.image;
            }
        };
    }

    return (<DoubleLink to={item.link || '#'}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        className="employer-link-list-item">
        {content}
    </DoubleLink>);
}

export default EmployerLinkListReadyItem;
