import React from 'react';
import {Header} from '../header';
import {Footer} from '../footer';
import {GoTopButton} from '../goTopButton';

import EmployerContactBlock from "./EmployerContactBlock";
import EmployerLinkList from "./EmployerLinkList";


function EmployerRoot() {
    return (<div className="wrapper wrapper--default">
        <Header/>
        <GoTopButton/>
        <div className="employer">
            <h1 className="employer__head">
                Работодателям
            </h1>
            <EmployerLinkList/>
            <EmployerContactBlock/>
        </div>
        <Footer/>
    </div>);
}

export default EmployerRoot;

