import React from "react";


const HeadItemStyle = {
    width: "70%",
    height: "32px",
}

const BodyItemStyle1 = {
    width: "60%",
    height: "12px",
    marginTop: "10px"
}
const BodyItemStyle2 = {
    width: "35%",
    height: "12px",
    marginTop: "10px"
}

const BodyItemStyle3 = {
    width: "50px",
    height: "12px",
    marginTop: "10px"
}

export function JobIndexVacancyListSkeletonItem() {
    return (<div className="skeleton">
        <div className="skeleton-item" style={HeadItemStyle} />
        <div className="skeleton-item" style={BodyItemStyle1} />
        <div className="skeleton-item" style={BodyItemStyle2} />
        <div className="skeleton-item" style={BodyItemStyle1} />
        <div className="skeleton-item" style={BodyItemStyle3} />
    </div>)
}
