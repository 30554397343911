import * as React from "react";
import {parseISO, format} from 'date-fns'
import {ru} from 'date-fns/locale'
import {Link} from "react-router-dom";

export function JobIndexVacancyListReadyItem({vacancy}) {
    if (!vacancy || !vacancy.id) {
        return null;
    }

    let content = [];
    if (vacancy.name) {
        content.push((<div key="name" className="job-index-vacancy-list-item__name">
            {vacancy.name}
        </div>));
    }
    let salaryFrom = null;
    let salaryTo = null;
    if (vacancy.salaryFrom) {
        salaryFrom = Number(vacancy.salaryFrom).toLocaleString("ru-RU");
    }
    if (vacancy.salaryTo) {
        salaryTo = Number(vacancy.salaryTo).toLocaleString("ru-RU");
    }
    if (salaryFrom || salaryTo) {
        let salary = '';
        if (salaryFrom) {
            salary = salary + ' от ' + salaryFrom;
        }
        if (salaryTo) {
            salary = salary + ' до ' + salaryTo;
        }
        if (vacancy.currency) {
            salary = salary + ' ' + vacancy.currency;
        }
        content.push((<div key="salary" className="job-index-vacancy-list-item__salary">
            {salary}
        </div>));
    }
    if (vacancy.employerName || vacancy.areaName) {
        let descContent = [];
        if (vacancy.employerName) {
            descContent.push((<div key="employer" className="job-index-vacancy-list-item__employer">
                {vacancy.employerName}
            </div>));
        }
        if (vacancy.areaName) {
            descContent.push((<div key="area" className="job-index-vacancy-list-item__area">
                {vacancy.areaName}
            </div>));
        }
        content.push((<div key="desc" className="job-index-vacancy-list-item__desc">{descContent}</div>))
    }
    content.push((<div key="space" className="job-index-vacancy-list-item__space"/>));
    if (vacancy.publishedAt) {
        let parsed = parseISO(vacancy.publishedAt);
        let formatted = format(parsed, 'd MMMM', {locale: ru});
        content.push((<div key="date" className="job-index-vacancy-list-item__date">
            {formatted}
        </div>));
    }

    let link = "/job/hh/" + vacancy.id;
    return (<Link to={link} className="job-index-vacancy-list-item">
        {content}
    </Link>);
}
