import React, { useRef } from "react";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { GotQuestion } from "../../components/got-question";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Carousel } from "../../components/carousel";

const NewsDetail = (props) => {
  const slider = useRef(null);

  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PrevArrow />
        <ul> {dots} </ul>
        <NextArrow />
      </div>
    ),
  };

  const PrevArrow = () => {
    return (
      <div
        onClick={() => slider.current.slickPrev()}
        className="carousel__arrow"
      >
        <svg width="41" height="16" viewBox="0 0 41 16" fill="none">
          <path
            d="M-0.000976562 8.38197L7.20903 16.001L8.99503 14.472L3.81302 8.99597H41.005V7.00497H3.82803L9.01902 1.52497L7.23103 -0.00402832L0.0130234 7.61497L0.454025 7.99197L-0.000976562 8.38197Z"
            fill="#d0101b"
          />
        </svg>
      </div>
    );
  };

  const NextArrow = () => {
    return (
      <div
        onClick={() => slider.current.slickNext()}
        className="carousel__arrow"
      >
        <svg width="41" height="16" viewBox="0 0 41 16" fill="none">
          <path
            d="M41.005 8.38197L33.795 16.001L32.009 14.472L37.191 8.99597H-0.000976562V7.00497H37.176L31.985 1.52497L33.773 -0.00402832L40.991 7.61497L40.55 7.99197L41.005 8.38197Z"
            fill="#d0101b"
          />
        </svg>
      </div>
    );
  };

  let hasPhotos =
    props.model && props.model.photos && props.model.photos.length > 0;
  return (
    <div className="wrapper wrapper--detail-page news_detail">
      <Header />

      <div className="news_detail__wrapper">
        <div className="container news_detail__container">
          {props.model && (
            <>
              {props.model.title && (
                <h1 className="news_detail__title">{props.model.title}</h1>
              )}
              {props.model.date && (
                <p className="news_detail__date">{props.model.date}</p>
              )}

              {props.model.img && (
                <div className="news_detail__img">
                  <img src={props.model.img} />
                </div>
              )}

              {props.model.text && (
                <div
                  className="news_detail__text"
                  dangerouslySetInnerHTML={{ __html: props.model.text }}
                />
              )}
            </>
          )}

          {hasPhotos && (
            <>
              <div className="block-margin" />
              <Slider {...sliderSettings} ref={slider}>
                {props.model.photos.map((item, index) => (
                  <div className="news_detail__photo" key={index}>
                    <img src={item.img} alt={item.title} />
                  </div>
                ))}
              </Slider>
            </>
          )}

          <div className="block-margin" />

          <Link to="/news" className="news_detail__go-back">
            <svg
              width="18"
              height="16"
              viewBox="0 0 41 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-0.000976562 8.38197L7.20903 16.001L8.99503 14.472L3.81302 8.99597H41.005V7.00497H3.82803L9.01902 1.52497L7.23103 -0.00402832L0.0130234 7.61497L0.454025 7.99197L-0.000976562 8.38197Z"
                fill="#d0101b"
              />
            </svg>
            <span>Все новости</span>
          </Link>
        </div>
      </div>

      <div className="block-margin" />

      <Footer />
    </div>
  );
};

export { NewsDetail };
