import React from 'react';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { GoTopButton } from '../../components/goTopButton';
import {GotQuestion} from "../../components/got-question";
import {FeedbackList} from "../../components/feedback-list";

const FeedbackPage = props => {
    return (
        <div className="wrapper">
            <Header/>
            <GoTopButton/>

            {props.model && props.model.items &&
                <FeedbackList feedbacks={props.model.items}
                              years={props.model.years}
                              maxYear={props.model.maxYear}
                              activities={props.model.activities}/>
            }

            <div className="block-margin"/>
            <GotQuestion/>
            <Footer/>
        </div>
    );
};

export {FeedbackPage};