import React from "react";


export function JobHead() {
    return (<div className="job-page-head">
        <div className="job-page-head__text">
            <h1 className="job-page-head__title">Содействие трудоустройству</h1>
            <div className="job-page-head__sub-title">
                Студентам помогаем найти работу, а компаниям — специалистов:
                маркетологов, программистов, дизайнеров, аналитиков, менеджеров.
            </div>
        </div>
        <img className="job-page-head__img" src="/images/components/job-page/head/logo.png" alt="Содействие трудоустройству" />
    </div>);
}
