import React from "react";
import EmployerLinkListSkeletonItem from "./EmployerLinkListSkeletonItem";

function EmployerLinkListSkeleton() {
    return (<div className="employer-link-list">
        <EmployerLinkListSkeletonItem key={1}/>
        <EmployerLinkListSkeletonItem key={2}/>
    </div>)
}

export default EmployerLinkListSkeleton;
