import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {GoTopButton} from '../../components/goTopButton';
import {StepControl} from '../../components/step-control';
import {DocItem} from '../../components/doc-item';
import {JobPageHead} from "../../components/job-page/head";

export const Resume = () => {
    return (
        <div className="wrapper wrapper--default">
            <Header/>
            <GoTopButton/>
            <div className="job__container">
                <JobPageHead/>
                <div className="job__step">Шаг 2</div>
                <div className="job__desc">
                    <h2>Составление резюме</h2>
                    <p>
                        Один из первых шагов в трудоустройстве – грамотно написанное резюме. Резюме – это Ваша визитная
                        карточка,
                        Ваш портрет, по которому работодатель (или кадровое агентство) определяет пригласить Вас на
                        собеседование
                        или нет. И составить его нужно так, чтобы они захотели пригласить!
                    </p>
                    <h4>Мы суммировали для Вас полезную информацию. Воспользуйтесь ею, сделайте второй шаг!</h4>
                    <DocItem link="/doc/27" title="Рекомендации по составлению резюме"/>
                    <DocItem link="/doc/28" title="Качества для успешного выполнения профессиональной деятельности"/>
                    <DocItem link="/doc/29" title="Бланк резюме (вариант 1)"/>
                    <DocItem link="/doc/30" title="Бланк резюме (вариант 2)"/>
                </div>
                <StepControl
                    prev="/job/job-search"
                    stepTitlePrev="Шаг 1"
                    titlePrev="Поиск работы"
                    stepTitleNext="Шаг 3"
                    titleNext="Самопрезентация"
                    next="/job/self-presentation"
                />
            </div>

            <Footer/>
        </div>
    );
};
