import * as React from "react";
import fetch from "isomorphic-unfetch";
import {ErrorPage} from "../../components/error-page";
import {
    withRouter
} from "react-router-dom";
import qs from "query-string"

const JobHHDataContextInitial = {
    data: null,
    isLoading: true,
    isError: false
}

const JobHHDataSearchContextInitial = {
    data: null,
    isLoading: true,
    isError: false
}

const JobHHDataFormContextInitial = {
    text: '',
    region: null,
    salary: null,
    activity: null,
    employment: null,
    onlyWithSalary: false,
    page: 0,
    orderBy: null
}

/**
 * Page scope data
 */
const JobHHDataContext = React.createContext(JobHHDataContextInitial);

/**
 *  Search result
 */
const JobHHDataSearchContext = React.createContext(JobHHDataSearchContextInitial);

/**
 *  Search form
 */
const JobHHDataFormContext = React.createContext(JobHHDataFormContextInitial);

/**
 *  Page scope methods
 */
const JobHHDataMethodsContext = React.createContext(null);


function formReducer(state, action) {
    if (action.type === 'initial') {
        let state = action.state || {}
        return {
            text: state.text || '',
            region: state.region || null,
            salary: state.salary || null,
            activity: state.activity || null,
            employment: state.employment || null,
            onlyWithSalary: state.onlyWithSalary || false,
            page: state.page || 0,
            orderBy: state.orderBy || null
        };
    } else if (action.type === 'set') {
        return {
            ...state,
            [action.field]: action.value
        }
    } else {
        return state;
    }
}


class JobHHDataImpl extends React.Component {
    _isMounted = false;
    _searchId = 0;

    constructor(props) {
        super(props);
        this.state = {
            searchVersion: 0,
            data: {
                ...JobHHDataContextInitial
            },
            search: {
                ...JobHHDataSearchContextInitial
            },
            form: {
                ...JobHHDataFormContextInitial
            },
            methods: {
                search: this.searchMethod,
                reset: this.resetMethod,
                setFormField: this.setFormFieldMethod,
                setOrderBy: this.setOrderByMethod,
                setPage: this.setPageMethod
            }
        }
    }


    searchMethod = async () => {
        if (!this._isMounted) {
            return;
        }
        await this.setStateAsync((prev) => ({
            form: {
                ...prev.form,
                page: 0
            }
        }));
        await this.search();
    }

    resetMethod = async () => {
        if (!this._isMounted) {
            return;
        }
        await this.reset();
        await this.search();
    }

    setOrderByMethod = async (value) => {
        if (!this._isMounted) {
            return;
        }
        await this.setStateAsync((prev) => ({
            form: {
                ...prev.form,
                orderBy: value,
                page: 0
            }
        }));
        await this.search();
    }

    setPageMethod = async (page) => {
        if (!this._isMounted) {
            return;
        }
        await this.setStateAsync((prev) => ({
            form: {
                ...prev.form,
                page
            }
        }));
        await this.search();
    }

    setFormFieldMethod = (field, value) => {
        if (!this._isMounted) {
            return;
        }
        this.setState((prev) => ({
            form: {
                ...prev.form,
                [field]: value
            }
        }))
    }

    async componentDidMount() {
        this._isMounted = true;
        await this.loadData();
        await this.search();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    setStateAsync(state) {
        return new Promise((resolve) => {
            if (this._isMounted) {
                this.setState(state, () => resolve());
            } else {
                resolve();
            }
        });
    }

    async setDataLoadError() {
        await this.setStateAsync({
            data: {
                data: null,
                isLoading: false,
                isError: true
            }
        })
    }

    async setDataLoadResult(result) {
        await this.setStateAsync({
            data: {
                data: result,
                isLoading: false,
                isError: false
            }
        })
    }

    async setFormDataAsync(form) {
        await this.setStateAsync({
            form: form
        })
    }

    async loadData() {
        if (!this._isMounted) {
            return;
        }
        const searchStr = this.props.location.search || '';
        try {
            const resp = await fetch("/api/job/hh")
            if (!resp.ok) {
                await this.setDataLoadError();
                return;
            }
            const data = await resp.json();

            const parsed = qs.parse(searchStr, {
                parseNumbers: true,
                parseBooleans: true
            });
            const form = {};

            form.region = Number(parsed.region || data.defaultRegion || JobHHDataFormContextInitial.region);
            form.orderBy = parsed.orderBy || data.defaultOrderBy || JobHHDataFormContextInitial.orderBy;

            form.text = parsed.text || JobHHDataFormContextInitial.text;
            form.salary = parsed.salary || JobHHDataFormContextInitial.salary;
            form.activity = Number(parsed.activity || JobHHDataFormContextInitial.activity);
            form.employment = Number(parsed.employment || JobHHDataFormContextInitial.employment);
            form.onlyWithSalary = Boolean(parsed.onlyWithSalary || JobHHDataFormContextInitial.onlyWithSalary);
            form.page = Number(parsed.page || JobHHDataFormContextInitial.page);

            await this.setDataLoadResult(data);
            await this.setFormDataAsync(form);

        } catch (e) {
            await this.setDataLoadError();
        }
    }

    async setSearchLoadStart() {
        await this.setStateAsync({
            search: {
                data: null,
                isLoading: true,
                isError: false
            }
        })
    }

    async setSearchLoadError() {
        await this.setStateAsync({
            search: {
                data: null,
                isLoading: false,
                isError: true
            }
        })
    }

    async setSearchLoadResult(result) {
        await this.setStateAsync({
            search: {
                data: result,
                isLoading: false,
                isError: false
            }
        })
    }

    async search() {
        if (!this._isMounted) {
            return;
        }
        const query = {}
        if (this.state.form.text) {
            query.text = this.state.form.text;
        }
        if (this.state.form.region) {
            query.region = this.state.form.region;
        }
        if (this.state.form.salary) {
            query.salary = this.state.form.salary;
        }
        if (this.state.form.activity) {
            query.activity = this.state.form.activity;
        }
        if (this.state.form.employment) {
            query.employment = this.state.form.employment;
        }
        if (this.state.form.onlyWithSalary) {
            query.onlyWithSalary = this.state.form.onlyWithSalary;
        }
        if (this.state.form.page) {
            query.page = this.state.form.page;
        }
        if (this.state.form.orderBy) {
            query.orderBy = this.state.form.orderBy;
        }
        const finalQuery = qs.stringify(query) || '';
        this.props.history.replace('/job/hh?' + finalQuery);
        let _searchId = ++this._searchId;
        try {
            await this.setSearchLoadStart();
            const resp = await fetch("/api/job/hh/search?" + finalQuery)
            if (!resp.ok) {
                if (_searchId === this._searchId) {
                    await this.setSearchLoadError();
                }
                return;
            }
            let data = await resp.json();
            if (_searchId === this._searchId) {
                await this.setSearchLoadResult(data);
            }
        } catch (e) {
            if (_searchId === this._searchId) {
                await this.setSearchLoadError();
            }
        }
    }

    async reset() {
        if (!this._isMounted) {
            return;
        }
        const form = {};
        if (this.state.date && this.state.data.data) {
            form.region = Number(this.state.data.defaultRegion || JobHHDataFormContextInitial.region);
            form.orderBy = this.state.data.data.defaultOrderBy || JobHHDataFormContextInitial.orderBy;
        } else {
            form.region = Number(JobHHDataFormContextInitial.region);
            form.orderBy = JobHHDataFormContextInitial.orderBy;
        }
        form.text = JobHHDataFormContextInitial.text;
        form.salary = JobHHDataFormContextInitial.salary;
        form.activity = Number(JobHHDataFormContextInitial.activity);
        form.employment = Number(JobHHDataFormContextInitial.employment);
        form.onlyWithSalary = Boolean(JobHHDataFormContextInitial.onlyWithSalary);
        form.page = Number(JobHHDataFormContextInitial.page);

        await this.setFormDataAsync(form);
    }


    render() {
        let content = this.props.children;
        if (this.state.data.isError || this.state.search.isError) {
            content = (<ErrorPage
                code="500"
                title="Ошибка сервера"
                text="На сервере произошла непредвиденная ошибка <br /> Пожалуйста, подождите, она вскоре будет исправлена"
            />)
        } else if ((!this.state.data.isLoading && !this.state.data.data) || (!this.state.search.isLoading && !this.state.search.data)) {
            content = (<ErrorPage
                code="500"
                title="Ошибка сервера"
                text="Не удалось получить данные от сервера"
            />)
        }


        return (<JobHHDataContext.Provider value={this.state.data}>
            <JobHHDataSearchContext.Provider value={this.state.search}>
                <JobHHDataFormContext.Provider value={this.state.form}>
                    <JobHHDataMethodsContext.Provider value={this.state.methods}>
                        {content}
                    </JobHHDataMethodsContext.Provider>
                </JobHHDataFormContext.Provider>
            </JobHHDataSearchContext.Provider>
        </JobHHDataContext.Provider>)

    }

}


export const JobHHData = withRouter(JobHHDataImpl);


export function useJobHHDataIsReady() {
    const data = React.useContext(JobHHDataContext);
    return data && !data.isLoading;
}

export function useJobHHDataFormText() {
    const data = React.useContext(JobHHDataContext);
    const form = React.useContext(JobHHDataFormContext);
    const methods = React.useContext(JobHHDataMethodsContext);


    const setValue = React.useCallback((newValue) => {
        if (methods && methods.setFormField) {
            methods.setFormField('text', newValue);
        }
    }, [methods])


    let isReady = false;
    let value = null;

    if (data) {
        isReady = !data.isLoading;
    }
    if (form) {
        value = form.text || '';
    }
    return [isReady, value, setValue];
}


export function useJobHHDataFormRegion() {
    const data = React.useContext(JobHHDataContext);
    const form = React.useContext(JobHHDataFormContext);
    const methods = React.useContext(JobHHDataMethodsContext);

    const setValue = React.useCallback((newValue) => {
        if (methods && methods.setFormField) {
            methods.setFormField('region', newValue);
        }
    }, [methods]);


    let isReady = false;
    let value = null;
    let list = [];

    if (data) {
        isReady = !data.isLoading;
        if (data.data) {
            list = data.data.regions || [];
        }
    }
    if (form) {
        value = form.region;
    }
    return [isReady, list, value, setValue];
}


export function useJobHHDataFormSalary() {
    const data = React.useContext(JobHHDataContext);
    const form = React.useContext(JobHHDataFormContext);
    const methods = React.useContext(JobHHDataMethodsContext);

    const setValue = React.useCallback((newValue) => {
        if (methods && methods.setFormField) {
            methods.setFormField('salary', newValue);
        }
    }, [methods]);


    let isReady = false;
    let value = null;

    if (data) {
        isReady = !data.isLoading;
    }
    if (form) {
        value = form.salary || '';
    }

    return [isReady, value, setValue];
}

export function useJobHHDataFormActivity() {
    const data = React.useContext(JobHHDataContext);
    const form = React.useContext(JobHHDataFormContext);
    const methods = React.useContext(JobHHDataMethodsContext);

    const setValue = React.useCallback((newValue) => {
        if (methods && methods.setFormField) {
            methods.setFormField('activity', newValue);
        }
    }, [methods]);


    let isReady = false;
    let value = null;
    let list = [];

    if (data) {
        isReady = !data.isLoading;
        if (data.data) {
            list = data.data.activities || [];
        }
    }
    if (form) {
        value = form.activity;
    }

    return [isReady, list, value, setValue];
}


export function useJobHHDataFormEmployment() {
    const data = React.useContext(JobHHDataContext);
    const form = React.useContext(JobHHDataFormContext);
    const methods = React.useContext(JobHHDataMethodsContext);


    const setValue = React.useCallback((newValue) => {
        if (methods && methods.setFormField) {
            methods.setFormField('employment', newValue);
        }
    }, [methods])


    let isReady = false;
    let value = null;
    let list = [];

    if (data) {
        isReady = !data.isLoading;
        if (data.data) {
            list = data.data.employments || [];
        }
    }
    if (form) {
        value = form.employment;
    }

    return [isReady, list, value, setValue];
}


export function useJobHHDataFormOnlyWithSalary() {
    const data = React.useContext(JobHHDataContext);
    const form = React.useContext(JobHHDataFormContext);
    const methods = React.useContext(JobHHDataMethodsContext);


    const setValue = React.useCallback((newValue) => {
        if (methods && methods.setFormField) {
            methods.setFormField('onlyWithSalary', newValue);
        }
    }, [methods])


    let isReady = false;
    let value = null;

    if (data) {
        isReady = !data.isLoading;
    }
    if (form) {
        value = form.onlyWithSalary || false;
    }

    return [isReady, value, setValue];
}


export function useJobHHDataFormSearch() {
    const data = React.useContext(JobHHDataContext);
    const form = React.useContext(JobHHDataFormContext);
    const methods = React.useContext(JobHHDataMethodsContext);


    const doSearch = React.useCallback(() => {
        if (methods && methods.search) {
            methods.search();
        }
    }, [methods])


    let isReady = false;
    if (data) {
        isReady = !data.isLoading;
    }

    return [isReady, doSearch];
}

export function useJobHHDataFormReset() {
    const data = React.useContext(JobHHDataContext);
    const form = React.useContext(JobHHDataFormContext);
    const methods = React.useContext(JobHHDataMethodsContext);

    const doReset = React.useCallback(() => {
        if (methods && methods.reset) {
            methods.reset();
        }
    }, [methods]);

    let isReady = false;
    let canReset = false;
    if (data) {
        isReady = !data.isLoading;
    }

    if (form) {
        canReset = form.text
            || form.region
            || form.salary
            || form.activity
            || form.employment
            || form.onlyWithSalary;
    }

    return [isReady, canReset, doReset];
}


export function useJobHHDataSearch() {
    const search = React.useContext(JobHHDataSearchContext);
    let isLoading = true;
    let data = null;
    if (search) {
        isLoading = !!search.isLoading;
        data = search.data;
    }
    return [isLoading, data];
}

export function useJobHHDataSearchPage() {
    const search = React.useContext(JobHHDataSearchContext);
    const methods = React.useContext(JobHHDataMethodsContext);

    const setPage = React.useCallback((page) => {
        if (methods && methods.setPage) {
            methods.setPage(page);
        }
    }, [methods]);


    let page = 0;
    let pages = 0;
    if (search && search.data) {
        page = search.data.page || 0;
        pages = search.data.pages || 0;
    }
    return [page, pages, setPage];
}

export function useJobHHDataSearchOrderBy() {
    const data = React.useContext(JobHHDataContext);
    const form = React.useContext(JobHHDataFormContext);
    const search = React.useContext(JobHHDataSearchContext);
    const methods = React.useContext(JobHHDataMethodsContext);

    const setOrderBy = React.useCallback((orderBy) => {
        if (methods && methods.setOrderBy) {
            methods.setOrderBy(orderBy);
        }
    }, [methods]);

    let orderBy = null;
    let options = [];

    if (data && data.data) {
        options = data.data.orderBy || [];
    }
    if (form) {
        orderBy = form.orderBy || data.data.defaultOrderBy;
    }
    return [options, orderBy, setOrderBy];
}
