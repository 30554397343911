import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {ProgramDetailBlock} from '../../components/program-detail-block';
import {Carousel} from '../../components/carousel';
import {Feedback} from '../../components/feedback';
import {ProgramList} from '../../components/program-list';
import {GotQuestion} from '../../components/got-question';
import {getCarouselSlides, getDetailPageInitialState, getFeedbackSlides, getProgramList} from '../../api/api';
import {PopupFreeProfession} from '../../components/popup-free-profession';
import {CSSTransitionGroup} from 'react-transition-group';
import fetch from "isomorphic-unfetch";
import {GoTopButton} from '../../components/goTopButton';

class ProgramDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popup: null,
            programId: null,
            programDetail: null
        }
    }

    loadDetail = () => {
        let programId = this.props.match.params['id'];
        fetch("/api/site/program/" + programId + "/detail")
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 404) {
                    this.props.history.push("/programs")
                } else {
                    throw response;
                }
            })
            .then(json => {
                this.setState({
                    programId: programId,
                    programDetail: json,
                })
            })
    };

    componentDidMount() {
        this.loadDetail();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params['id'] !== this.state.programId) {
            this.loadDetail();
        }
    }

    handlePopupAdd = popup => {
        this.setState({popup});
    };

    handlePopupRemove = () => {
        this.setState({popup: null})
    };

    render() {
        return (
            <div className="wrapper wrapper--detail-page">
                <Header/>
				<GoTopButton />

                {this.state.programDetail &&
                <>
                    <ProgramDetailBlock initialState={this.state.programDetail}
                                        handlePopupAdd={() => this.handlePopupAdd(
                                            <PopupFreeProfession
                                                forWhom={this.state.programDetail.targetGroup}
                                                programId={this.state.programId}
                                                handleClose={this.handlePopupRemove}/>)}/>

                    {this.state.programDetail.photos && this.state.programDetail.photos.length > 0 &&
                    <>
                        <div className="block-margin program-detail__photo-section_separator"/>

                        <div className="container program-detail__photo-section_title">
                            <div className="block-title">Фото занятий</div>
                        </div>
                        <div className="carousel__default-wrapper program-detail__photo-section_photos">
                            <Carousel slides={this.state.programDetail.photos} noBg/>
                        </div>
                    </>
                    }

                    {this.state.programDetail.feedbacks && this.state.programDetail.feedbacks.length > 0 &&
                    <>
                        <div className="block-margin"/>

                        <div className="container">
                            <div className="block-title">Отзывы учеников</div>
                        </div>

                        <div className="feedback__default-wrapper">
                            <Feedback slides={this.state.programDetail.feedbacks}/>
                        </div>
                    </>
                    }

                    <div className="block-margin"/>

                    <GotQuestion/>

                    {this.state.programDetail.others && this.state.programDetail.others.length > 0 && <>

                        <div className="block-margin"/>

                        <div className="container">
                            <div className="program-list__detail-page-title">
                                Также в направлении “{this.state.programDetail.sphere}”
                                в {this.state.programDetail.placeFilterDesc} для {this.state.programDetail.forWhomFilterDesc}
                            </div>
                        </div>

                        <div className="program-list__default-wrapper">
                            <ProgramList programs={this.state.programDetail.others}/>
                        </div>

                    </>}
                </>}


                <div className="block-margin"/>

                <Footer/>

                <CSSTransitionGroup
                    transitionName="popup"
                    transitionEnterTimeout={1}
                    transitionLeaveTimeout={1}>
                    {this.state.popup}
                </CSSTransitionGroup>
            </div>
        );
    }
}

export {ProgramDetail};