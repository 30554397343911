export const quizQuestions = [
  {
    option_1: "Ухаживать за животными",
    option_2: "Обслуживать машины, приборы (следить, регулировать)",
  },
  {
    option_1: "Помогать больным",
    option_2: "Составлять таблицы, схемы, программы для вычислительных машин",
  },
  {
    option_1: "Следить за качеством книжных иллюстраций, плакатов, художественных открыток, грампластинок",
    option_2: "Следить за состоянием, развитием растений",
  },
  {
    option_1: "Обрабатывать материалы (дерево, ткань, металл, пластмассу и т.п.)",
    option_2: "Доводить Товары до потребителя, рекламировать, продавать",
  },
  {
    option_1: "Обсуждать научно-популярные книги, статьи",
    option_2: "Обсуждать художественные книги (или пьесы, концерты)",
  },
  {
    option_1: "Выращивать молодняк (животных какой-либопороды)",
    option_2: "Тренировать товарищей (или младших) в выполнении каких-либо действий (трудовых, учебных, спортивных)",
  },
  {
    option_1: "Копировать рисунки, изображения (или настраивать музыкальные инструменты)",
    option_2:
      "Управлять каким-либо грузовым (подъемным или транспортным) средством – подъемным краном, трактором, тепловозом и др.",
  },
  {
    option_1: "Сообщать, разъяснять людям нужные им сведения (в справочном бюро, на экскурсии и т.д.)",
    option_2: "Оформлять выставки, витрины (или участвовать в подготовке пьес, концертов)",
  },
  {
    option_1: "Ремонтировать вещи, изделия (одежду, технику), жилище",
    option_2: "Искать и исправлять ошибки в текстах, таблицах, рисунках",
  },
  {
    option_1: "Лечить животных",
    option_2: "Выполнять вычисления, расчеты",
  },
  {
    option_1: "Выводить новые сорта растений",
    option_2:
      "Конструировать, проектировать новые виды промышленных изделий (машины, одежду, дома, продукты питания и т.п.)",
  },
  {
    option_1: "Разбирать споры, ссоры между людьми, убеждать, разъяснять, наказывать, поощрять",
    option_2: "Разбираться в чертежах, схемах, таблицах (проверять, уточнять, приводить в порядок)",
  },
  {
    option_1: "Наблюдать, изучать работу кружков художественной самодеятельности",
    option_2: "Наблюдать, изучать жизнь микробов",
  },
  {
    option_1: "Обслуживать, налаживать медицинские приборы, аппараты",
    option_2: "Оказывать людям медицинскую помощь при ранениях, ушибах, ожогах и т.п",
  },
  {
    option_1: "Художественно описывать, изображать события (наблюдаемые и представляемые)",
    option_2: "Составлять точные описания-отчеты о наблюдаемых явлениях, событиях, измеряемых объектах и др.",
  },
  {
    option_1: "Делать лабораторные анализы в больнице",
    option_2: "Принимать, осматривать больных, беседовать с ними, назначать лечение",
  },
  {
    option_1: "Красить или расписывать стены помещений, поверхность изделий",
    option_2: "Осуществлять монтаж или сборку машин, приборов",
  },
  {
    option_1:
      "Организовать культпоходы сверстников или младших в театры, музеи, экскурсии, туристические походы и т.п.",
    option_2: "Играть на сцене, принимать участие в концертах",
  },
  {
    option_1: "Изготовлять по чертежам детали, изделия (машины, одежду), строить здания",
    option_2: "Заниматься черчением, копировать чертежи, карты",
  },
  {
    option_1: "Вести борьбу с болезнями растений, с вредителями леса, сада",
    option_2: "Работать на клавишных машинах (пишущей машинке, телетайпе, наборной машине и др.)",
  },
];

export const quizResult = [
  {
    id: 1,
    type: "«Человек - природа»",
    name: "nature",
    answers: ["1a", "3b", "6a", "10a", "11a", "13b", "16a", "20a"],
    typeDesc:
      "Если вы любите работать в саду, огороде, ухаживать за растениями, животными, любите предмет биологию, то ознакомьтесь с профессиями типа «человек-природа»",
    labor: ["животные, условия их роста, жизни;", "растения, условия их произрастания."],
    activities: [
      "изучать, исследовать, анализировать состояние, условия жизни растений или животных;",
      "выращивать растения, ухаживать за животными;",
      "проводить профилактику заболеваний растений и животных.",
    ],
    psychological: [
      "развитое воображение, наглядно-образное мышление, хорошая зрительная память, наблюдательность, способность предвидеть и оценивать изменчивые природные факторы;",
      "поскольку результаты деятельности выявляются по прошествии довольно длительного времени, специалист должен обладать терпением, настойчивостью, должен быть готовым работать вне коллективов, иногда в трудных погодных условиях, в грязи и т. п.",
    ],
    professions: [
      "Агроном",
      "обработчик-сортировщик овощей",
      "обработка соков",
      "овощевод",
      "озеленитель",
      "оператор по очистке, сушке, буртованию зерна",
      "садовод",
      "семеновод",
      "ботаник",
      "геоботаник",
      "физиолог растений",
      "ветеринарный фельдшер",
      "зверовод",
      "кроликовод",
      "оператор животноводческих комплексов",
      "оператор машинного доения",
      "оператор кормоцехов",
      "оператор птицефабрик",
      "охотовед",
      "пчеловод",
      "рыбовод",
      "тренер лошадей",
      "чабан",
      "гидробиолог",
      "дрессировщик",
      "зоотехник",
      "зооинженер",
      "зоолог",
      "энтомолог",
      "ихтиолог",
      "биохимик",
      "вирусолог",
      "генетик",
      "микробиолог",
      "физиолог",
      "цитолог",
      "эколог",
      "эмбриолог",
      "лесник",
      "садовник",
      "наладчик с/х машин и тракторов",
      "тракторист-машинист",
      "мастер орошения",
      "агрометеоролог",
      "климатолог",
      "метеоролог",
      "рыбовод",
      "гидролог",
      "ихтиолог",
      "океанолог",
      "взрывник",
      "замерщик",
      "маркшейдер",
      "техник-топограф",
      "ботаникогеограф",
      "геоморфолог",
      "горный инженер",
      "физикогеограф",
      "аппаратчик по производству муки",
      "вальцовщик",
      "дозаторщик",
      "машинист зернопогрузочных машин",
      "тестовод",
      "пекарь",
      "прессовщик полуфабриката макаронных изделий",
      "машинист поточной автоматической линии",
      "мастер цельномолочной и кисломолочной продукции",
      "маслодел-мастер",
      "сыродел-мастер",
      "аппаратчик производства пищевых продуктов",
      "формовщик колбасных изделий",
      "обжарщик колбасных изделий",
      "варщик колбасных изделий",
      "коптильщик колбасных изделий",
      "аппаратчик ферментации препаратов биосинтеза",
      "аппаратчик нейтрализации",
      "оператор выращивания дрожжей",
      "техник микробиологического производства",
      "инженер-технолог",
    ],
  },
  {
    id: 2,
    type: "«Человек - техника»",
    name: "machine",
    answers: ["1b", "4a", "7b", "9a", "11b", "14a", "17b", "19a"],
    typeDesc:
      "Если вам нравятся лабораторные работы по физике, химии, электротехнике, если вы делаете модели, разбираетесь в бытовой технике, если вы хотите создавать, эксплуатировать или ремонтировать машины, механизмы, аппараты, станки, то ознакомьтесь с профессиями «человек-техника»",
    labor: ["технические объекты (машины, механизмы)", "материалы, виды энергии."],
    activities: [
      "создание, монтаж, сборка технических устройств (специалисты проектируют, конструируют технические системы, устройства, разрабатывают процессы их изготовления. Из отдельных узлов, деталей собирают машины, механизмы, приборы, регулируют и налаживают их);",
      "эксплуатация технических устройств (специалисты работают на станках, управляют транспортом, автоматическими системами);",
      "ремонт технических устройств (специалисты выявляют, распознают неисправности технических систем, приборов, механизмов, ремонтируют, регулируют, налаживают их).",
    ],
    psychological: [
      "хорошая координация движений;",
      "точное зрительное, слуховое, вибрационное и кинестетическое восприятие;",
      "развитое техническое и творческое мышление и воображение;",
      "умение переключать и концентрировать внимание;",
      "наблюдательность.",
    ],
    professions: [
      "Испытатель электромашин",
      "кабельщик",
      "машинисты",
      "обмотчик электромоторов",
      "сборщик электромашин",
      "слесарь КИП и А",
      "термообработчик проводов и кабелей",
      "электрик",
      "электромонтер",
      "электромеханик",
      "инженер",
      "монтажник",
      "оператор процессов",
      "сборщик микросхем",
      "слесари",
      "радиоинженер-схемотехник",
      "радиоинженер-тополог",
      "инженер электросвязи",
      "аппаратчик",
      "бурильщик скважин",
      "вышкомонтажник",
      "газосварщик",
      "газорезчик",
      "моторист",
      "трубоукладчик",
      "арматурщик",
      "бетонщик",
      "взрывник",
      "каменщик",
      "кровельщик",
      "маляр",
      "отделочник",
      "плотник",
      "стекольщик",
      "столяр",
      "штукатур",
      "шлифовщик",
      "жестянщик",
      "литейщик металлов и сплавов",
      "модельщик выплавляемых изделий",
      "строгальщик",
      "термист",
      "формовщик машинной и ручной формовки",
      "наладчик автоматов и полуавтоматов",
      "наладчик станков и КИП",
      "токарь",
      "фрезеровщик",
      "формовщик",
      "осмотрщик вагонов",
      "техник-путеец железнодорожных путей",
      "техник-электрик",
      "водитель",
      "матрос",
      "моторист-рулевой",
      "судовой электромеханик",
      "судоводитель",
      "авиамеханик",
      "авиационный техник",
      "бортрадист",
      "техник-механик",
      "бортинженер",
      "пилот",
      "вязальщица",
      "обувщик",
      "часовщик",
      "швея",
    ],
  },
  {
    id: 3,
    type: "«Человек - знаковая система»",
    name: "symbol",
    answers: ["2b", "5a", "9b", "10b", "12b", "15a", "19b", "20b"],
    typeDesc:
      "Если вы любите выполнять вычисления, чертежи, схемы, вести картотеки, систематизировать различные сведения, если вы хотите заниматься программированием, экономикой или статистикой и т. п., то знакомьтесь с профессиями типа «человек - знаковая система». Большинство профессий этого типа связано с переработкой информации.",
    labor: [
      "тексты на родном или иностранном языках;",
      "цифры, формулы, таблицы;",
      "чертежи, схемы, карты;",
      "звуковые сигналы;",
    ],
    psychological: [
      "хорошая оперативная и механическая память;",
      "способность к длительной концентрации внимания на отвлеченном (знаковом) материале;",
      "хорошее распределение и переключение внимания;",
      "точность восприятия, умение видеть то, что стоит за условными знаками;",
      "усидчивость, терпение;",
      "логическое мышление.",
    ],
    professions: [
      "Библиотекарь",
      "делопроизводитель",
      "корректор",
      "машинистка",
      "наборщик",
      "паспортист",
      "почтальон",
      "радиооператор",
      "стенографистка",
      "архивариус",
      "библиограф",
      "востоковед",
      "историк",
      "лингвист",
      "нотариус",
      "патентовед",
      "переводчик",
      "философ",
      "банковский служащий",
      "изготовитель трафаретов и плат",
      "кассир",
      "лаборант",
      "оператор связи",
      "печатник",
      "табельщик",
      "таксировщик",
      "учетчик",
      "агент по ценным бумагам",
      "астроном",
      "аудитор",
      "брокер",
      "бухгалтер",
      "математик",
      "метролог",
      "налоговый инспектор",
      "провизор",
      "статистик",
      "стандартизатор",
      "фармацевт",
      "физик",
      "финансовый инспектор",
      "химик",
      "экономист",
      "аэрофотосъемщик",
      "дефектоскопист",
      "диспетчер управления движением",
      "чертежник",
      "штурман",
      "картограф",
      "конструктор",
      "маркшейдер",
      "оператор ЭВМ",
      "техник ЭВМ",
      "инженер-программист",
      "инженер-технолог АСУ",
      "математик-программист",
      "программист WEB-сайтов",
    ],
  },
  {
    id: 4,
    type: "«Человек - художественный образ»",
    name: "art",
    answers: ["3a", "5b", "7a", "8b", "13a", "15b", "17a", "18b"],
    labor: ["художественный образ, способы его построения."],
    activities: [
      "создание, проектирование художественных произведений;",
      "воспроизведение, изготовление различных изделий по образцу;",
      "размножение художественных произведений в массовом производстве.",
    ],
    psychological: [
      "художественные способности;",
      "развитое зрительное восприятие;",
      "наблюдательность, зрительная память;",
      "наглядно-образное мышление;",
      "творческое воображение;",
      "знание психологических законов эмоционального воздействия на людей.",
    ],
    professions: [
      "Артист",
      "вокалист",
      "дирижер",
      "искусствовед",
      "киновед",
      "режиссер",
      "композитор",
      "концертмейстер",
      "музыкант",
      "музыкант-педагог",
      "художник",
      "декоратор-оформитель",
      "маляр",
      "лепщик",
      "архитектор",
      "дизайнер",
      "реставратор",
      "скульптор",
      "вышивальщица",
      "гравер",
      "закройщик",
      "кондитер",
      "косметолог",
      "кулинар",
      "кружевница",
      "парикмахер",
      "портной",
      "скорняк",
      "гончар",
      "стеклодув",
      "витражист",
      "резчик по дереву",
      "ювелир",
      "эмальер",
      "печатник",
      "наборщик",
      "фотограф",
      "ретушер",
      "модельер",
    ],
  },
  {
    id: 5,
    type: "«Человек - человек»",
    name: "human",
    answers: ["2a", "4b", "6b", "8a", "12a", "14b", "16b", "18a"],
    labor: ["люди."],
    activities: [
      "воспитание, обучение людей;",
      "медицинское обслуживание;",
      "бытовое обслуживание;",
      "информационное обслуживание;",
      "защита общества и государства.",
    ],
    psychological: [
      "стремление к общению, умение легко вступать в контакт с незнакомыми людьми;",
      "устойчивое хорошее самочувствие при работе с людьми;",
      "доброжелательность, отзывчивость;",
      "выдержка;",
      "умение сдерживать эмоции;",
      "способность анализировать поведение окружающих и свое собственное, понимать намерения и настроение других людей, способность разбираться во взаимоотношениях людей, умение улаживать разногласия между ними, организовывать их взаимодействие;",
      "способность мысленно ставить себя на место другого человека, умение слушать, учитывать мнение другого человека;",
      "способность владеть речью, мимикой, жестами;",
      "развитая речь, способность находить общий язык с разными людьми;",
      "умение убеждать людей;",
      "аккуратность, пунктуальность, собранность;",
      "знание психологии людей.",
    ],
    professions: [
      "Няня",
      "дефектолог",
      "воспитатель",
      "вожатый",
      "методист",
      "преподаватель",
      "тренер",
      "врач",
      "милиционер",
      "адвокат",
      "прокурор",
      "судья",
      "юрисконсульт",
      "администратор торгового зала",
      "гидпереводчик",
      "культорганизатор",
      "экскурсовод",
      "бармен",
      "бортпроводник",
      "буфетчик",
      "гардеробщик",
      "горничная",
      "кассир",
      "контролер сберкассы",
      "официант",
      "парикмахер",
      "повар",
      "приемщик ателье",
      "продавец",
      "проводник",
      "приемщик заказов",
      "регистратор",
      "социальный работник",
      "телефонистка",
      "швейцар",
      "мастер участка",
      "цеха",
      "секретарь",
      "предприниматель",
      "организатор малого бизнеса",
      "менеджер",
      "журналист",
      "корреспондент",
      "психолог",
      "следователь",
      "социолог",
    ],
  },
];
