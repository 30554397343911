import * as React from "react";


const HeadItemStyle = {
    width: "70%",
    height: "24px",
}

const OrgInfoStyle = {
    width: "200px",
    height: "34px",
    marginTop: "12px",
    marginBottom: "12px"
}


const BodyItemStyle1 = {
    width: "60%",
    height: "12px",
    marginTop: "10px"
}
const BodyItemStyle2 = {
    width: "35%",
    height: "12px",
    marginTop: "10px"
}

export function JobHHSearchResultListSkeletonItem() {
    return (<div className="job-hh-search-result-list-item">
        <div className="skeleton">
            <div className="skeleton-item" style={HeadItemStyle}/>

            <div className="skeleton-item" style={OrgInfoStyle}/>

            <div className="skeleton-item" style={BodyItemStyle1}/>
            <div className="skeleton-item" style={BodyItemStyle2}/>
            <div className="skeleton-item" style={BodyItemStyle1}/>
        </div>
    </div>)

}
