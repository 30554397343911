import * as React from "react";
import {PageErrorCatch} from "../hoc/PageErrorCatch";
import {CofinanceIndexData} from "../services/Cofinance/CofinanceIndex";
import CofinanceRoot from "../components/cofinance/CofinanceRoot";

function CofinancePage() {
    return (<PageErrorCatch>
        <CofinanceIndexData>
            <CofinanceRoot/>
        </CofinanceIndexData>
    </PageErrorCatch>)
}

export default CofinancePage;
