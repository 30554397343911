import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import DatePickerProvider, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';

registerLocale('ru', ru);


// Output as ISO LOCAL DATE string

function padMD(number) {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}

function padY(number) {
    if (number < 10) {
        return '000' + number;
    }
    if (number < 100) {
        return '00' + number;
    }
    if (number < 1000) {
        return '0' + number;
    }
    return number;
}

const DatePicker = props => {
    const [chosenDate, setDate] = useState(null);

    const onChange = date => {
        setDate(date);
        let isoLocalDate;
        if (date) {
            isoLocalDate = `${padY(date.getFullYear())}-${padMD(date.getMonth() + 1)}-${padMD(date.getDate())}`;
        } else {
            isoLocalDate = null;
        }
        props.onChange(isoLocalDate);
    };

    const required = props.required || false;

    return <DatePickerProvider selected={chosenDate}
                               onChange={date => onChange(date)}
                               placeholderText={props.placeholder}
                               locale="ru"
                               name={props.name}
                               tabIndex={props.tabIndex}
                               showYearDropdown
                               showMonthDropdown
                               dropdownMode="select"
                               required={required}
                               dateFormat="dd.MM.yyyy"
                               className="form-page__input"
                               customInput={<InputMask mask={'99.99.9999'}/>}
    />
};

export {DatePicker};
