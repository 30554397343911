import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {PageTitle} from '../../components/page-title';
import {GoTopButton} from '../../components/goTopButton';
import {Link} from "react-router-dom";


export const JobResourcePage = () => {

    return (
        <div className="wrapper wrapper--default">
            <Header/>

            <GoTopButton/>

            <div className="container job__container">
                <PageTitle>Перечень информационных ресурсов по вопросам трудоустройства</PageTitle>

                <div className="job">
                    <div className="job__desc job-resources-page__desc">
                        <h3>1. Полезные ссылки для выпускника:</h3>
                    </div>
                    <table className="job-resources-page__table">
                        <thead>
                        <tr>
                            <th>Наименование</th>
                            <th>Описание</th>
                            <th>Ссылка</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Атлас новых профессий</td>
                            <td>«Атлас новых профессий» — это
                                альманах перспективных отраслей
                                и профессий на ближайшие 15–20
                                лет. Какие отрасли будут активно
                                развиваться? Какие в них будут
                                рождаться новые технологии,
                                продукты, практики управления и
                                какие новые специалисты
                                потребуются работодателям? Более
                                300 профессий по 27 отраслям.
                            </td>
                            <td>
                                <a href="http://atlas100.ru/" target="_blank">http://atlas100.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Навигатум</td>
                            <td>
                                Игровые инструменты профессионального и личностного самоопределения для детей и
                                взрослых. Мультсериалы для всех возрастных категорий, легкая подача материала,
                                психологическая поддержка и мотивация, тренинги, формирующие навыки и востребованные
                                качества.
                            </td>
                            <td>
                                <a href="https://navigatum.ru/" target="_blank">https://navigatum.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>ПроеКТОриЯ</td>
                            <td>
                                Интерактивная цифровая платформа для профориентации школьников. Онлайн-портал, где
                                собран уникальный контент о множестве профессий с рассказом от первого лица.
                                «Примерочная профессий» подберет то, что подходит конкретно Вам. Крутые профессии,
                                перспективные отрасли и лучшие эксперты. Всё для того, чтобы помочь тебе ответить на
                                вопрос «Кто Я?».
                            </td>
                            <td>
                                <a href="https://proektoria.online/" target="_blank">https://proektoria.online/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Профориентатор
                            </td>
                            <td>
                                Центр тестирования и развития. Ежемесячно обновляемые бесплатные тесты на
                                профориентацию, тесты на профессию, выбор профессии, методики, консультации
                                специалистов.
                            </td>
                            <td>
                                <a href="https://proforientator.ru/tests/"
                                   target="_blank">https://proforientator.ru/tests/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                ПрофВыбор.ру
                            </td>
                            <td>
                                Электронный музей профессий для помощи подросткам в формировании интереса к миру
                                профессий и выстраиванию своих профессиональных компетенций. Профессиограммы,
                                тематические статьи о профессиях, профориентационные тесты.
                            </td>
                            <td>
                                <a href="http://profvibor.ru/" target="_blank">http://profvibor.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Online Test Pad (Профессиональные предпочтения)
                            </td>
                            <td>
                                Бесплатный многофункциональный сервис. Система дистанционного обучения и тестирования,
                                конструктор онлайн-тестов по профориентации, опросов, кроссвордов, логических игр,
                                комплексные задания, диалоговые тренажёры, уроки.
                            </td>
                            <td>
                                <a href="https://onlinetestpad.com/ru" target="_blank">https://onlinetestpad.com/ru</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Калейдоскоп профессий
                            </td>
                            <td>
                                Сайт кинокомпании «Парамульт». Проекты для взрослых и детей. Современный образовательный
                                мультсериал. Просто и понятно о профориентации и выборе профессии для малышей,
                                старшеклассников, студентов, взрослых в формате коротких видео.
                            </td>
                            <td>
                                <a href="https://paramult.ru/" target="_blank">https://paramult.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Профтест
                            </td>
                            <td>
                                Отличный тест на профориентацию от опытного психолога-профконсультанта, работающего со
                                школьниками, студентами и взрослыми людьми с 2007 года по всей России. Самая необходимая
                                базовая информация - рекомендуемые профессии и приоритеты профессиональной деятельности,
                                доступна даже в бесплатной версии теста, тогда как полная дополняет результат подробным
                                описанием личностных особенностей человека.
                            </td>
                            <td>
                                <a href="https://careertest.ru/" target="_blank">https://careertest.ru/</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div className="job__desc job-resources-page__desc">
                        <h3>2. Перечень полезных ресурсов для поиска работы и получения практических
                            навыков и компетенций:</h3>
                    </div>

                    <table className="job-resources-page__table">
                        <thead>
                        <tr>
                            <th>N</th>
                            <th>Наименование</th>
                            <th>Ссылка</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                1.
                            </td>
                            <td>
                                АО "Федеральная корпорация по развитию малого и среднего предпринимательства
                            </td>
                            <td>
                                <a href="https://corpmsp.ru/" target="_blank">https://corpmsp.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2.
                            </td>
                            <td>
                                Универсариум (Проект ООО "Курсариум")
                            </td>
                            <td>
                                <a href="https://universarium.org/" target="_blank">https://universarium.org/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3.
                            </td>
                            <td>
                                20.35 Университет НТИ
                            </td>
                            <td>
                                <a href="https://2035.university" target="_blank">https://2035.university</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4.
                            </td>
                            <td>

                                Национальный проект "Малое и среднее предпринимательство и поддержка предпринимательской
                                инициативы" Министерства экономического развития Российской Федерации
                            </td>
                            <td>
                                <a href="https://мойбизнес.рф" target="_blank">https://мойбизнес.рф</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5.
                            </td>
                            <td>
                                Общероссийская база вакансий Федеральной службы по труду и занятости
                            </td>
                            <td>
                                <a href="https://trudvsem.ru/" target="_blank">https://trudvsem.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6.
                            </td>
                            <td>
                                Группа компаний HeadHunter
                            </td>
                            <td>
                                <a href="https://hh.ru/" target="_blank">https://hh.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                7.
                            </td>
                            <td>
                                Neuvoo. Онлайн ресурс для поиска работы
                            </td>
                            <td>
                                <a href="https://neuvoo.ru/" target="_blank">https://neuvoo.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                8.
                            </td>
                            <td>
                                Агрегатор вакансий Jooble в России
                            </td>
                            <td>
                                <a href="https://ru.jooble.org/" target="_blank">https://ru.jooble.org/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                9.
                            </td>
                            <td>
                                IT-сервис по поиску работы и подбору сотрудников
                            </td>
                            <td>
                                <a href="https://www.superjob.ru/" target="_blank">https://www.superjob.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                10.
                            </td>
                            <td>
                                Агрегатор вакансий
                            </td>
                            <td>
                                <a href="https://www.trud.com/" target="_blank">https://www.trud.com/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                11.
                            </td>
                            <td>
                                Агрегатор вакансий
                            </td>
                            <td>
                                <a href="https://www.rabota.ru/" target="_blank">https://www.rabota.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                12.
                            </td>
                            <td>
                                Агрегатор вакансий
                            </td>
                            <td>
                                <a href="https://russia.zarplata.ru/" target="_blank">https://russia.zarplata.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                13.
                            </td>
                            <td>
                                Вакансии государственной службы
                            </td>
                            <td>
                                <a href="https://gossluzhba.gov.ru/" target="_blank">https://gossluzhba.gov.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                14.
                            </td>
                            <td>
                                Кадровое агентство
                            </td>
                            <td>
                                <a href="https://ancor.ru/" target="_blank">https://ancor.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                15.
                            </td>
                            <td>
                                Портал государственной службы занятости Республики Татарстан
                            </td>
                            <td>
                                <a href="https://kazantrud.ru/" target="_blank">https://kazantrud.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                16.
                            </td>
                            <td>
                                Компания "FutureToday" - работа для студентов и выпускников
                            </td>
                            <td>
                                <a href="https://fut.ru/" target="_blank">https://fut.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                17.
                            </td>
                            <td>
                                Академия Яндекса
                            </td>
                            <td>
                                <a href="https://academy.yandex.ru/" target="_blank">https://academy.yandex.ru/</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                18.
                            </td>
                            <td>
                                Проект содействия занятости студентов во время пандемии коронавируса (студработа.рф)
                            </td>
                            <td>
                                <a href="https://studrabota.spb.ru/main/"
                                   target="_blank">https://studrabota.spb.ru/main/</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>


                    <a className="doc" target="_blank"
                       href="https://trudvsem.ru/vacancy/search?_page=0&_titleType=VACANCY_NAME&_regionIds=7200000000000&_publishDateType=ALL">
                        <img src="/images/link-out.png" alt=""/>
                        <div className="doc-desc">
                            <span>Актуальные вакансии Тюменской области</span>
                        </div>
                    </a>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
