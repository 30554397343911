import React from "react";
import cn from "classnames";

const VideoModal = ({ open, link, close }) => {
  const isYoutube = link && link.indexOf("youtube") !== -1;

  const overlayClasses = cn({
    "video_list-modal-overlay": true,
    "video_list-modal-overlay--open": open,
  });

  const modalClasses = cn({
    "video_list-modal": true,
    "video_list-modal--open": open,
  });

  return (
    <div className={overlayClasses} onClick={close}>
      <div className={modalClasses}>
        {open && (
          <>
            <iframe
              title="videoModal"
              src={`${link}${isYoutube ? "?autoplay=1" : "&autoplay=1,"}`}
              width="820"
              height="461"
              frameBorder="0"
              allowFullScreen
            />
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={close}
            >
              <circle cx="20" cy="20" r="20" fill="#C6CED9" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1213 22.2426L28 30.1213L30.1213 28L22.2426 20.1213L30.1213 12.2426L28 10.1213L20.1213 18L12.1213 10L10 12.1213L18 20.1213L10 28.1213L12.1213 30.2426L20.1213 22.2426Z"
                fill="#778899"
              />
            </svg>
          </>
        )}
      </div>
    </div>
  );
};

export { VideoModal };
