import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {PageTitle} from '../../components/page-title';
import {Link} from 'react-router-dom';
import {GotQuestion} from '../../components/got-question';
import {Accordion} from '../../components/accordion';
import {GoTopButton} from '../../components/goTopButton';



const Faq = props => {
  return (
    <div className="wrapper wrapper--default">
      <Header/>
	  <GoTopButton />
      <div className="container container--about">
        <PageTitle>Частые вопросы</PageTitle>
        
        {props.model && props.model.faq && <Accordion items={props.model.faq}/>}
        
        <div className="block-margin"/>
  
        <Link to="/contact-us" className="btn btn--primary btn--arrow inline__btn">
          <span>Задать вопрос</span>
          <svg width="41" height="16" viewBox="0 0 41 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M41.005 8.38197L33.795 16.001L32.009 14.472L37.191 8.99597H-0.000976562V7.00497H37.176L31.985 1.52497L33.773 -0.00402832L40.991 7.61497L40.55 7.99197L41.005 8.38197Z"
                    fill="#778899"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="41" height="16" fill="#778899"/>
              </clipPath>
            </defs>
          </svg>
        </Link>
        
        <div className="block-margin"/>
      </div>
      <GotQuestion/>
      <Footer/>
    </div>
  )
};

export {Faq};