import * as React from "react";
import {Job3ItemList} from "../JobCommon/Job3ItemList";
import {JobIndexVacancyListReadyItem} from "./JobIndexVacancyListReadyItem";

export function JobIndexVacancyListReady({vacancies}) {
    if (!vacancies || vacancies.length === 0) {
        return null;
    }
    return (<Job3ItemList>
        {vacancies.map(vacancy => (<JobIndexVacancyListReadyItem vacancy={vacancy} key={vacancy.id}/>))}
    </Job3ItemList>);
}
