import * as React from "react";
import {useJobHHDataSearch} from "../../services/Job";

const ItemStyle = {
    width: "150px",
    height: "22px"
}

export function JobHHSearchListFound() {
    const [isLoading, data] = useJobHHDataSearch();

    if (isLoading) {
        return (<div className="skeleton">
            <div className="skeleton-item" style={ItemStyle} />
        </div>)
    } else {
        return (<>Всего вакансий: {data.found || 0}</>)
    }

}
