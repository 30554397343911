import React from 'react';
import {Button} from '../button';
import {DoubleLink} from "../../api/functions";

const sliderInterval = 4000;

const isInViewport = (element) => {
    const bounding = element.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

class WelcomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSlide: 0,
            fade: false,
            animationInterval: null,
            fadeTimeout: null,
            stopped: false
        };
    }

    componentDidMount() {
        this.setSlideshow();
        window.addEventListener('scroll', this.checkVisibility);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.slides.length !== this.props.slides.length) {
            this.setSlideshow();
        }
    }

    componentWillUnmount() {
        this.clearSlideshow();
        window.removeEventListener('scroll', this.checkVisibility);
    }

    checkVisibility = () => {
        if (this.title && isInViewport(this.title)) {
            if (this.state.stopped) {
                this.setSlideshow();

                this.setState({stopped: false})
            }
        } else {
            if (!this.state.stopped) {
                this.clearSlideshow();
                this.setState({stopped: true})
            }
        }
    };

    setSlideshow = () => {
        clearInterval(this.state.animationInterval);
        if (this.props.slides.length > 1) {
            this.setState({
                animationInterval: setInterval(() => {
                    this.setState(prevState => {
                        const newIndex = prevState.currentSlide + 1 === this.props.slides.length
                            ? 0
                            : prevState.currentSlide + 1;

                        return {
                            currentSlide: newIndex,
                            fade: true,
                            fadeTimeout: setTimeout(() => {
                                this.setState({
                                    fade: false
                                })
                            }, 500)
                        }
                    });
                }, sliderInterval)
            });
        }
    };

    clearSlideshow = () => {
        if (this.state.animationInterval) {
            clearInterval(this.state.animationInterval);
        }
    };

    handleSliderClick = currentSlide => {
        this.clearSlideshow();
        this.setState({
            currentSlide,
            fade: true,
            fadeTimeout: setTimeout(() => {
                this.setState({
                    fade: false
                });
            }, 100)
        }, () => this.setSlideshow());
    };

    render() {
        const {slides} = this.props;
        const {currentSlide, fade} = this.state;
        const hasSlides = slides && slides.length > 0;

        let welcomeButton = null
        if (hasSlides) {
            if (slides[currentSlide].link) {
                let text = 'Перейти'
                if (slides[currentSlide].linkText) {
                    text = slides[currentSlide].linkText
                }
                welcomeButton = (<DoubleLink to={slides[currentSlide].link} className="welcome-screen__btn-container">
                    <Button className="btn--secondary welcome-screen__btn">
                        <>
                            {text}
                            <svg width="41" height="16" viewBox="0 0 41 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M41.005 8.38197L33.795 16.001L32.009 14.472L37.191 8.99597H-0.000976562V7.00497H37.176L31.985 1.52497L33.773 -0.00402832L40.991 7.61497L40.55 7.99197L41.005 8.38197Z"
                                          fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="41" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </>
                    </Button>
                </DoubleLink>)
            }
        }

        return (
            <div className={`welcome-screen__wrapper ${fade ? 'fade' : ''}`}>

                {hasSlides && <div className="welcome-screen__bg">
                    <img src={slides[currentSlide].img} alt=""/>
                </div>}
                <div className="container">
                    <div className="welcome-screen">
                        <div className="welcome-screen__logo">
                            <img src="/images/large-logo.png" alt=""/>
                        </div>
                        {hasSlides && <>
                            <div className="welcome-screen-title__wrapper" ref={ref => this.title = ref}>
                                <div className="welcome-screen-title"
                                     dangerouslySetInnerHTML={{__html: slides[currentSlide].text}}/>
                            </div>
                            {welcomeButton}
                            <div className="welcome-screen-slider">
                                {slides.map((item, index) => {
                                    if (item.link) {
                                        return (
                                            <DoubleLink to={item.link}
                                                  className={currentSlide === index ? 'welcome-screen-slider__item welcome-screen-slider__item--active' : 'welcome-screen-slider__item'}
                                                  key={index}>
                                                {item.name}
                                            </DoubleLink>
                                        );
                                    } else {
                                        return (
                                            <div
                                                className={currentSlide === index ? 'welcome-screen-slider__item welcome-screen-slider__item--active' : 'welcome-screen-slider__item'}
                                                key={index}
                                                onClick={() => this.handleSliderClick(index)}>
                                                {item.name}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </>}

                    </div>
                </div>
            </div>
        )
    }
}

export {WelcomeScreen};
