import * as React from "react";
import {format, parseISO} from "date-fns";
import {ru} from "date-fns/locale";
import {Link} from "react-router-dom";


export function JobHHSearchResultListReadyItem({vacancy}) {
    if (!vacancy || !vacancy.id) {
        return null;
    }


    let content = [];

    let salaryFrom = null;
    let salaryTo = null;
    if (vacancy.salaryFrom) {
        salaryFrom = Number(vacancy.salaryFrom).toLocaleString("ru-RU");
    }
    if (vacancy.salaryTo) {
        salaryTo = Number(vacancy.salaryTo).toLocaleString("ru-RU");
    }

    if (salaryFrom || salaryTo || vacancy.name) {
        const head = []

        if (vacancy.name) {
            head.push((<div key="name" className="job-hh-search-result-list-item__name">
                {vacancy.name}
            </div>));
        }


        if (salaryFrom || salaryTo) {
            let salary = '';
            if (salaryFrom) {
                salary = salary + ' от ' + salaryFrom;
            }
            if (salaryTo) {
                salary = salary + ' до ' + salaryTo;
            }
            if (vacancy.currency) {
                salary = salary + ' ' + vacancy.currency;
            }
            head.push((<div key="salary" className="job-hh-search-result-list-item__salary">
                {salary}
            </div>));
        }

        content.push(<div key="head" className="job-hh-search-result-list-item__head">
            {head}
        </div>)
    }


    if (vacancy.employerName || vacancy.areaName) {
        let employer = [];
        if (vacancy.employerName) {
            employer.push((<div key="employer" className="job-hh-search-result-list-item__employer-name">
                {vacancy.employerName}
            </div>));
        }
        if (vacancy.areaName) {
            employer.push((<div key="area" className="job-hh-search-result-list-item__area">
                {vacancy.areaName}
            </div>));
        }
        content.push((<div key="employer" className="job-hh-search-result-list-item__employer">{employer}</div>))
    }
    if (vacancy.responsibility) {
        content.push((<div
            key="responsibility"
            className="job-hh-search-result-list-item__responsibility"
            dangerouslySetInnerHTML={{__html: vacancy.responsibility}}/>));
    }


    let published = null;
    if (vacancy.publishedAt) {
        let parsed = parseISO(vacancy.publishedAt);
        let formatted = format(parsed, 'd MMMM', {locale: ru});
        published = (<div key="date" className="job-hh-search-result-list-item__date">
            {formatted}
        </div>);
    }

    let link = "/job/hh/" + vacancy.id;
    return (<a href={link} target="_blank" className="job-hh-search-result-list-item">
        {content}
        <div className="job-hh-search-result-list-item__footer">
            {published}
        </div>
    </a>);

}
