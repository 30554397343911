import React from 'react';
import { Link } from 'react-router-dom';
import { getMenuItems, getPhones } from '../../api/api';
import { HeaderSearch } from '../header-search';
import { HeaderPhones } from '../header-phones';
import { Button } from '../button';
import { HashLink } from 'react-router-hash-link';
import SecurityContext from '../../context/SecurityContext';
import {LowVisionPanel} from "../low-vision-panel";

const scrollWithOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -150;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const SubMenu = ({ subMenuItems }) => (
  <div className="header-menu-submenu">
    {subMenuItems.map((item, index) => (
      <HashLink to={item.link} className="header-menu-submenu__item" key={`sub_menu_item_${index}`}>
        {item.anchor}
      </HashLink>
    ))}
  </div>
);

class Header extends React.Component {
  static contextType = SecurityContext;

  constructor(props) {
    super(props);

    this.state = {
      scrollTop: document.body.scrollTop || document.documentElement.scrollTop,
      mobileMenuVisible: false,
      menuItems: getMenuItems(),
      phones: getPhones(),
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    this.setState({
      scrollTop: document.body.scrollTop || document.documentElement.scrollTop,
    });
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuVisible: !prevState.mobileMenuVisible }));
  };

  login = () => {
    this.context.login();
  };

  logout = () => {
    this.context.logout();
  };

  render() {
    const { props } = this;
    const { profile, authenticated, broken } = this.context;

    return (
      <>
        <header className={`header ${props.front && this.state.scrollTop < 250 ? 'header--front' : ''}`}>
          <div className="container">
            <div className={props.noLogo ? 'header__row header__row--no-logo' : 'header__row'}>
              <HashLink to="/#start" className="header__logo">
                <img src="/images/logo.png" alt="ЦОПП72" />
              </HashLink>

              <div className="header-menu">
                {this.state.menuItems.map((item, index) => {
                  if (item.link.startsWith('/#')) {
                    return (
                      <div className="header-menu__container" key={`header_menu_item_${index}}`}>
                        <HashLink to={item.link} scroll={scrollWithOffset} className="header-menu__item">
                          {item.anchor}
                        </HashLink>
                        {item.children && (
                          <>f
                            <SubMenu subMenuItems={item.children} />
                          </>
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <div className="header-menu__container" key={`header_menu_item_${index}`}>
                        <HashLink to={item.link} className="header-menu__item">
                          {item.anchor}
                        </HashLink>
                        {item.children && (
                          <>
                            <SubMenu subMenuItems={item.children} />
                          </>
                        )}
                      </div>
                    );
                  }
                })}
              </div>

              <HeaderSearch/>
              <HeaderPhones />
              <div className="header__btn-container">
                {authenticated ? (
                  <a href="https://platform.copp72.ru/lk/schedule">
                    <Button className="btn--tertiary header__btn">Оставить заявку</Button>
                  </a>
                ) : (
                  <Link to="/schedule">
                    <Button className="btn--tertiary header__btn">Оставить заявку</Button>
                  </Link>
                )}

                {!authenticated && !broken && (
                  <div className="header-profile">
                    {/*<img
                        onClick={this.login}
                        className="header-profile__image"
                        src="/images/profile-login.png"
                        alt="Войти в личный кабинет"
                    />*/}
                    <a onClick={this.login} tabIndex={-1}
                       className="header-profile__image"
                        title="Войти в личный кабинет">
                      <svg height="32" width="32" viewBox="0 0 512 512"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill="#394959" d="m465.535156.320312c-.53125-.042968-.980468-.320312-1.535156-.320312h-229.332031c-35.285157 0-64 28.714844-64 64v21.332031c0 11.777344 9.554687 21.335938 21.332031 21.335938s21.332031-9.558594 21.332031-21.335938v-21.332031c0-11.753906 9.578125-21.332031 21.335938-21.332031h99.390625l-6.507813 2.175781c-17.277343 5.972656-28.882812 22.25-28.882812 40.488281v320h-64c-11.757813 0-21.335938-9.578125-21.335938-21.332031v-42.667969c0-11.773437-9.554687-21.332031-21.332031-21.332031s-21.332031 9.558594-21.332031 21.332031v42.667969c0 35.285156 28.714843 64 64 64h64v21.332031c0 23.53125 19.132812 42.667969 42.664062 42.667969 4.566407 0 8.898438-.660156 13.589844-2.113281l128.171875-42.730469c17.300781-5.972656 28.90625-22.25 28.90625-40.488281v-384c0-24.875-21.441406-44.375-46.464844-42.347657zm0 0"/>
                        <path fill="#394959" d="m228.414062 198.25-85.332031-85.332031c-6.101562-6.101563-15.273437-7.9375-23.253906-4.628907-7.957031 3.304688-13.160156 11.09375-13.160156 19.710938v64h-85.335938c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h85.335938v64c0 8.617187 5.203125 16.402343 13.160156 19.710937 7.980469 3.304688 17.152344 1.472656 23.253906-4.628906l85.332031-85.335938c8.34375-8.339843 8.34375-21.820312 0-30.164062zm0 0"/>
                      </svg>
                    </a>
                  </div>
                )}
                {!!authenticated && !broken && (
                  <div className="header-profile">
                    <img className="header-profile__image" src="/images/profile-user.png" alt="" />
                    <div className="header-profile__item">
                      <div className="header-profile__item-arrow-up"/>
                      <div className="header-profile__name">
                        <span>
                          {profile.lastName} {profile.firstName}
                        </span>
                        <a href="https://platform.copp72.ru/lk" className="header-profile__link">Программы</a>
                        <a href="https://platform.copp72.ru/lk" className="header-profile__link">Профиль</a>
                      </div>
                      <a tabIndex={-1} className="header-profile__link" onClick={this.logout}>
                        Выйти
                      </a>
                    </div>
                  </div>
                )}
              </div>

              <div className="header__mobile-menu-toggle" onClick={this.toggleMobileMenu}>
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 14V11.999H22V14H0ZM0 6.00002H22V8.00002H0V6.00002ZM0 -0.000976562H22V2.00002H0V-0.000976562Z"
                    fill="#394959"
                  />
                </svg>
                Меню
              </div>
            </div>
          </div>
          <LowVisionPanel/>
        </header>

        <div className={`mobile-menu ${this.state.mobileMenuVisible ? 'visible' : ''}`}>
          <div className="mobile-menu__logo">
            <img src="/images/logo.png" alt="" />
          </div>
          <div className="mobile-menu__close" onClick={this.toggleMobileMenu}>
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.996 14.59L15.658 16.01L8.49999 9.33401L1.34199 16.01L0.00299072 14.59L7.06899 8.00001L0.00299072 1.40901L1.34199 -0.0109863L8.49999 6.66501L15.658 -0.0109863L16.996 1.40901L9.92999 8.00001L16.996 14.59Z"
                fill="#394959"
              />
            </svg>
          </div>

          {true && <HeaderSearch mobile />}

          <div className="mobile-menu__list">
            {this.state.menuItems.map((item, index) => {
              if (item.link.startsWith('/#')) {
                return (
                  <HashLink
                    to={item.link}
                    onClick={this.toggleMobileMenu}
                    scroll={scrollWithOffset}
                    className="mobile-menu__item"
                    key={index}
                  >
                    {item.anchor}
                  </HashLink>
                );
              } else {
                return (
                  <HashLink to={item.link} className="mobile-menu__item" key={index}>
                    {item.anchor}
                  </HashLink>
                );
              }
            })}
          </div>

          <div className="mobile-menu-phones">
            <div className="mobile-menu-phones__city">
              <img src="/images/place.png" alt="" />
              <span>Тюмень</span>
            </div>

              <div className="header-phone__info">
                  <div> График работы ЦОПП</div>
                  <div>ПН-ПТ с 09-00 до 18-00</div>
                  <div>  Обед с 13.00 до 14.00</div>
                  <div> СБ, ВС - выходной</div>
              </div>

            <div className="mobile-menu-phones__list">
                <div className="header-phone__info">По общим вопросам:</div>
              {this.state.phones.map((item, index) => (
                <a className="header-phone__link" href={`tel:${item}`} key={index}>
                  {item}
                </a>
              ))}
              <a className="header-phone__link" href="mailto:info@copp72.ru">info@copp72.ru</a>
            </div>
          </div>

          <div className="mobile-menu__action">
            <Link to="/schedule">
                <Button className="btn--tertiary mobile-menu__btn">Оставить заявку</Button>
            </Link>
          </div>
          {!authenticated && !broken && (
            <div className="mobile-menu__action">
              <div onClick={this.login} className="btn btn--primary mobile-menu__btn">
                <span>Войти</span>
              </div>
            </div>
          )}
          {!!authenticated && !broken && (
            <div className="mobile-menu__action">
              <a href="https://platform.copp72.ru/lk">
                <img src="/images/profile-user.png" alt="lk" />
              </a>
              <div onClick={this.logout} className="btn btn--primary mobile-menu__btn">
                <span>Выйти</span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export { Header };
