import React from 'react';
import OnVisible from 'react-on-visible';

class VideoBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
        };
    }

    handleVisible = playing => {
        this.setState({playing: true});
    };

    render() {
        return (
            <div className="container">
                <div className="video-block">
                    <div className="video-block__text">
                        <div className="video-block__title">
                            Обучение в ЦОПП: <br/>
                            живое, активное, яркое
                        </div>
                        <div className="video-block__description">
                            Максимально комфортные условия для вас: <br/>
                            — наши учителя — яркие личности и замечательные педагоги, их любят дети и признают
                            коллеги; <br/>
                            — показываем возможности и помогаем найти свое предназначение; <br/>
                            — филиалы в 5 городах Тюменской области.
                        </div>
                    </div>

                    <div className="video-block__video">
                        <OnVisible onChange={this.handleVisible}>
                            {this.state.playing &&
                            <iframe width="560" height="315"
									title="video"
                                    src="https://www.youtube.com/embed/GCqRuyyFglE?controls=0"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>}
                        </OnVisible>
                    </div>
                </div>
            </div>
        );
    }
}

export {VideoBlock};