import React from "react";
import { Button } from "../button";
import { quizQuestions } from "../../api/quizData";

const QuizInitial = ({ setStartQuiz }) => {
  return (
    <div className="quiz__initilal">
      <div className="quiz__initilal--title">
        Дифференциально-диагностический опросник (ДДО) Е.А.Климовa
      </div>
      <p>
        Методика предназначена для отбора на различные типы профессий в
        соответствии с классификацией типов профессий Е.А.Климова. Можно
        использовать при профориентации подростков и взрослых.
      </p>
      <h5>Инструкция к тесту:</h5>
      <p>
        Предположим, что после соответствующего обучения Вы сможете выполнить
        любую работу. Но если бы Вам пришлось выбирать только из двух
        возможностей, что бы Вы предпочли?
      </p>

      <p>
        Количество вопросов в тесте: <b>{quizQuestions.length}</b>
      </p>
      <div className="quiz__initilal--control">
        <Button className="btn--secondary" onClick={setStartQuiz}>
          Начать тестирование
        </Button>
      </div>
    </div>
  );
};

export { QuizInitial };
