import React from "react";
import {Link} from "react-router-dom";
import {useJobIndexData} from "../../services/Job/JobIndex";
import {JobIndexVacancyListSkeleton} from "./JobIndexVacancyListSkeleton";
import {JobIndexVacancyListReady} from "./JobIndexVacancyListReady";

export function JobIndexVacancyList() {
    const [isLoading, data] = useJobIndexData()

    let list = null;
    if (isLoading) {
        list = (<JobIndexVacancyListSkeleton/>);
    } else if (data && data.vacancies && data.vacancies.length > 0) {
        list = (<JobIndexVacancyListReady vacancies={data.vacancies}/>)
    } else {
        return null;
    }

    return (<div className="job-index-connected-vacancy-list">
        <div className="job-index-connected-vacancy-list--before">
            <div className="job-index-connected-vacancy-list--before-source">
                <span
                    className="job-index-connected-vacancy-list--before-source-text">Свежие вакансии партнера hh.ru</span>
                <img className="job-index-connected-vacancy-list--before-source-img"
                     alt="HeadHunter"
                     width="20px"
                     height="20px"
                     src="/images/hh.png"/>
            </div>
            <Link to="/job/hh" className="job-index-connected-vacancy-list--before-all-link">
                <span className="job-index-connected-vacancy-list--hh-link">Все вакансии</span>
            </Link>
        </div>
        {list}
        <div className="job-index-connected-vacancy-list--after">
            <Link to="/job/hh" className="job-index-connected-vacancy-list--after-all-link">
                <span className="job-index-connected-vacancy-list--hh-link">Все вакансии</span>
            </Link>
        </div>
    </div>)
}
