import * as React from "react";
import {Link} from "react-router-dom";


export function JobHHItemNotFound() {
    return (<div className="job-hh-item-ready">
        <div className="job-hh-item-ready__content">
            <h2>Вакансия не найдена</h2>
        </div>
        <Link to="/job/hh" className="job-hh-item-ready__return">
            <svg width="17" height="12" viewBox="0 0 17 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M17.001 7.01401H2.98098L6.36898 10.853L5.11998 12.001L0.719981 7.01401H0.00598145V4.98201H0.663981L5.16298 -0.00299072L6.43998 1.14401L2.97598 4.98201H17.001V7.01401Z"
                      fill="#D0101B"/>
            </svg>
            <span>Все вакансии</span>
        </Link>
    </div>)
}

