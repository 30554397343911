import React from 'react';
import {getPhones} from '../../api/api';

const HeaderPhones = () => {
  const phones = getPhones();

  return (
    <div className="header-phone">
      <div className="header-phone__city">
        <img src="/images/place.png" alt=""/>
        <span>Тюмень</span>
      </div>
      <a className="header-phone__link" href={`tel:${phones[0]}`}>{phones[0]}</a>

      <div className="header-phone__tooltip">

          <div className="header-phone__info">
              <div> График работы ЦОПП</div>
              <div>ПН-ПТ с 09-00 до 18-00</div>
              <div>  Обед с 13.00 до 14.00</div>
              <div> СБ, ВС - выходной</div>
          </div>

          <div className="header-phone__info">По общим вопросам:</div>
        {phones.map((item, index) => (
          <a className="header-phone__link" href={`tel:${item}`} key={index}>{item}</a>
        ))}

          <a className="header-phone__link" href="mailto:info@copp72.ru">info@copp72.ru</a>
      </div>
    </div>
  );
};

export {HeaderPhones};
