import React, {useRef} from 'react';
import {Link} from 'react-router-dom';

export const StepControl = ({stepTitlePrev, titlePrev, stepTitleNext, titleNext, prev, next}) => {

    let rootClassName = "step-control";
    if (!prev && next) {
        rootClassName += " step-control--next-only";
    }
    if (prev && !next) {
        rootClassName += " step-control--prev-only";
    }

    let imgNextRef = useRef(null);
    const onNextLinkMouseOver = (e) => {
        if (imgNextRef.current) {
            imgNextRef.current.src = "/images/components/step-control/next-active.png";
        }
    }
    const onNextLinkMouseLeave = (e) => {
        if (imgNextRef.current) {
            imgNextRef.current.src = "/images/components/step-control/next.png";
        }
    }

    let imgPrevRef = useRef(null);
    const onPrevLinkMouseOver = (e) => {
        if (imgPrevRef.current) {
            imgPrevRef.current.src = "/images/components/step-control/prev-active.png";
        }
    }
    const onPrevLinkMouseLeave = (e) => {
        if (imgPrevRef.current) {
            imgPrevRef.current.src = "/images/components/step-control/prev.png";
        }
    }

    return (
        <div className={rootClassName}>
            {prev && (
                <Link to={prev} className="step-control__nav-item"
                      onMouseOver={onPrevLinkMouseOver}
                      onMouseLeave={onPrevLinkMouseLeave}>
                    <img ref={imgPrevRef} className="step-control__nav-item-img"
                         src="/images/components/step-control/prev.png"
                         alt="Назад"/>
                    <div className="step-control__nav-item-text">
                        <div className="step-control__nav-item-step-title">{stepTitlePrev}</div>
                        <div className="step-control__nav-item-title" title={titleNext}>{titlePrev}</div>
                    </div>
                </Link>
            )}

            {next && (
                <Link to={next} className="step-control__nav-item"
                      onMouseOver={onNextLinkMouseOver}
                      onMouseLeave={onNextLinkMouseLeave}>
                    <div className="step-control__nav-item-text">
                        <div className="step-control__nav-item-step-title">{stepTitleNext}</div>
                        <div className="step-control__nav-item-title" title={titleNext}>{titleNext}</div>
                    </div>
                    <img ref={imgNextRef} className="step-control__nav-item-img"
                         src="/images/components/step-control/next.png"
                         alt="Далее"/>
                </Link>
            )}
        </div>
    );
};
