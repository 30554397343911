import React from 'react';

import {Header} from '../../components/header';
import {WelcomeScreen} from '../../components/welcome-screen';
import {ProgramList} from '../../components/program-list';
import {InlineButtonBlock} from '../../components/inline-button-block';
import {GotQuestion} from '../../components/got-question';
import {About} from '../../components/about';
import {VideoBlock} from '../../components/video-block';
import {Carousel} from '../../components/carousel';
import {Feedback} from '../../components/feedback';
import {News} from '../../components/news';
import {GotQuestionLarge} from '../../components/got-question-large';
import {Footer} from '../../components/footer';
import {GoTopButton} from '../../components/goTopButton'
import {FooterMap} from '../../components/footer-map';
import {Link} from 'react-router-dom';
import {PopupFreeProfession} from '../../components/popup-free-profession';
import {CSSTransitionGroup} from 'react-transition-group';
import SEO from '../../components/seo';
import {AnnounceCarousel} from "../../components/announce-carousel";
import {AnnounceList} from "../../components/announce-list";

const announcesStub = [
    {
        dateEvent: "28.09",
        title: "Мастер-класс “Питание в 21 веке”",
        text: "<p>Здоровое питание-основа долголетия.</p><p> Хорошее состояние кожи, волос, ногтей, отличная работа огранизма-все это зависит от правильного питания.</p>",
        img: "/images/nail-designer.jpg",
        link: "/news/17"
    },
    {
        dateEvent: "28.09",
        title: "Кондитерское и кондитерское дело",
        text: "Открывается набор на новую специальность для людей предпенсионного возраста.",
        img: "/images/nail-designer.jpg",
        link: "/news/17"
    },
    {
        dateEvent: "28.09",
        title: "Сервировка и декор стола. Онлайн курс",
        text: "Здесь научат сервировке стола к завтраку, обеду и ужину или праздику. Владеть разными способами переноса блюд. Как убрать скатерть при гостях.",
        img: "/images/nail-designer.jpg",
        link: "/news/17"
    },
    {
        title: "Серебряные волонтеры Вперед!",
        text: "Приглашает серебряных волонтеров к нам на курс психологии “Счастливы вместе”",
        img: "/images/nail-designer.jpg",
        link: "/news/17"
    }
];

class FrontPage extends React.Component {
    constructor() {
        super();

        this.state = {
            popup: null,
        }
    }

    handlePopupAdd = popup => {
        this.setState({popup});
    };

    handlePopupRemove = () => {
        this.setState({popup: null})
    };

    render() {

        let hasSlides = this.props.model && this.props.model.slides && this.props.model.slides.length > 0;
        let slides = [];
        if (hasSlides) {
            slides = this.props.model.slides;
        }

        return (
            <>
                <div id="start" className="wrapper wrapper--front-page">
                    <Header noLogo front/>
					<GoTopButton />

                    <WelcomeScreen slides={slides}/>

                    {this.props.model && <>

                        {this.props.model.announces && this.props.model.announces.length > 0 && <>
                            <div className="block-margin"/>

                            <div className="container">
                                <div className="program-list__title">Анонсы мероприятий</div>
                            </div>

                            <div className="announce-list__default-wrapper">
                                {/*<AnnounceCarousel slides={announcesStub} noBg/>*/}
                                <AnnounceCarousel slides={this.props.model.announces} noBg/>
                            </div>

                            <div className="announce-list__lw-wrapper">
                                <AnnounceList announces={this.props.model.announces}/>
                            </div>
                        </>}

                        {this.props.model.programs && this.props.model.programs.length > 0 && <>
                            <div className="block-margin"/>

                            <div className="container">
                                <div className="program-list__title">Программы</div>
                            </div>

                            <div className="program-list__default-wrapper">
                                <ProgramList programs={this.props.model.programs}/>
                            </div>
                        </>}

                        <InlineButtonBlock onClick={() => this.handlePopupAdd(<PopupFreeProfession
                            handleClose={this.handlePopupRemove}/>)}/>

                        <div className="block-margin"/>

                        <GotQuestion/>

                        <div id="about" className="block-margin"/>

                        <About model={this.props.model}/>

                        <div className="block-margin"/>

                        <VideoBlock/>

                        {this.props.model.photos && this.props.model.photos.length > 0 && <>
                            <div className="block-margin"/>

                            <div className="container">
                                <div className="carousel__title">
                                    Мы уже сейчас можем заложить фундамент будущей <br/>
                                    личности и помочь вам раскрыться
                                </div>
                            </div>

                            <div className="photo_carousel carousel__default-wrapper">
                                <Carousel slides={this.props.model.photos} infinite/>
                            </div>
                        </>}

                        {this.props.model.feedbacks && this.props.model.feedbacks.length > 0 && <>
                            <div id="feedback" className="block-margin"/>

                            <div className="container">
                                <div className="feedback__title">Отзывы выпускников</div>
                                <div className="feedback__subtitle">
                                    Мы гордимся нашими выпускниками, и они рады, что учились у нас. <br/>
                                    Наши <Link to="/programs" className="underline-link-inline">программы</Link> прошли уже
                                    более 4000
                                    человек.
                                </div>
                            </div>

                            <div className="feedback__default-wrapper">
                                <Feedback slides={this.props.model.feedbacks}/>
                            </div>
                        </>}

                        {this.props.model.news && this.props.model.news.length > 0 && <>
                            <div id="news" className="block-margin"/>

                            <News news={this.props.model.news} extraLink={this.props.model.pinned}/>
                        </>}

                        <GotQuestionLarge />
                        <Footer/>
                    </>}

                </div>

                {this.props.model && <FooterMap/>}

                <CSSTransitionGroup
                    transitionName="popup"
                    transitionEnterTimeout={1}
                    transitionLeaveTimeout={1}>
                    {this.state.popup}
                </CSSTransitionGroup>

                <SEO pageProps={{
                  title: "ЦОПП Тюменской области",
                  description: "Центр опережающей профессиональной подготовки",
                  thumbnail: "https://copp72.ru/android-chrome-192x192.png",
                  url: `https://copp72.ru` }}
                />

            </>
        );
    }
}

export {FrontPage};
