import React, { useState } from "react";
import {Button} from "../button";
import cn from "classnames";

const LowVisionPanel = (props) => {
    const [imagesVisible, setImagesVisible] = useState(true);
    const [colorTheme, setColorTheme] = useState('white');
    const [lineInterval, setLineInterval] = useState('standart');
    let root = document.getElementById('root');

    function imgSetHidden() {
        if (root.classList.contains('image_hidden')) {
            setImagesVisible(true);
            root.classList.remove('image_hidden');
        } else {
            setImagesVisible(false);
            root.classList.add('image_hidden');
        }
    }

    function themeSetColorDefault() {
        if (root.classList.contains('low_vision_black')) {
            root.classList.remove('low_vision_black');
        }
        if (root.classList.contains('low_vision_blue')) {
            root.classList.remove('low_vision_blue');
        }
    }

    function themeSetColorBlack() {
        if (!root.classList.contains('low_vision_black')) {
            root.classList.add('low_vision_black');
        }
        if (root.classList.contains('low_vision_blue')) {
            root.classList.remove('low_vision_blue');
        }
    }

    function themeSetColorBlue() {
        if (!root.classList.contains('low_vision_blue')) {
            root.classList.add('low_vision_blue');
        }
        if (root.classList.contains('low_vision_black')) {
            root.classList.remove('low_vision_black');
        }
    }

    function fontSizePlus() {
        let style = window.getComputedStyle(root, null).getPropertyValue('font-size');
        let fs = parseFloat(style);
        console.log(fs);
        root.style.fontSize = (fs + 1).toString() + "px";
    }

    function fontSizeMinus() {
        let style = window.getComputedStyle(root, null).getPropertyValue('font-size');
        let fs = parseFloat(style);
        if (fs > 16) {
            root.style.fontSize = (fs - 1).toString() + "px";
        }
    }

    function setLineIntervalValues(lh,ls) {
        root.style.lineHeight = lh;
        root.style.letterSpacing = ls;
    }

    return (
        <div className="low-vision-panel">
            <div className="low-vision-panel__item">
                <span>Цветовая схема:</span>
                <div className="btn_square__wrapper">
                    <Button className={cn('btn_square', 'btn_square--white')}
                            onClick={() => {
                                setColorTheme('white');
                                themeSetColorDefault();
                            }}><span>A</span></Button>
                    <Button className={cn('btn_square', 'btn_square--black')}
                            onClick={() => {
                                setColorTheme('black');
                                themeSetColorBlack();
                            }}><span>A</span></Button>
                    <Button className={cn('btn_square', 'btn_square--blue')}
                            onClick={() => {
                                setColorTheme('blue');
                                themeSetColorBlue();
                            }}><span>A</span></Button>
                </div>
            </div>
            <div className="low-vision-panel__item">
                <span>Размер шрифта:</span>
                <div className="btn_square__wrapper">
                    <Button className={cn('btn_square', 'btn_square--white')}
                            onClick={fontSizeMinus}>
                        <svg height="30px" viewBox="0 -192 469.33333 469" width="30px" xmlns="http://www.w3.org/2000/svg">
                            <path d="m437.332031.167969h-405.332031c-17.664062 0-32 14.335937-32 32v21.332031c0 17.664062 14.335938 32 32 32h405.332031c17.664063 0 32-14.335938 32-32v-21.332031c0-17.664063-14.335937-32-32-32zm0 0"/>
                        </svg>
                    </Button>
                    <Button className={cn('btn_square', 'btn_square--white')}
                            onClick={fontSizePlus}>
                        <svg height="30px" viewBox="0 0 469.33333 469.33333" width="30px" xmlns="http://www.w3.org/2000/svg">
                            <path d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0"/>
                        </svg>
                    </Button>
                </div>
            </div>
            <div className="low-vision-panel__item">
                <span>Интервал между строк и букв:</span>
                <div className="btn_white__wrapper">
                    <Button className="btn--white"
                            onClick={()=>{
                                setLineIntervalValues("24px", "0.1px");
                                setLineInterval('standart');
                            }}>
                        Стандартный
                    </Button>
                    <Button className="btn--white"
                            onClick={()=>{
                                setLineIntervalValues("32px", "1px");
                                setLineInterval('big');
                            }}>
                        Большой
                    </Button>
                    <Button className="btn--white"
                            onClick={()=>{
                                setLineIntervalValues("40px", "2px");
                                setLineInterval('large');
                            }}>
                        Крупный
                    </Button>
                </div>
            </div>
            <div className="low-vision-panel__item">
                <span>Картинки и видео:</span>
                <Button className="btn--white"
                        onClick={imgSetHidden}>
                    {imagesVisible ? 'Отключить' : 'Показать'}
                </Button>
            </div>
        </div>
    );
};

export { LowVisionPanel };