import React from 'react';
import {Link} from 'react-router-dom';
import {getPhones} from "../../api/api";

const zeroMargin = {margin: 0};

const GotQuestionPhones = () => {
    let phones = getPhones() || [];
    let cmp = [];
    cmp.push(" ");
    for (let idx = 0; idx < phones.length; idx++) {
        if (idx > 0) {
            cmp.push(', ');
        }
        let phone = phones[idx];
        let phoneHref = "tel:" + phone;
        cmp.push(<a style={zeroMargin} href={phoneHref}>{phone}</a>);
    }
    cmp.push(" ");
    return cmp;
}

const GotQuestion = props => (
    <div className="got-question">
        <div className="got-question__bg">
            <img src="/images/photo-pattern.png" alt=""/>
        </div>

        <div className="container">
            <div className="got-question__title">{props && props.title ? props.title : `Появились вопросы?`}</div>
            <div className="got-question__text">
                Свяжитесь с нами любым удобным для вас способом. <br/>
                Позвоните <GotQuestionPhones/>
                 или
                напишите.
            </div>
            {props && props.linkto ?
                <a href={`mailto:${props.linkto}`}>
                    <div className="btn btn--secondary got-question__btn">НАПИСАТЬ НАМ</div>
                </a>
                : <Link to="/contact-us">
                    <div className="btn btn--secondary got-question__btn">НАПИСАТЬ НАМ</div>
                </Link>
            }

        </div>
    </div>
);

export {GotQuestion};
