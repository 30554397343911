import * as React from "react";
import {JobHHSearchResultListSkeletonItem} from "./JobHHSearchResultListSkeletonItem";


export function JobHHSearchResultListSkeleton() {
    return <div className="job-hh-search-result-list">
        <JobHHSearchResultListSkeletonItem />
        <JobHHSearchResultListSkeletonItem />
        <JobHHSearchResultListSkeletonItem />
    </div>
}
