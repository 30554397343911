import * as React from "react";
import {useJobHHDataFormReset} from "../../services/Job";

const SkeletonItemStyle = {
    width: "120px",
    height: "17px"
}

export function JobHHSearchPanelReset() {
    const [isReady, canReset, doReset] = useJobHHDataFormReset();

    if (!canReset) {
        return null;
    }

    if (!isReady) {
        return (<div className="skeleton">
            <div className="skeleton-item" style={SkeletonItemStyle}/>
        </div>);
    }


    return (<div className="job-hh-search-panel-reset" tabIndex={-1} onClick={doReset}>
        <i className="job-hh-search-panel-reset__icon"/>
        <div className="job-hh-search-panel-reset__text">Сбросить все</div>
    </div>);
}
