import * as React from "react";


export function JobHHSearchListAdvanced() {
    return (<div className="job-hh-search-list-advanced">
        <a
            href="https://hh.ru/search/vacancy/advanced"
            className="job-hh-search-list-advanced__text"
            target="_blank">
            расширенный поиск
        </a>
        <div className="job-hh-search-list-advanced__img"/>
    </div>)
}
