const getMenuItems = () => {
    return [
        {
            anchor: 'О Центре',
            link: '/about'
        },
        {
            anchor: 'Отзывы',
            link: '/feedbacks'
        },
        {
            anchor: 'Программы',
            link: '/programs'
        },
        {
            anchor: 'Трудоустройство',
            link: '/job'
        },
        {
            anchor: 'Работодателям',
            link: '/employer'
        },
        {
            anchor: 'Новости',
            link: '/news'
        },
        {
            anchor: 'Контакты',
            link: '/contacts'
        },
    ]
};

const getFooterMenuItems = () => {
    return [
        {
            anchor: 'О Центре',
            link: '/about'
        },
        {
            anchor: 'Программы',
            link: '/programs'
        },
        {
            anchor: 'Отзывы',
            link: '/feedbacks'
        },
        {
            anchor: 'Трудоустройство',
            link: '/job'
        },
        {
            anchor: 'Новости',
            link: '/news'
        },
        {
            anchor: 'Контакты',
            link: '/contacts'
        },
        {
            anchor: 'Для работодателей',
            link: '/employer'
        },
    ]
};

const aboutTabMenuModel = [
    {
        anchor: 'О ЦОПП',
        link: '/about',
    },
    {
        anchor: 'Сотрудники',
        link: '/about/staffs',
    },
    {
        anchor: 'Партнеры',
        link: '/about/partners',
    },
    {
        anchor: 'Документы',
        link: '/about/docs',
    },
    /*{ -- Скрыто
        anchor: 'Преподаватели',
        link: '/about/teachers',
    },*/
    {
        anchor: 'Видеоматериалы',
        link: '/about/video',
    },
];

const getPhones = () => (
    [
        '+7 3452 68-01-00',
        '+7 952 687-57-52',
        '+7 950 480-02-52'
        /*
        '+7 950 480-02-52',
        '+7 952 687-57-52'
         */
    ]
);


const wsSteps = [
    {
        title: 'ВЫБРАТЬ РЕГИОН ПРОЖИВАНИЯ'
    },
    {
        title: 'ВЫБРАТЬ ПРОФЕССИОНАЛЬНУЮ КОМПЕТЕНЦИЮ'
    },
    {
        title: 'ВЫБРАТЬ МЕСТО ОБУЧЕНИЯ'
    },
    {
        title: 'ПОДАТЬ ЗАЯВКУ НА ОБУЧЕНИЕ'
    }
];

const wsEducationCenters = [
    {
        order: 1,
        title: 'ГАПОУ ТО «Тюменский техникум индустрии питания, коммерции и сервиса»'
    },
    {
        order: 2,
        title: 'ГАПОУ ТО «Тюменский техникум строительной индустрии и городского хозяйства»'
    },
    {
        order: 3,
        title: 'ГАПОУ ТО «Тюменский колледж производственных и социальных технологий»'
    },
    {
        order: 4,
        title: 'ГАПОУ ТО «Тобольский медицинский колледж имени Володи Солдатова»'
    },
    {
        order: 5,
        title: 'ГАПОУ ТО «Тобольский многопрофильный техникум»'
    },
    {
        order: 6,
        title: 'ГАПОУ ТО «Колледж цифровых и педагогических технологий»'
    },
    {
        order: 7,
        title: 'ГАПОУ ТО «Агротехнологический колледж»'
    },
    {
        order: 8,
        title: 'ГАПОУ ТО «Ишимский многопрофильный техникум»'
    },
    {
        order: 9,
        title: 'ГАПОУ ТО «Голышмановский агропедколледж»'
    },
    {
        order: 10,
        title: 'ГАПОУ ТО «Тюменский колледж транспортных технологий и сервиса»'
    },
    {
        order: 11,
        title: 'ГАПОУ ТО «Тюменский медицинский колледж»'
    },
    {
        order: 12,
        title: 'ГАПОУ ТО «Ишимский медицинский колледж»'
    },
    {
        order: 13,
        title: 'ФГБОУ ВО «Тюменский индустриальный университет»'
    },
    {
        order: 14,
        title: 'ГАПОУ ТО «Тюменский медицинский колледж»'
    }
];

const wsExpressSkills = [
    "Администрирование отеля",
    "Веб-дизайн и разработка",
    "Визуальный мерчендайзинг",
    "Графический дизайн",
    "Дошкольное воспитание",
    "Кирпичная кладка",
    "Кондитерское дело",
    "Кузовной ремонт",
    "Лабораторный медицинский анализ",
    "Малярные и декоративные работы",
    "Медицинский и социальный уход",
    "Обработка листового металла",
    "Парикмахерское искусство",
    "Плотницкое дело",
    "Поварское дело",
    "Предпринимательство",
    "Преподавание в младших классах",
    "Программные решения для бизнеса",
    "Производство мебели",
    "Промышленная автоматика",
    "Ремонт и обслуживание легковых автомобилей",
    "Ресторанный сервис",
    "Сантехника и отопление",
    "Сварочное производство",
    "Сварочные технологии ",
    "Сетевое и системное администрирование",
    "Социальная работа ",
    "Технологии моды",
    "Токарные работы на станках с ЧПУ",
    "Турагентская деятельность",
    "Туризм",
    "Туроператорская деятельность",
    "Фармацевтика",
    "Физическая культура, спорт и фитнес",
    "Флористика",
    "Фотография",
    "Фрезерные работы на станках с ЧПУ",
    "Хлебопечение",
    "Холодильная техника и системы кондиционирования",
    "Эксплуатация сельскохозяйственных машин",
    "Электромонтаж",
    "Эстетическая косметология"
];

export {
    getMenuItems,
    getFooterMenuItems,
    aboutTabMenuModel,
    getPhones,
    wsSteps,
    wsEducationCenters,
    wsExpressSkills
};
