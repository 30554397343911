import React, {useState} from 'react';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import {ReadMore} from '../read-more';
import {VideoModal} from "../videoModal";

class Feedback extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            slides: this.props.slides,
            slideIndex: 0,
            openVideoModal: false,
            videoLink: null
        };
    }

    handleClickNext = () => {
        const {slides, currentSlideIndex} = this.state;
        if (currentSlideIndex + 1 < slides.length) {
            this.slider.slickNext();
        }
    };

    handleClickPrev = () => {
        const {currentSlideIndex} = this.state;
        if (currentSlideIndex > 0) {
            this.slider.slickPrev();
        }
    };

    componentDidMount() {
        this.setState({
            mainSlider: this.mainSlider,
            navSlider: this.navSlider
        });
    }

    updateMainSliderHeight = () => {
        this.mainSlider.slickPause();
    };


    getMainSlideLayout = (item, index) => {

        if (item.videoLink) {
            return (
                <div className="feedback-main-slider-item" key={index}>
                    <div className="feedback-main-slider-item__video" onClick={() => this.openModal(item.videoLink)}>
                            <div className="feedback-main-slider-item__video-img">
                                <img
                                    src={
                                        item.img ? item.img : "/images/photo-pattern-large.jpg"
                                    }
                                    alt=""
                                />
                                <img src="/images/play.png" alt=""/>
                            </div>
                            <div className="feedback-main-slider-item__video-source">
                                <img
                                    src={
                                        item.videoLink.indexOf("youtube") !== -1
                                            ? "/images/youtube-small.png"
                                            : "/images/vk-small.png"
                                    }
                                    alt=""
                                />
                                <span>{`${
                                    item.videoLink.indexOf("youtube") !== -1
                                        ? "Youtube"
                                        : "Вконтакте"
                                }`}</span>
                            </div>
                    </div>

                    <div className="feedback-main-slider-item__info">
                        {item.text && item.text.trim().length > 0 && <ReadMore text={item.text} maxTextLength={300} onChange={this.updateMainSliderHeight}/>}
                        <div className="feedback-main-slider-item__name">{item.name}</div>
                        {item.program && <div className="feedback-main-slider-item-program">
                            Программа <Link to={item.program.link}>{item.program.name}</Link>
                        </div>}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="feedback-main-slider-item" key={index}>
                    <div className="feedback-main-slider-item__img">
                        <img src={item.img} alt=""/>
                    </div>

                    <div className="feedback-main-slider-item__info">
                        <ReadMore text={item.text} maxTextLength={300} onChange={this.updateMainSliderHeight}/>
                        <div className="feedback-main-slider-item__name">{item.name}</div>
                        {item.program && <div className="feedback-main-slider-item-program">
                            Программа <Link to={item.program.link}>{item.program.name}</Link>
                        </div>}
                    </div>
                </div>
            );
        }
    };

    getNavSlideLayout = (item, index) => (
        <div className="feedback-nav-slider-item" key={index}>
            <div className="feedback-nav-slider__img" title={item.name}>
                <img src={item.thumb} alt={`Фотография к отзыву ${item.name}`}/>
                {item.videoLink && <img src="/images/play-btn.png" className="feedback-nav-slider__play"
                                        alt={`Видеоматериалы к отзыву ${item.name}`}/>}
            </div>
        </div>
    );

    openModal = (link) => {
        this.setState({
            videoLink: link,
            openVideoModal: true
        })
    }

    closeModal = () => {
        this.setState({
            videoLink: null,
            openVideoModal: false
        })
    }

    render() {
        const {mainSlider, navSlider, videoLink, openVideoModal} = this.state;

        const mainSliderSettings = {
                slidesToShow: 1,
                fade: true,
                asNavFor: navSlider,
                adaptiveHeight: true,
                arrows: false,
                verticalSwiping: true,
                responsive: [
                    {
                        breakpoint: 700,
                        settings: {
                            verticalSwiping: false
                        }
                    }
                ]
            },
            navSliderSettings = {
                slidesToShow: this.state.slides.length > 3 ? 3 : this.state.slides.length,
                vertical: true,
                focusOnSelect: true,
                asNavFor: mainSlider,
                swipeToSlide: true,
                adaptiveHeight: false,
                nextArrow: '',
                prevArrow: '',
                verticalSwiping: true,
                responsive: [
                    {
                        breakpoint: 700,
                        settings: {
                            vertical: false,
                            verticalSwiping: false
                        }
                    }
                ]
            },
            {slides} = this.state;

        return (
            <>
                <div className="container feedback__wrapper">

                    <div className="feedback">
                        <Slider
                            ref={slider => {
                                this.mainSlider = slider;
                            }}
                            className="feedback-main-slider"
                            {...mainSliderSettings}>
                            {slides.map((item, index) => this.getMainSlideLayout(item, index))}
                        </Slider>

                        <Slider
                            ref={slider => {
                                this.navSlider = slider;
                            }}
                            className="feedback-nav-slider"
                            {...navSliderSettings}>
                            {slides.map((item, index) => this.getNavSlideLayout(item, index))}
                        </Slider>
                    </div>
                </div>
                <VideoModal open={openVideoModal} link={videoLink} close={this.closeModal}/>
            </>
        );
    }
}

export {Feedback};
