import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {PageTitle} from '../../components/page-title';
import {TabMenu} from '../../components/tab-menu';
import {GotQuestion} from '../../components/got-question';
import {Link} from 'react-router-dom';
import {GoTopButton} from '../../components/goTopButton';
import {aboutTabMenuModel} from '../../api/api';


const Teachers = props => {
  return (
    <div className="wrapper wrapper--default">
      <Header/>

	  <GoTopButton />
      <div className="container container--about">
        <PageTitle>О центре</PageTitle>
        
        <TabMenu menuModel={aboutTabMenuModel}/>
        
        <div className="block-margin"/>
        
        <div className="teachers">
          {props.model && props.model.teachers && props.model.teachers.map((item, index) => (
            <Link to={`/about/teachers/${item.link}`} className="teachers-item" key={`teachers_item_${index}`}>
              <div className="teachers-item__img">
                <img src={item.img} alt=""/>
                <div className="teachers-item__arrow"/>
              </div>
              
              <div className="teachers-item__name">{item.name}</div>
            </Link>
          ))}
        </div>
        
        <div className="block-margin"/>
      </div>
      <GotQuestion/>
      <Footer/>
    </div>
  );
};

export {Teachers};