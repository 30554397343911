import React from "react";

import EmployerLinkListReadyItem from "./EmployerLinkListReadyItem";

function EmployerLinkListReady({links}) {
    return (<div className="employer-link-list">
        {links.map((i, idx) => (<EmployerLinkListReadyItem item={i} key={idx} />))}
    </div>)
}

export default EmployerLinkListReady;
