import React from "react";

const ItemStyle = {
    width: "100%",
    height: "212px",
}

function CofinanceLinkListSkeletonItem() {
    return (<div className="skeleton">
        <div className="skeleton-item" style={ItemStyle} />
    </div>)
}

export default CofinanceLinkListSkeletonItem;
