import React from "react";
import {JobIndexVacancyListSkeletonItem} from "./JobIndexVacancyListSkeletonItem";
import {Job3ItemList} from "../JobCommon/Job3ItemList";

export function JobIndexVacancyListSkeleton() {
    return (<Job3ItemList>
        <JobIndexVacancyListSkeletonItem key={1}/>
        <JobIndexVacancyListSkeletonItem key={2}/>
        <JobIndexVacancyListSkeletonItem key={3}/>
    </Job3ItemList>)
}
