import * as React from "react";
import {JobHHSearchPanelText} from "./JobHHSearchPanelText";
import {JobHHSearchPanelRegion} from "./JobHHSearchPanelRegion";
import {JobHHSearchPanelSalary} from "./JobHHSearchPanelSalary";
import {JobHHSearchPanelActivity} from "./JobHHSearchPanelActivity";
import {JobHHSearchPanelEmployment} from "./JobHHSearchPanelEmployment";
import {JobHHDataFormOnlyWithSalary} from "./JobHHSearchPanelOnlyWithSalary";
import {JobHHSearchPanelSearch} from "./JobHHSearchPanelSearch";
import {JobHHSearchPanelReset} from "./JobHHSearchPanelReset";

export function JobHHSearchPanel() {

    return (<div className="job-hh-search-panel">
        <div className="job-hh-search-panel__row">
            <div className="job-hh-search-panel__text">
                <JobHHSearchPanelText/>
            </div>
            <div className="job-hh-search-panel__region">
                <JobHHSearchPanelRegion/>
            </div>
        </div>
        <div className="job-hh-search-panel__row">
            <div className="job-hh-search-panel__salary">
                <JobHHSearchPanelSalary />
            </div>
            <div className="job-hh-search-panel__activity">
                <JobHHSearchPanelActivity />
            </div>
            <div className="job-hh-search-panel__employment">
                <JobHHSearchPanelEmployment />
            </div>
        </div>
        <div className="job-hh-search-panel__row">
            <div className="job-hh-search-panel__only-with-salary">
                <JobHHDataFormOnlyWithSalary />
            </div>
            <div className="job-hh-search-panel__search">
                <JobHHSearchPanelSearch />
            </div>
        </div>
        <div className="job-hh-search-panel__row">
            <div className="job-hh-search-panel__reset">
                <JobHHSearchPanelReset />
            </div>
        </div>
    </div>)
}
