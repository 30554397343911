import React from "react";
import {DoubleLink} from "../../api/functions";

function CofinanceLinkListReadyItem({item}) {
    let content = [];
    if (item.image) {
        let alt = item.caption || 'Информация по софинансированию';
        content.push((<img className="cofinance-link-list-item__image" src={item.image} alt={alt}/>));
    }
    if (item.caption) {
        content.push((<div className="cofinance-link-list-item__caption">
            {item.caption}

        </div>));
    }
    content.push((<svg width="41" height="16" viewBox="0 0 41 16" fill="none"
                       className="cofinance-link-list-item__caption-arrow"
                       xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M41.005 8.38197L33.795 16.001L32.009 14.472L37.191 8.99597H-0.000976562V7.00497H37.176L31.985 1.52497L33.773 -0.00402832L40.991 7.61497L40.55 7.99197L41.005 8.38197Z"
                  fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="41" height="16" fill="currentColor"/>
            </clipPath>
        </defs>
    </svg>))

    return (<DoubleLink to={item.link} className="cofinance-link-list-item">
        {content}
    </DoubleLink>);

}

export default CofinanceLinkListReadyItem;
