import React, { useState } from "react";
import { Radio } from "../radio";
import { Button } from "../button";

const QuizForm = ({ totalQuestions, currentQuestion, activeQuestion, nextQuestion, setShowResults }) => {
  const [answer, setAnswer] = useState();
  const [error, setError] = useState();

  const onRadioClick = event => {
    setAnswer(event.target.value);
    setError("");
  };

  const addAnswer = answer => {
    if (!answer) {
      setError("Ответьте, пожалуйста, на вопрос.");
      return;
    }

    if (currentQuestion >= totalQuestions) setShowResults();

    nextQuestion(answer);
    setAnswer("");
  };

  return (
    <>
      <div className="quiz__form__topic">
        <p>Предположим, что после соответствующего обучения Вы сможете выполнить любую работу.</p>
        <p>Но если бы Вам пришлось выбирать только из двух возможностей, что бы Вы предпочли?</p>
      </div>
      <div className="quiz__form__progress">
        <div className="quiz__form__progress--count">{currentQuestion}</div>
        <div className="quiz__form__progress--bar">
          <div
            className="quiz__form__progress--bar--filler"
            style={{ width: `${(currentQuestion / totalQuestions) * 100}%` }}
          />
        </div>
        <div className="quiz__form__progress--bar--total">
          {currentQuestion} из {totalQuestions}
        </div>
      </div>
      <div className="quiz__form__question">
        {error && <div className="quiz__form__error">{error}</div>}
        <div className="quiz__form__question--answer">
          <Radio
            name="quiz"
            label={activeQuestion.option_1}
            value="a"
            onRadioChanged={event => onRadioClick(event)}
            checked={answer === "a"}
          />
          <Radio
            name="quiz"
            label={activeQuestion.option_2}
            value="b"
            onRadioChanged={event => onRadioClick(event)}
            checked={answer === "b"}
          />
        </div>
        <div className="quiz__form__question--control">
          <Button className="btn--secondary" onClick={() => addAnswer(answer)}>
            {currentQuestion >= totalQuestions ? "Завершить тест" : "Далее"}
          </Button>
        </div>
      </div>
    </>
  );
};

export { QuizForm };
