import Select from "react-select";
import React from "react";
import {useJobHHDataSearchOrderBy} from "../../services/Job";

function formatOptionLabel(x) {
    return x.name;
}

export function JobHHSearchListOrderBy() {
    const [options, orderBy, setOrderBy] = useJobHHDataSearchOrderBy();
    const handleChange = React.useCallback((x) => {
        if (x) {
            setOrderBy(x.id || null);
        } else {
            setOrderBy(null);
        }
    }, [setOrderBy]);

    let selected = options.find(x => x.id === orderBy) || null;

    return (<div className="job-hh-search-list-order-by">
        <div className="job-hh-search-list-order-by__label">Сортировка:</div>
        <Select value={selected}
                onChange={handleChange}
                formatOptionLabel={formatOptionLabel}
                noOptionsMessage={() => 'Нет значений для выбора'}
                options={options}
                isSearchable={false}
                className="job-hh-search-list-order-by__sort"
                classNamePrefix="job-hh-search-list-order-by-sort"/>
    </div>)
}
