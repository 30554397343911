import React from "react";
import { Radar } from "react-chartjs-2";
import { useMediaQuery } from "react-responsive";

const Chart = ({ chartData }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const options = {
    maintainAspectRatio: isMobile ? false : true,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scale: {
      ticks: {
        suggestedMin: 1,
        suggestedMax: 8,
      },
      pointLabels: {
        fontSize: 14,
      },
    },
  };

  const data = {
    labels: [
      `${isMobile ? "Природа" : "Человек - природа"}`,
      `${isMobile ? "Техника" : "Человек - техника"}`,
      `${isMobile ? `Знаковая  система` : "Человек - знаковая система"}`,
      `${isMobile ? `Художественный образ` : "Человек - художественный образ"}`,
      `${isMobile ? "Человек" : "Человек - человек"}`,
    ],
    datasets: [
      {
        backgroundColor: "rgba(233,16,27,0.4)",
        borderColor: "rgba(208,16,27,0.8)",
        pointRadius: isMobile ? 2 : 4,
        pointBackgroundColor: "rgba(208,16,27,1)",

        data: chartData,
      },
    ],
  };
  return <Radar data={data} options={options} />;
};

export { Chart };
