import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import { Header } from "../../components/header";
import { ProgramListItem } from "../../components/program-list-item";
// import { Reviews } from "../../components/reviews";
import { GoTopButton } from "../../components/goTopButton";
import { Footer } from "../../components/footer";

const SearchPage = () => {
  const history = useHistory();
  const [searchInputValue, setSearchInputValue] = useState();
  const [searchResult, setSearchResult] = useState();
  const [loading, setLoading] = useState();
  const searchString = decodeURI(history.location.search.split("=").pop());
  const searchItemsLength =
    searchResult &&
    searchResult.programs.length +
      searchResult.faq.length +
      searchResult.pages.length;

  useEffect(() => {
    if (searchString) {
      setSearchInputValue(searchString);
      getSearchResult(searchString);
    }
  }, [searchString]);

  //! Без задержки
  /*  const getSearchResult = search => {
    setLoading(true);
    fetch(`/api/search?query=${search}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          history.push("/search");
        } else {
          throw response;
        }
      })
      .then(json => {
        setSearchResult(json);
        setLoading(false);
      });
  }; */
  //! С задержкой 1000 мс
  const getSearchResult = (search) => {
    const start = Date.now();
    setLoading(true);
    fetch(`/api/search?query=${search}`)
      .then((response) => {
        return new Promise((resolve) => {
          const diff = Date.now() - start;
          if (diff < 1000) {
            setTimeout(() => {
              resolve(response);
            }, 1000 - diff);
          } else {
            resolve(response);
          }
        });
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          history.push("/search");
        } else {
          throw response;
        }
      })
      .then((json) => {
        setSearchResult(json);
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    setSearchInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getSearchResult(searchInputValue);
    history.push(`/search?query=${searchInputValue}`);
  };

  const handleStringSearch = (event) => {
    event.preventDefault();
    setSearchInputValue(event.target.textContent);
    getSearchResult(event.target.textContent);
    history.push(`/search?query=${event.target.textContent}`);
  };

  return (
    <>
      <div className="search__wrapper">
        <Header />
        <GoTopButton />
        <div className="container">
          <div className="search">
            <div className="search__title">Поиск по сайту</div>
            <form
              onSubmit={(event) => handleSubmit(event)}
              className={!loading ? "search__form" : "search__form--loading"}
            >
              <input
                type="text"
                name="searchText"
                placeholder="Введите текст для поиска"
                value={searchInputValue || ""}
                onChange={(event) => handleInputChange(event)}
              />
              <img
                src="/images/search-icon.png"
                alt=""
                onClick={(event) => handleSubmit(event)}
              />
            </form>
            {loading && (
              <span className="progress">
                <span className="indeterminate"></span>
              </span>
            )}
            {!loading && (
              <>
                {searchResult && (
                  <div className="search__result__text">
                    По запросу “<span>{`${searchString}`}</span>”{" "}
                    {searchItemsLength === 0 && "не"} найдено совпадений
                    {` ${searchItemsLength !== 0 ? searchItemsLength : ""}`}
                    {searchItemsLength === 0 && (
                      <div>
                        Попробуйте другие ключевые слова
                        <span
                          onClick={(event) => handleStringSearch(event)}
                          className="search__result__text__options"
                        >{`фотограф`}</span>
                        <span
                          onClick={(event) => handleStringSearch(event)}
                          className="search__result__text__options"
                        >{`флористика`}</span>
                        <span
                          onClick={(event) => handleStringSearch(event)}
                          className="search__result__text__options"
                        >{`дизайн`}</span>
                      </div>
                    )}
                  </div>
                )}
                {searchResult && searchResult.programs.length > 0 && (
                  <>
                    <div className="search__program__caption">Программы</div>
                    <div className="search__result__program-list">
                      {searchResult.programs.map((item, index) => (
                        <ProgramListItem key={index} item={item} />
                      ))}
                    </div>
                  </>
                )}
                {searchResult && searchResult.faq.length > 0 && (
                  <>
                    <div className="search__faq__caption">Частые вопросы</div>
                    {searchResult.faq.map((item, index) => (
                      <div className="search__result__faq" key={index}>
                        <div className="search__result__faq-question">
                          {item.question}
                        </div>
                        <div
                          className="search__result__faq-answer"
                          dangerouslySetInnerHTML={{ __html: item.answer }}
                        />
                      </div>
                    ))}
                  </>
                )}
                {searchResult && searchResult.pages.length > 0 && (
                  <>
                    <div className="search__pages__caption">Страницы</div>
                    {searchResult.pages.map((item, index) => (
                      <div className="search__result__pages" key={index}>
                        <div className="search__result__pages--img">
                          <img
                            src={
                              item.source === "Документы"
                                ? "/images/pdf.svg"
                                : item.img || "/images/no-page-image.svg"
                            }
                            alt=""
                          />
                        </div>

                        <div className="search__result__pages__info">
                          <Link
                            to={item.link}
                            className="search__result__pages__info-title"
                          >
                            {item.title}
                          </Link>
                          <div
                            className="search__result__pages__info-desc"
                            dangerouslySetInnerHTML={{
                              __html: item.desc
                                .replace(/^,/, "...")
                                .replace(/$/, "..."),
                            }}
                          />
                          <div className="search__result__pages__info-source">
                            {item.source}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className={`search__footer ${searchItemsLength === 0 && "search__footer--bottom"}`}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export { SearchPage };
