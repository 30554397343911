import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';

const Policy = () => (
  <div className="wrapper wrapper--policy">
    <Header/>
    <div className="container">
      <div className="policy">
        <b>Политика конфиденциальности</b> <br/><br/>
        Предоставляя свои персональные данные Пользователь даёт согласие на обработку, хранение и использование своих
        персональных данных на основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. в следующих целях:
        <br/>
        <br/>
    
        Осуществление клиентской поддержки<br/><br/>
    
        Получения Пользователем информации о маркетинговых событиях<br/><br/>
    
        Проведения аудита и прочих внутренних исследований с целью повышения качества предоставляемых услуг.<br/><br/>
    
        Под персональными данными подразумевается любая информация личного характера, позволяющая установить личность
        Пользователя/Покупателя такая как:<br/><br/>
    
        Фамилия, Имя, Отчество<br/>
        Дата рождения<br/>
        Контактный телефон<br/>
        Адрес электронной почты<br/>
        Почтовый адрес<br/><br/>
    
        Персональные данные Пользователей хранятся исключительно на электронных носителях и обрабатываются с
        использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка
        персональных данных необходима в связи с исполнением требований законодательства.<br/><br/>
    
        Компания обязуется не передавать полученные персональные данные третьим лицам, за исключением следующих
        случаев:<br/><br/>
    
        По запросам уполномоченных органов государственной власти РФ только по основаниям и в порядке, установленным
        законодательством РФ<br/><br/>
    
        Стратегическим партнерам, которые работают с Компанией для предоставления продуктов и услуг, или тем из них,
        которые помогают Компании реализовывать продукты и услуги потребителям. Мы предоставляем третьим лицам
        минимальный объем персональных данных, необходимый только для оказания требуемой услуги или проведения
        необходимой транзакции.<br/><br/>
    
        Компания оставляет за собой право вносить изменения в одностороннем порядке в настоящие правила, при условии,
        что изменения не противоречат действующему законодательству РФ. Изменения условий настоящих правил вступают в
        силу после их публикации на Сайте.
      </div>
    </div>
    <Footer/>
  </div>
);

export {Policy};