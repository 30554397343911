import * as React from "react";


const HeadItemStyle = {
    width: "70%",
    height: "48px",
}

const SalaryItemStyle = {
    width: "200px",
    height: "22px",
    marginTop: "12px"
}

const OrgItemStyle = {
    width: "50%",
    height: "100px",
    marginTop: "32px"
}

const PropsItemStyle = {
    width: "200px",
    height: "44px",
    marginTop: "32px",
    marginBottom: "12px"
}

const BodyItemStyle1 = {
    width: "60%",
    height: "12px",
    marginTop: "10px"
}
const BodyItemStyle2 = {
    width: "35%",
    height: "12px",
    marginTop: "10px"
}

export function JobHHItemSkeleton() {
    return (<div className="skeleton">
        <div className="skeleton-item" style={HeadItemStyle}/>

        <div className="skeleton-item" style={SalaryItemStyle}/>

        <div className="skeleton-item" style={OrgItemStyle}/>

        <div className="skeleton-item" style={PropsItemStyle}/>

        <div className="skeleton-item" style={BodyItemStyle1}/>
        <div className="skeleton-item" style={BodyItemStyle2}/>
        <div className="skeleton-item" style={BodyItemStyle1}/>
    </div>);
}
