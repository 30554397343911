import React from 'react';
import OnVisible from 'react-on-visible';

const About = props => {
    return (
        <div className="container">
            <div className="about">

                <div className="about__title">О центре опережающей профессиональной подготовки</div>

                <div className="quote">
                    Команда профессионалов, которая раскроет ваш потенциал <br/>
                    <span className="quote__highlight">Учим профессиям будущего</span>
                </div>
            </div>

            <div className="about-icons">
                <OnVisible className="about-icons__row">
                    <div className="about-icons-item" style={{transitionDelay: `${Math.random() / 2}s`}}>
                        <div className="about-icons-item__img">
                            <img src="/images/building.svg" alt=""/>
                        </div>

                        <div className="about-icons-item__title">представительства <br /> в Тюменской  <br /> области</div>
                    </div>

                    <div className="about-icons-item" style={{transitionDelay: `${Math.random() / 2}s`}}>
                        <div className="about-icons-item__img">
                            <img src="/images/partners.svg" alt=""/>
                        </div>

                        <div className="about-icons-item__title"> партнеры - <br /> образовательные  <br /> организации</div>
                    </div>

                    <div className="about-icons-item" style={{transitionDelay: `${Math.random() / 2}s`}}>
                        <div className="about-icons-item__img">
                            <img src="/images/specialist.svg" alt=""/>
                        </div>

                        <div className="about-icons-item__title"> преподаватели -  <br /> эксперты</div>
                    </div>

                    <div className="about-icons-item" style={{transitionDelay: `${Math.random() / 2}s`}}>
                        <div className="about-icons-item__img">
                            <img src="/images/programms.svg" alt=""/>
                        </div>

                        <div className="about-icons-item__title">программы  <br /> опережающей  <br /> подготовки</div>
                    </div>
                </OnVisible>
            </div>
        </div>

    );
};

export {About};
