import React, {useState,useCallback} from "react";
import {Radio} from "../../radio";
import {CheckBox} from "../../check-box";

const QuestionItem = ({question, setAnswer}) => {
    const [error, setError] = useState();

    const [checkQuestions, setCheckQuestions] = useState([]);

    const onRadioClick = (id, event) => {
        setAnswer(id, event.target.value);
    };

    const onCheckBoxChange = (id, variantId, event) => {
        try {
            const chkVariantChecked = event.target.value;
            let newValues = '';

            let newCheckQuestions = checkQuestions || [];
            let chkIndex = checkQuestions.findIndex(item => item.questionId === id);
            if (chkIndex !== -1) {
                let oldValues = newCheckQuestions[chkIndex].values;
                let oldValuesSplited = oldValues.split(',') || [];
                const indexVar = oldValuesSplited.indexOf(variantId.toString());

                if (indexVar !== -1 && !!chkVariantChecked) {
                    oldValuesSplited.splice(indexVar, 1);
                    newValues = oldValuesSplited.join(',');
                } else if (indexVar === -1 && chkVariantChecked) {
                    newValues = oldValues.concat(',', variantId);
                }
                newCheckQuestions[chkIndex] = {
                    questionId: id,
                    values: newValues
                };
            } else {
                newValues = variantId.toString();
                let newCheckQuestion = {
                    questionId: id,
                    values: newValues
                };
                newCheckQuestions.push(newCheckQuestion);
            }
            setCheckQuestions(newCheckQuestions);
            setAnswer(id, newValues);
        } catch (e) {

        }
    };

    return <>
        <div className="quiz__form__topic">
            <p>
                <span className="quiz__form__topic--required">{question.isRequired && '*'}</span>
                {question.order}. {question.title}
            </p>
        </div>
        <div className="quiz__form__question">
            {error && <div className="quiz__form__error">{error}</div>}
            <div className="quiz__form__question--answer">
                {question.type.id === 1 &&
                    <input type="text"
                           onChange={event => setAnswer(question.id, event.target.value)}
                           name={`question-${question.id}`}
                           required={question.isRequired}
                           className="form-page__input"/>
                }

                {question.type.id === 2 &&
                <input type="date"
                       name={`question-${question.id}`}
                       required={question.isRequired}
                       onChange={event => setAnswer(question.id, event.target.value)}
                       className="form-page__input"/>
                }

                {question.type.id === 3 &&
                <input type="time"
                       name={`question-${question.id}`}
                       required={question.isRequired}
                       onChange={event => setAnswer(question.id, event.target.value)}
                       className="form-page__input"/>
                }

                {question.type.id === 4 && question.variants && <>
                    {question.variants.map((item,idx) =>
                        <Radio key={`radio-variant-${question.id}-${item.id}`}
                            name={`radio-${question.id}`}
                            label={item.description}
                            onRadioChanged={event => onRadioClick(question.id, event)}
                            value={item.id}/>
                    )}
                </>}

                {question.type.id === 5 && question.variants && <>
                    {question.variants.map((item,idx) =>
                        <CheckBox key={`checkbox-variant-${question.id}-${item.id}`}
                               label={item.description}
                               onChange={(event) => onCheckBoxChange(question.id, item.id, event)}
                               checked={false}
                        />
                    )}
                </>}

                {question.type.id === 6 && question.variants && question.variants.length > 0 && <>
                    <select className="form-page__select"
                            onChange={event => setAnswer(question.id, event.target.value)}>
                        <option hidden default>Выберите вариант ответа</option>
                        {question.variants.map((item,idx) =>
                        <option key={`select-option-${question.id}-${item.id}`}
                                value={item.id}>
                            {item.description}
                        </option>)}
                    </select>
                </>}

                {question.type.id === 7 && question.params &&
                    <input type="number"
                           name={`question-${question.id}`}
                           required={question.isRequired}
                           placeholder={`от ${question.params.minValue} до ${question.params.maxValue}`}
                           onChange={event => setAnswer(question.id, event.target.value)}
                           className="form-page__input"/>}
            </div>
        </div>
    </>
}

export default QuestionItem;