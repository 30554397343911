import React from "react";
import { DoubleLink, trimTextFromSpace } from "../../api/functions";
import { Badge } from "../badge";
import cn from "classnames";

const NewsList = (props) => {
  return (
    <div
      className={
        props.noWraper ? "news_list__wrapper--no-wraper" : "news_list__wrapper"
      }
    >
      <div
        className={
          props.main
            ? "container news__container-main"
            : "container news__container"
        }
      >
        {!props.noTitle && (
          <>
            <div className="news_list__title">Новости и события</div>
            <div className="news_list__subtitle">Новости</div>
          </>
        )}

        <div className="news_list">
          {props.news.map((item, index) => {
            const imageClasses = cn({
              "news_list-item__img": true,
              //"news_list-item__img--smi": item.category === 1,
              "news_list-item__img--video":
                item.category === 2 || item.category === 3,
            });
            return (
              <DoubleLink
                to={item.externalLink ? item.externalLink : item.link}
                className="news_list-item"
                key={index}
              >
                <>
                  <div className={imageClasses}>
                    {(item.category === 1 || item.category === 3) && (
                      <Badge
                        className="news_list-item__badge"
                        title="СМИ о нас"
                      />
                    )}
                    {(item.category === 2 || item.category === 3) && (
                      <>
                        <img src="/images/play.png" alt="" />
                        <div className="news_list-item__title--video">
                          <span>
                            {item.title}
                            {item.category === 3 && (
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g>
                                  <path
                                    d="M1.33333 0C0.98052 0.00261677 0.6429 0.143933 0.393416 0.393416C0.143933 0.6429 0.00261677 0.98052 0 1.33333V10.6667C0.00261677 11.0195 0.143933 11.3571 0.393416 11.6066C0.6429 11.8561 0.98052 11.9974 1.33333 12H10.6667C11.0195 11.9974 11.3571 11.8561 11.6066 11.6066C11.8561 11.3571 11.9974 11.0195 12 10.6667V6H10.6667V10.6667H1.33333V1.33333H6V0H1.33333Z"
                                    fill="#C6CFD8"
                                  />
                                  <path
                                    d="M7.33331 0V1.33333H9.72442L3.52887 7.52889L4.47109 8.47111L10.6666 2.27556V4.66667H12V0H7.33331Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect width="12" height="12" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            )}
                          </span>
                        </div>
                      </>
                    )}
                    <img src={item.img} alt="" />
                  </div>
                  <div className="news_list-item__info">
                    <div>
                      <div className="news_list-item__title">
                        {(!item.category || item.category === 1 || item.category === 4) && item.title}
                        {item.category === 1 && (
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path
                                d="M1.44444 0C1.06223 0.00283483 0.696475 0.155927 0.426201 0.426201C0.155927 0.696475 0.00283483 1.06223 0 1.44444V11.5556C0.00283483 11.9378 0.155927 12.3035 0.426201 12.5738C0.696475 12.8441 1.06223 12.9972 1.44444 13H11.5556C11.9378 12.9972 12.3035 12.8441 12.5738 12.5738C12.8441 12.3035 12.9972 11.9378 13 11.5556V6.5H11.5556V11.5556H1.44444V1.44444H6.5V0H1.44444Z"
                                fill="#C6CFD8"
                              />
                              <path
                                d="M7.94448 0V1.44444H10.5348L3.823 8.1563L4.84374 9.17704L11.5556 2.46518V5.05556H13V0H7.94448Z"
                                fill="#394959"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="13" height="13" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </div>
                      {!item.category && (
                        <div
                          className="news_list-item__text"
                          dangerouslySetInnerHTML={{
                            __html: trimTextFromSpace(item.text, 275, "..."),
                          }}
                        />
                      )}
                    </div>
                  </div>
                </>
                <div className="news_list-item__date">
                  {item.date}
                  <span>
                    | <p>{item.source ? item.source : "Новости ЦОПП"}</p>
                  </span>
                </div>
              </DoubleLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { NewsList };
