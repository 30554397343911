import React from 'react';
import {Header} from '../../components/header';
import {Footer} from '../../components/footer';
import {GoTopButton} from '../../components/goTopButton';
import {JobPageHead} from "../../components/job-page/head";
import {StepControl} from "../../components/step-control";
import {IndexStepNavItem} from "../../components/job-page/steps/index-step";

export const JobSteps = () => {
    return (
        <div className="wrapper wrapper--default">
            <Header/>
            <GoTopButton/>
            <div className="job__container">
                <JobPageHead/>

                <div className="job__desc">
                    <h2>5 шагов к достижению цели</h2>
                </div>

                <IndexStepNavItem
                    stepTitle="Шаг 1"
                    title="Поиск работы"
                    link="/job/job-search"
                    icon="/images/pages/job-page/steps/step1.png"
                />
                <IndexStepNavItem
                    stepTitle="Шаг 2"
                    title="Составление резюме"
                    link="/job/resume"
                    icon="/images/pages/job-page/steps/step2.png"
                />
                <IndexStepNavItem
                    stepTitle="Шаг 3"
                    title="Самопрезентация"
                    link="/job/self-presentation"
                    icon="/images/pages/job-page/steps/step3.png"
                />
                <IndexStepNavItem
                    stepTitle="Шаг 4"
                    title="Собеседование"
                    link="/job/interview"
                    icon="/images/pages/job-page/steps/step4.png"
                />
                <IndexStepNavItem
                    stepTitle="Шаг 5"
                    title="Начало карьеры"
                    link="/job/carier-start"
                    icon="/images/pages/job-page/steps/step5.png"
                />


                <StepControl
                    next="/job/job-search"
                    stepTitleNext="Шаг 1"
                    titleNext="Поиск работы"
                />
            </div>
            <Footer/>
        </div>
    );
};
